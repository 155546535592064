import { useTheme } from '@mui/material'
import { NotificationsIcon } from 'assets/icon'
import { Link, Typography, Box } from 'components'
import { TWhiteLabel } from 'models/white-label'

interface NotificationBellProps {
  seenNotifications?: boolean
  newNotifications?: number
  whiteLabel?: TWhiteLabel
}

export default function NotificationBell({
  seenNotifications,
  newNotifications,
  whiteLabel,
}: NotificationBellProps) {
  const theme = useTheme()

  return (
    <Link
      underline="none"
      href="/settings/notifications"
      sx={{
        position: 'relative',
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row-reverse',
        borderRadius: '50%',
        transition: '0.2s all',
        ':hover': {
          backgroundColor: whiteLabel?.theme
            ? theme.palette.primary.light
            : theme.palette.secondary.main,
        },

        [theme.breakpoints.down('md')]: {
          marginRight: 2,
        },
      }}
    >
      {!seenNotifications && !!newNotifications && newNotifications > 0 && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1000,
            top: 3,
            right: 2,
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              color: theme.palette.error.contrastText,
              backgroundColor: '#B0140A',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              padding: '2px',
              fontSize: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {newNotifications}
          </Typography>
        </Box>
      )}
      <NotificationsIcon sx={{ fontSize: 32 }} />
    </Link>
  )
}
