/* eslint-disable @typescript-eslint/no-explicit-any */
import api from 'services/api'

interface ICompleteMultipartResponse {
  id: string
  fileUrl: string
  fileType: string
  fileName: string
  createdAt: string
  updatedAt: string
}

export class Uploader {
  chunkSize: number

  threadsQuantity: number

  file: File

  fileName: string

  aborted: boolean

  uploadedSize: number

  progressCache: { [part: string]: unknown }

  activeConnections: { [connection: string]: { abort(): unknown } }

  parts: { signedUrl: string; PartNumber: number }[]

  uploadedParts: { ETag: string; PartNumber: number }[]

  fileId: null

  fileKey: null

  onProgressFn: (progress: unknown) => void

  onSuccessFn: (data: ICompleteMultipartResponse) => Promise<void>

  onErrorFn: (err: unknown) => void

  constructor(options: {
    chunkSize?: number
    threadsQuantity?: number
    file: File
    fileName: string
  }) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
    this.file = options.file
    this.fileName = options.fileName
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.fileId = null
    this.fileKey = null
    this.onProgressFn = () => {}
    this.onSuccessFn = async () => {}
    this.onErrorFn = () => {}
  }

  // starting the multipart upload request
  start() {
    this.initialize()
  }

  async initialize() {
    try {
      const { fileName } = this

      // initializing the multipart request
      const videoInitializationUploadInput = {
        name: fileName,
      }
      const initializeResponse = await api.post(
        'files/create-multipart',
        videoInitializationUploadInput
      )

      const AWSFileDataOutput = initializeResponse.data

      this.fileId = AWSFileDataOutput.UploadId
      this.fileKey = AWSFileDataOutput.Key

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize)

      const AWSMultipartFileDataInput = {
        uploadId: this.fileId,
        name: this.fileKey,
        parts: numberOfparts,
      }

      const urlsResponse = await api.post('files/get-presigned-url', AWSMultipartFileDataInput)

      const newParts = urlsResponse.data.parts

      this.parts.push(...newParts)

      this.sendNext()
    } catch (error) {
      await this.complete(error)
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete()
      }

      return
    }

    const part = this.parts.pop()

    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext()
        })
        .catch(error => {
          this.parts.push(part)

          this.complete(error)
        })
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: unknown) {
    if (error && !this.aborted) {
      this.onErrorFn(error)
      return
    }

    if (error) {
      this.onErrorFn(error)
      return
    }

    try {
      const response = await this.sendCompleteRequest()

      if (response) {
        await this.onSuccessFn(response)
      }
    } catch (err) {
      this.onErrorFn(err)
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest(): Promise<ICompleteMultipartResponse | void> {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        UploadId: this.fileId,
        Key: this.fileKey,
        parts: this.uploadedParts,
      }

      const { data } = await api.post<ICompleteMultipartResponse>(
        'files/complete-multipart',
        videoFinalizationMultiPartInput
      )

      return data
    }
  }

  sendChunk(
    chunk: Blob,
    part: { signedUrl: string; PartNumber: number },
    sendChunkStarted: () => void
  ) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then(status => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'))
            return
          }

          resolve(1)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: any, event: any) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded
      }

      if (event.type === 'uploaded') {
        this.uploadedSize = this.uploadedSize + (this.progressCache[part] as number) || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => memo + (this.progressCache[id] as number), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent,
        total,
        percentage,
      })
    }
  }

  // uploading a part through its pre-signed URL
  upload(
    file: Blob,
    part: { signedUrl: string; PartNumber: number },
    sendChunkStarted: () => void
  ) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        // eslint-disable-next-line no-multi-assign
        const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest())

        sendChunkStarted()

        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)

        xhr.upload.addEventListener('progress', progressListener)

        xhr.addEventListener('error', progressListener)
        xhr.addEventListener('abort', progressListener)
        xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag')

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', ''),
              }

              this.uploadedParts.push(uploadedPart)

              resolve(xhr.status)
              delete this.activeConnections[part.PartNumber - 1]
            }
          }
        }

        xhr.onerror = error => {
          reject(error)
          delete this.activeConnections[part.PartNumber - 1]
        }

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'))
          delete this.activeConnections[part.PartNumber - 1]
        }

        xhr.send(file)
      }
    })
  }

  onProgress(onProgress: any) {
    this.onProgressFn = onProgress
    return this
  }

  onError(onError: any) {
    this.onErrorFn = onError
    return this
  }

  onSuccess(onSuccessCallback: (data: ICompleteMultipartResponse) => Promise<void>) {
    this.onSuccessFn = onSuccessCallback
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach(id => {
        this.activeConnections[id].abort()
      })

    this.aborted = true
  }
}
