export default {
  waiting_list: {
    almost_there: 'Almost there!',
    description:
      'Thank you for your interest in the Coterie beta, which is now closed. Join our waiting list and we will be in touch as soon as our registration is open again!',
    success:
      // eslint-disable-next-line quotes
      'Thanks for joining our waiting list! Check your email to stay updated on our progress.',
    sign_up: 'Join the waiting list!',
    back_home: 'Back to Home',
    marketing:
      '*By entering your information you consent to receive marketing messages from Coterie at the email provided. We don’t send emails often, but when we do we make them count!',
  },
}
