import { TFile } from 'models/file'
import { TTag } from 'models/tag'
import { IReaction } from './reactions'
import { IUser } from './user'

export type TMomentTags = {
  contentId: string
  tag: TTag
  tagId: string
}

export type TComment = {
  id: string
  userId: string
  userName: string
  userAvatar: string
  comment: string
  createdAt: Date
}

export enum EMomentSport {
  GOLF = 'GOLF',
  PICKLEBALL = 'PICKLEBALL',
}

export enum EMomentType {
  TEXT = 'TEXT',
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type TMomentReaction = {
  contentId: string
  reaction: {
    id: string
    reaction: IReaction
  }
  reactionId: string
  user: IUser
  userId: string
}
export type TCreatedBy = {
  id: string
  accountId: string
  file?: TFile
  fileId?: string
  name: string
  profession: string
  createdAt: Date
  updatedAt: Date
}

export type TMoment = {
  id: string
  title: string
  sport: EMomentSport
  description: string
  createdById: string
  richText: string
  thumbnail?: string
  link?: string
  isHighlighted?: boolean
  file?: TFile
  type: string
  createdBy: TCreatedBy
  reactions: TMomentReaction[]
  tags?: TMomentTags[]
  _count: {
    reactions: number
  }
  embed?: {
    html: string
  }
  createdAt: Date
  updatedAt: Date
}

export type TPaginationMoment = {
  data: TMoment[]
  hasMore: boolean
}
