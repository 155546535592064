import { GetState, SetState } from 'zustand'
import { TUserNotifications } from 'models/notification'
import {
  getUserNotifications,
  postNotificationView,
  postUserSeenNotifications,
  readAllNotifications,
  deleteNotification,
} from 'services/user'
import { TGlobalStore } from 'store'

export type TNotificationSlice = {
  notifications: TUserNotifications[] | null
  getNotifications: (userId: string) => Promise<void>
  readNotification: (notificationId: string) => Promise<void>
  seenNotifications: (notificationSeen: boolean, newNotifications?: number) => Promise<void>
  readAllNotifications: () => Promise<void>
  deleteNotification: (notificationId: string) => Promise<void>
}

const createNotificationSlice = (
  set: SetState<TGlobalStore>,
  get: GetState<TGlobalStore>
): TNotificationSlice => ({
  notifications: null,
  getNotifications: async (userId: string) => {
    const { account, seenNotifications } = get()

    const userNotifications = await getUserNotifications(userId)

    if (
      userNotifications.newNotifications > 0 &&
      userNotifications.newNotifications !== account?.user.newNotifications
    ) {
      await seenNotifications(false, userNotifications.newNotifications)
    }

    set(
      {
        notifications: userNotifications.notifications,
      },
      false,
      // @ts-ignore
      'getNotifications'
    )
  },
  readNotification: async (notificationId: string) => {
    const { getNotifications, account } = get()

    await postNotificationView(notificationId)

    if (account) {
      await getNotifications(account?.user?.id)
    }
  },
  seenNotifications: async (notificationSeen: boolean, newNotifications?: number) => {
    const { account } = get()

    await postUserSeenNotifications(notificationSeen)

    if (account) {
      set(
        {
          account: {
            ...account,
            user: {
              ...account.user,
              newNotifications: newNotifications || account.user.newNotifications,
              seenNotifications: notificationSeen,
            },
          },
        },
        false
      )
    }
  },
  readAllNotifications: async () => {
    const { getNotifications, account } = get()

    await readAllNotifications()

    if (account) {
      await getNotifications(account?.user?.id)
    }
  },
  deleteNotification: async (notificationId: string) => {
    const { getNotifications, account } = get()

    await deleteNotification(notificationId)

    if (account) {
      getNotifications(account.user.id)
    }
  },
})

export default createNotificationSlice
