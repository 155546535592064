import * as React from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTheme, useMediaQuery, Stack, Chip } from '@mui/material'
import Alert from '@mui/material/Alert'
import Snackbar from 'components/Snackbar'
import { useSnackbar } from 'hooks'

interface IAppProviderProps {
  children: React.ReactNode
}

const SnackbarProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const theme = useTheme()

  const media = useMediaQuery(theme.breakpoints.down('md'))

  const { message, type, close } = useSnackbar()

  return (
    <>
      {message && type !== 'newPost' && (
        <Snackbar
          open={!!message}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: media ? 'bottom' : 'top',
            horizontal: 'center',
          }}
          onClose={() => close()}
        >
          <Alert
            severity={type}
            variant="filled"
            sx={{ marginTop: '60px', width: '100%', padding: '15px 120px' }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {message && type === 'newPost' && (
        <Snackbar
          open={!!message}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: media ? 'bottom' : 'top',
            horizontal: 'center',
          }}
          onClose={() => close()}
        >
          <Stack direction="row" spacing={1}>
            <Chip
              color="primary"
              label={message}
              icon={<KeyboardArrowUpIcon />}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                close()
              }}
              onDelete={() => close()}
            />
          </Stack>
        </Snackbar>
      )}
      {children}
    </>
  )
}

export default SnackbarProvider
