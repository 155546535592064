export default {
  question_1: {
    question: 'So, how do I learn at Coterie?',
    answer:
      'Coterie will help you get started, even if you don’t have a lot of time on your hands. Gain new skills and increase your confidence with unlimited access to golf & pickleball tutorials delivered in 3 minutes or less!',
  },
  question_2: {
    question: 'How does the community work?',
    answer:
      'You can access the ‘Community’ section hosted on Coterie. You will learn from active members in the community and share your progress with a supportive network!',
  },
  question_3: {
    question: 'Who should join Coterie?',
    answer:
      'Whether you’re an athlete or you only consider yourself “sport curious”, this is your home. Coterie is a social space designed by women, for women. You’ll love it here if you are ready to learn new skills and meet new people!',
  },
  question_4: {
    question: 'What is the membership service offered by Coterie?',
    answer: {
      title: 'Our membership provides access to:',
      item_1: '🎥 Exclusive curated video content, by trusted coaches and pros',
      item_2: '📝 Tipsheets',
      item_3: '😄 Player matching! New friends and connections to get out and play with',
      item_4:
        '📍 Location finder - easy way to find a location for you and your play partner, without all the back-and-forth',
      item_5: '✨ Community platform to share interest and hobbies in a safe & welcoming space',
      item_6: '👜💰 Promotions & discounts',
    },
  },
  question_5: {
    question: 'How does the membership service work?',
    answer: {
      part_1: 'To become a member & part of the community, simply',
      part_2: 'create an account or login',
      part_3:
        ', select the membership plan and provide your payment information. You will be billed automatically at the designated frequency (e.g., monthly, or yearly).',
    },
  },
  question_6: {
    question: 'What’s included in the Coterie membership?',
    answer:
      'Joining our platform offers a range of benefits, including access to exclusive curated content by some of the best coaches and pros in their respective activity, as well as access to our incredible (and growing!) community where you can meet others to get out and play with, as well as exclusive features, promotions and discounts.',
  },
  question_7: {
    question: 'How do I cancel my membership?',
    answer:
      'You can cancel your membership at any time by logging into your account on our website and navigating to the membership management page under the “My Account” tab. Follow the prompts to cancel your membership, and you will not be billed in the future.',
  },
  question_8: {
    question: 'Is there a trial period for the subscription service?',
    answer: 'Yes, we offer a 3-day free trial for all yearly plans',
  },
  question_9: {
    question: 'Will my membership automatically renew?',
    answer:
      'Yes, your membership will automatically renew based on the billing frequency you selected during sign-up (e.g., monthly, or yearly) unless you cancel it before the renewal date.',
  },
  question_10: {
    question: 'What payment methods are accepted?',
    answer: 'We accept major credit cards for payment of the membership.',
  },
  question_11: {
    question: 'What happens if my payment fails?',
    answer:
      'If your payment fails, we will attempt to notify you and provide instructions for updating your payment information. If you do not update your payment information within a designated time frame, your membership may be automatically canceled',
  },
  question_12: {
    question: 'Can I get a refund for my membership?',
    answer: {
      part_1:
        'Refunds for membership are generally not provided, as we are partnering with Stripe to process payments and are charged regardless. As a small business, we’ve worked hard to price our product at a competitive rate and hope you feel the same. However, if you experience any issues or have questions, please contact our',
      part_2: 'customer support',
      part_3: ', and we will do our best to assist you.',
    },
  },
  question_13: {
    question: 'How can I contact customer support for further assistance?',
    answer: {
      part_1: 'You can contact our customer support by emailing',
      part_2: 'info@activecoterie.com',
      part_3:
        ', and our team will be happy to assist you with any questions or issues related to our subscription plans.',
    },
  },
}
