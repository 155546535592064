import { css } from '@emotion/react'

const GlobalStyles = css`
  html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
  }
  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`

export default GlobalStyles
