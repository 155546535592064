import { useState } from 'react'
import { FormLabel, Radio, RadioGroup, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import FormControl from 'components/FormControl'
import FormControlLabel from 'components/FormControlLabel'
import { useAchievements, useModal, useSnackbar } from 'hooks'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { ESubscriptionType } from 'models/subscription'
import { getSubscriptionProductsByDomain, goToCheckout } from 'services/subscription'
import useStore from 'store'
import { ModalTypes } from 'store/slices/Modal'
import Box from '../../Box'
import Card from '../../Card'
import CardContent from '../../CardContent'
import CardHeader from '../../CardHeader'
import LoadingButton from '../../LoadingButton'
import Typography from '../../Typography'

interface SubscriptionFormProps {
  plan: string
  priceId: string
  title?: string
  subtitle?: string
  afterSignUp?: boolean
}

interface SubscriptionCardProps {
  name: string
  description?: string
  price: number
  type: ESubscriptionType
}

const SubscriptionFormWhiteLabel = ({
  title,
  subtitle,
  afterSignUp = false,
}: Pick<SubscriptionFormProps, 'title' | 'subtitle' | 'afterSignUp'>) => {
  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()
  const { t } = useTranslation()
  const { error } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const { closeModal } = useModal()
  const { notificationNewAchievement, getProfileAchievement } = useAchievements()
  const queryKeySubscriptionProducts = 'queryGetSubscriptionProducts'
  const { data: products } = useQuery(queryKeySubscriptionProducts, () =>
    whiteLabel?.domain ? getSubscriptionProductsByDomain(whiteLabel?.domain) : undefined
  )

  const { account } = useStore(store => store)

  const [isLoading, setIsLoading] = useState(false)

  const SubscriptionSchema = Yup.object().shape({
    plan: Yup.string().required('select one plan!'),
  })

  const formik = useFormik<SubscriptionFormProps>({
    initialValues: {
      plan: products && products.length > 0 ? `${products[0].id}.${products[0].type}` : '',
      priceId: products && products.length > 0 ? products[0].priceId : '',
    },
    enableReinitialize: true,
    validationSchema: SubscriptionSchema,
    onSubmit: async ({ plan, priceId }) => {
      if (plan.split('.')[1] === ESubscriptionType.FREE) {
        if ((location?.state as { signup: boolean })?.signup) {
          navigate('/home', { replace: true })
          const profileAchievement = getProfileAchievement()
          notificationNewAchievement({ achievement: profileAchievement })
          return
        }

        navigate('/home', { replace: true })
        window.location.reload()
        closeModal(ModalTypes.SUBSCRIPTION)

        return
      }

      try {
        setIsLoading(true)
        const checkoutUrl = await goToCheckout({
          priceId,
          customerId: account?.user.subscription.customerId as string,
          afterSignUp,
          whiteLabelDomain: whiteLabel?.domain,
        })

        window.location.replace(checkoutUrl)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        error({ message: err.message })
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const planValue = (event.target as HTMLInputElement).value

    formik.setFieldValue('plan', planValue)
    formik.setFieldValue(
      'priceId',
      products?.find(product => product?.id === planValue.split('.')[0])?.priceId
    )
  }

  const getRecurringType = (type: ESubscriptionType) => {
    switch (type) {
      case ESubscriptionType.ANNUAL:
        return '/year'

      case ESubscriptionType.MONTHLY:
        return '/month'

      default:
        return ' one time'
    }
  }

  const SubscriptionCard = ({ name, price, type, description }: SubscriptionCardProps) => {
    return (
      <Box width="228px">
        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeigh: 1.5,
            letterSpacing: '0.031rem',
            margin: '0.5rem 0 0.5rem 0',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Area Normal',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.25,
            color: `${theme.palette.text.primary}`,
          }}
        >
          {`$${price}${getRecurringType(type)}`}
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeight: 1,
            margin: '0.5rem 2rem 0.5rem 0',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {description || ''}
        </Typography>
      </Box>
    )
  }

  const Header = () => {
    return (
      <>
        <Typography
          color="text.primary"
          sx={{
            fontFamily: 'Crimson Text',
            fontSize: '1.375rem',
            fontWeight: 600,
            lineHeight: '1.625rem',
            marginBottom: title ? '1.30rem' : 0,
          }}
        >
          {title || t('subscription.header.title')}
        </Typography>
        {subtitle && (
          <Typography
            color="text.secondary"
            sx={{
              fontFamily: 'Area Normal',
              fontSize: '0,75rem',
              fontWeight: 500,
              lineHeight: '1.25',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </>
    )
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Card variant="outlined" sx={{ maxWidth: '325px' }}>
        <CardHeader title={<Header />} sx={{ paddingBottom: title ? 0 : '16px' }} />
        <FormControl>
          <CardContent>
            <FormLabel
              id="subscription-header-label"
              focused={false}
              sx={{
                fontFamily: 'Area Normal',
                fontSize: '0.75rem',
                fontWeight: 500,
                lineHeight: 1.25,
                paddingBottom: '1.5rem',
                color: `${theme.palette.text.secondary}`,
              }}
            >
              {!subtitle && t('subscription.header.description')}
            </FormLabel>
            <RadioGroup
              aria-labelledby="subscription-header-label"
              value={formik.values.plan}
              onChange={handlePlanChange}
              name="radio-buttons-group"
              sx={{
                mt: '24px',
              }}
            >
              {products?.map(product => (
                <Card
                  key={`product-${product.id}`}
                  sx={{
                    mb: '0.6rem',
                    height: '125px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                  }}
                >
                  <FormControlLabel
                    sx={{ mr: '0.5rem' }}
                    value={`${product.id}.${product.type}`}
                    control={<Radio />}
                    label={
                      <SubscriptionCard
                        name={product.name}
                        price={product.price}
                        type={product.type}
                        description={product?.description}
                      />
                    }
                    labelPlacement="start"
                  />
                </Card>
              ))}
            </RadioGroup>

            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              sx={{
                mt: 2,
                color: whiteLabel?.theme ? theme.palette.text.button : theme.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? theme.palette.secondary.main
                  : theme.palette.lilac.secondary,
                ':hover': {
                  color: whiteLabel?.theme
                    ? theme.palette.text.buttonHover
                    : theme.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? theme.palette.primary.light
                    : theme.palette.lilac.primary,
                },
              }}
            >
              {t('subscription.submit_button')}
            </LoadingButton>
          </CardContent>
        </FormControl>
      </Card>
    </Box>
  )
}
export default SubscriptionFormWhiteLabel
