import yn from 'yn'

export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || ''
export const API_URL = process.env.REACT_APP_API_URL || ''

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || ''
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
export const AUTH0_REDIRECTURI = process.env.REACT_APP_AUTH0_REDIRECTURI || ''
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || ''

export const AUTH0_GRETA_PROJECT_REDIRECTURI = process.env.REACT_APP_AUTH0_GRETA_PROJECT_REDIRECTURI
export const GRETA_PROJECT_GOOGLE_SOCIAL_LOGIN_URL = `${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&connection=google-oauth2&redirect_uri=${AUTH0_GRETA_PROJECT_REDIRECTURI}&state=STATE&audience=${AUTH0_AUDIENCE}&scope=openid%20profile%20email%20address%20phone%20offline_access`
export const GRETA_PROJECT_GOOGLE_FACEBOOK_LOGIN_URL = `${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&connection=facebook&redirect_uri=${AUTH0_GRETA_PROJECT_REDIRECTURI}&state=STATE&audience=${AUTH0_AUDIENCE}&scope=openid%20profile%20email%20address%20phone%20offline_access`

export const GOOGLE_SOCIAL_LOGIN_URL = `${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&connection=google-oauth2&redirect_uri=${AUTH0_REDIRECTURI}&state=STATE&audience=${AUTH0_AUDIENCE}&scope=openid%20profile%20email%20address%20phone%20offline_access`
export const GOOGLE_FACEBOOK_LOGIN_URL = `${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&connection=facebook&redirect_uri=${AUTH0_REDIRECTURI}&state=STATE&audience=${AUTH0_AUDIENCE}&scope=openid%20profile%20email%20address%20phone%20offline_access`
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

export const STRIPE_MONTHLY_PRICEID = process.env.REACT_APP_STRIPE_MONTHLY_PRICEID || ''
export const STRIPE_ANNUAL_PRICEID = process.env.REACT_APP_STRIPE_ANNUAL_PRICEID || ''

export const SHOW_SUBSCRIPTION = yn(process.env.REACT_APP_SHOW_SUBSCRIPTION)

export const SHOW_WAITING_LIST = yn(process.env.REACT_APP_SHOW_WAITING_LIST)

export const ENABLE_GET_LOCATION = yn(process.env.REACT_APP_ENABLE_GET_LOCATION)

export const SHOW_NEW_HOMEPAGE = yn(process.env.REACT_APP_SHOW_NEW_HOMEPAGE)
