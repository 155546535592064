import { GetState, SetState } from 'zustand'
import { TGlobalStore } from 'store'

type tSnackbar = {
  message: string
}

export type AlertColor = 'success' | 'info' | 'warning' | 'error' | 'newPost'

export type TToastlice = {
  message: string | undefined
  type: AlertColor | undefined
  success: ({ message }: tSnackbar) => void
  error: ({ message }: tSnackbar) => void
  newPost: ({ message }: tSnackbar) => void
  close: () => void
}

const createToastSlice = (
  set: SetState<TGlobalStore>,
  get: GetState<TGlobalStore>
): TToastlice => ({
  type: undefined,
  message: undefined,
  success: ({ message }) => {
    set(
      { message },
      false,
      // @ts-ignore
      'success'
    )

    set(
      { type: 'success' },
      false,
      // @ts-ignore
      'success'
    )
  },
  error: ({ message }) => {
    set(
      { message },
      false,
      // @ts-ignore
      'error'
    )

    set(
      { type: 'error' },
      false,
      // @ts-ignore
      'error'
    )
  },
  newPost: ({ message }) => {
    set(
      {
        message,
        type: 'newPost',
      },
      false,
      // @ts-ignore
      'newPost'
    )
  },
  close: () => {
    set(
      { message: undefined },
      false,
      // @ts-ignore
      'close'
    )

    set(
      { type: undefined },
      false,
      // @ts-ignore
      'close'
    )
  },
})

export default createToastSlice
