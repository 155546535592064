/* eslint-disable @typescript-eslint/no-explicit-any */
export function getRegion(
  response?: any,
  address_components?: any
): {
  state: string
  city: string
  country: string
  state_short_name: string
} {
  let state = ''
  let city = ''
  let country = ''
  let state_short_name = ''

  if (response?.results) {
    for (let k = 0; k < response.results.length; k += 1) {
      for (let i = 0; i < response.results[k].address_components.length; i += 1) {
        for (let j = 0; j < response.results[k].address_components[i].types.length; j += 1) {
          switch (response.results[k].address_components[i].types[j] as string) {
            case 'locality':
              city = response.results[k].address_components[i].long_name
              break
            case 'administrative_area_level_1':
              state = response.results[k].address_components[i].long_name
              state_short_name = response.results[k].address_components[i].short_name
              break
            case 'country':
              country = response.results[k].address_components[i].long_name
              break

            default:
              break
          }
        }
      }
    }
  }

  if (address_components) {
    for (let i = 0; i < address_components.length; i += 1) {
      for (let j = 0; j < address_components[i].types.length; j += 1) {
        switch (address_components[i].types[j] as string) {
          case 'locality':
            city = address_components[i].long_name
            break
          case 'administrative_area_level_2':
            if (!city) {
              city = address_components[i].long_name
            }
            break
          case 'administrative_area_level_1':
            state = address_components[i].long_name
            state_short_name = address_components[i].short_name
            break
          case 'country':
            country = address_components[i].long_name
            break

          default:
            break
        }
      }
    }
  }

  return {
    state,
    city,
    country,
    state_short_name,
  }
}
