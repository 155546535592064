export default {
  sign_in: {
    sign_in: 'Login',
    create_account: 'Sign Up',
    error_login: 'Login error!',
    forgot_password: 'Forgot password?',
    dont_have_account: 'Don’t have an account?',
    error: 'Wrong username or password. Please, try again.',
    sign_in_google: 'Sign in with Google',
    sign_in_facebook: 'Sign in with Facebook',
  },
}
