import useStore from 'store'

export default function useNotification() {
  const notifications = useStore(store => store.notifications)
  const getNotifications = useStore(store => store.getNotifications)
  const readNotification = useStore(store => store.readNotification)
  const seenNotifications = useStore(store => store.seenNotifications)
  const readAllNotifications = useStore(store => store.readAllNotifications)
  const deleteNotification = useStore(store => store.deleteNotification)

  return {
    notifications,
    getNotifications,
    readNotification,
    seenNotifications,
    readAllNotifications,
    deleteNotification,
  }
}
