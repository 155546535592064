import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material'
import { Fade, Button, Modal, Typography, Box } from 'components'
import { useAchievements } from 'hooks'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { TAchievementResponse } from 'models/badge'
import useStore from 'store'
import { StyledContainer } from './styles'

interface IEarnBadgeModalProps {
  achievement: TAchievementResponse | null
  open: boolean
  onClose: () => void
}

function EarnBadgeModal({ achievement, open, onClose }: IEarnBadgeModalProps) {
  const theme = useTheme()
  const account = useStore(state => state.account)
  const [abortModal, setAbortModal] = useState<boolean | null>(null)
  const { whiteLabel } = useWhiteLabel()

  const { getBadge } = useAchievements()

  const badge = getBadge({ name: achievement?.achievement.name })

  const handleClose = () => {
    setAbortModal(true)
    setTimeout(() => {
      onClose()
      setAbortModal(null)
    }, 500)
  }

  const showModal = abortModal ? false : open

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      disableEnforceFocus
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500, style: { backgroundColor: theme.shadows[4] } } }}
    >
      <Fade in={open}>
        <StyledContainer>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            p={2}
            sx={{
              borderRadius: '10px',
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box />
              <Typography
                color={whiteLabel?.theme ? theme.palette.text.primary : theme.palette.primary.main}
                fontWeight={600}
                fontSize="22px"
                variant="h3"
                textAlign="center"
                marginBottom="8px"
              >
                Congrats, {account?.user.name}
              </Typography>

              <CloseIcon onClick={handleClose} />
            </Box>

            <Typography
              textAlign="center"
              color={whiteLabel?.theme ? theme.palette.text.secondary : theme.palette.primary.main}
            >
              {badge?.objective}{' '}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{ width: '150px', height: '150px', marginTop: '8px' }}
                src={
                  achievement?.completedAt ? badge?.mini_active_image : badge?.mini_disabled_image
                }
                alt={badge?.name}
              />
            </Box>
          </Box>

          <Typography
            textAlign="center"
            fontWeight={600}
            fontSize="22px"
            variant="h3"
            marginBottom="8px"
            color={whiteLabel?.theme ? theme.palette.text.primary : theme.palette.primary.main}
          >
            {badge?.title ? badge.title : badge?.name}
          </Typography>

          <Typography
            textAlign="center"
            color={whiteLabel?.theme ? theme.palette.text.primary : theme.palette.primary.main}
          >
            {badge?.description}
          </Typography>

          <Box
            sx={{
              flexDirection: 'column',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button
              sx={{
                marginTop: '16px',
                [theme.breakpoints.down('md')]: {
                  marginBottom: '8px',
                  width: '100%',
                },

                color: whiteLabel?.theme ? theme.palette.text.button : theme.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? theme.palette.secondary.main
                  : theme.palette.lilac.secondary,
                ':hover': {
                  color: whiteLabel?.theme
                    ? theme.palette.text.buttonHover
                    : theme.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? theme.palette.primary.light
                    : theme.palette.lilac.primary,
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </StyledContainer>
      </Fade>
    </Modal>
  )
}

export default EarnBadgeModal
