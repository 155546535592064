import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { PERSISTENCE_KEYS } from 'common/constants'
import { API_URL } from 'common/env'
import { ITokenResponse } from 'models/auth'
import store from 'store'

const api = axios.create({
  baseURL: API_URL,
})

const refreshAccessToken = async (): Promise<ITokenResponse> => {
  const token = localStorage.getItem(PERSISTENCE_KEYS.REFRESH_TOKEN)
  const { data } = await api.post<ITokenResponse>('/auth/refresh-token', {
    refreshToken: token,
  })
  localStorage.setItem(PERSISTENCE_KEYS.ACCESS_TOKEN, data.access_token)
  localStorage.setItem(PERSISTENCE_KEYS.REFRESH_TOKEN, data.refresh_token)
  return data
}

const onRequest = (
  config: AxiosRequestConfig,
  keyLocalStorage: string,
  activeBearer = true
): AxiosRequestConfig => {
  const token = localStorage.getItem(keyLocalStorage)
  if (token) {
    return {
      ...config,
      headers: {
        authorization: activeBearer ? `Bearer ${token}` : token,
      },
    }
  }

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => response

let isRefreshing = false
let failedQueue: {
  resolve: (token?: string) => unknown
  reject: (error: AxiosError) => unknown
}[] = []

const processQueue = ({ error, token }: { error?: AxiosError; token?: string }) => {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error)
    } else {
      promise.resolve(token)
    }
  })

  failedQueue = []
}

const onResponseError = async (error: AxiosError, axiosInstance: AxiosInstance) => {
  const originalRequest = error.config as AxiosRequestConfig & { retry?: boolean }

  if (error.response?.status === 401 && !originalRequest.retry) {
    // Queues failed request if it's currently refreshing the access token
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then(() => axiosInstance(originalRequest))
        .catch(err => Promise.reject(err))
    }

    // Otherwise, refresh the access token
    isRefreshing = true

    try {
      const { access_token } = await refreshAccessToken()
      // Process the queue with the new access token
      processQueue({ token: access_token })
      return await axiosInstance(originalRequest)
    } catch (refreshError: unknown) {
      // Process the queue with rejected promises
      store.getState().logout()
      processQueue({ error: refreshError as AxiosError<unknown> })

      window.location.reload()
    }

    isRefreshing = false
  }

  return Promise.reject(error)
}

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
  keyLocalStorage: string,
  activeBearer?: boolean
): AxiosInstance {
  axiosInstance.interceptors.request.use(
    config => onRequest(config, keyLocalStorage, activeBearer),
    onRequestError
  )
  axiosInstance.interceptors.response.use(onResponse, error =>
    onResponseError(error, axiosInstance)
  )
  return axiosInstance
}
