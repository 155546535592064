export enum ETagManagerType {
  SIGN_UP = 'SIGN_UP',
  LOGIN = 'LOGIN',
}

export type TTagManagerHistory = {
  id: string
  userId: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_term: string
  utm_content: string
  type: ETagManagerType
}
