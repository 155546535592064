import components from './components'

export default {
  common: {
    title: 'Coterie',
    star: 'Star',
    learn_more: 'Learn more',
    react: 'React',
    more: 'More',
    back: 'Back',
    send: 'Send',
    lessons: 'lessons',
    edit: 'Edit',
    cancel: 'Cancel',
    components,
    menu: {
      home: 'Home',
      progress: 'Progress',
      content: 'Improve Your Game',
      moments: 'Community',
      profile: 'Profile',
      logout: 'Logout',
      invite_friends: 'Invite friends',
      settings: 'Settings',
      admin: 'Admin',
      player_match: 'Connect and Play',
      your_posts: 'Your Posts',
      subscription: 'Subscription',
    },
    user_card: {
      fill_bio: 'You haven’t filled your bio yet.',
      complete_profile_1: 'Complete your profile',
      complete_profile_2: 'so others can get to know you!',
    },
    achievements_list: {
      achievements: 'Achievements',
      next_achievements: 'Next achievements',
    },
    button: {
      love: 'Love',
      share: 'Share',
      post: 'Post',
      view_all: 'View All',
    },
    programs: 'Programs',
    start_program: 'Start Program',
    quick_tips: 'Quick Tips',
    error: 'Something went wrong. Please try again.',
    improve: {
      title: 'Improving My Game',
      description:
        'It looks like you are not watching any programs yet. Check out our programs and start improving your game!',
      button: 'Choose a Program',
    },
    back_programs: 'Back to Programs',
    all_programs: 'All Programs',
    all_contents: 'All Contents',
    watch_again: 'Watch it Again',
    search_result: 'Search Result',
    chat: {
      header: 'Chat',
      placeholder: 'Write a message',
    },
  },
}
