import { useMediaQuery, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Box from 'components/Box'
import Typography from 'components/Typography'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { ESegmentTrack, segmentService } from 'services/segment'

interface Props {
  text: string
  buttonText: string
  link: string
}

export default function Banner({ text, link, buttonText }: Props) {
  const themeHook = useTheme()
  const { whiteLabel } = useWhiteLabel()
  const media = useMediaQuery(themeHook.breakpoints.down('md'))

  const openInNewTab = () => {
    window.open(link, '_blank', 'noopener,noreferrer')
    segmentService.track(ESegmentTrack.Button_Clicked, {
      button: 'Beta Feedback',
    })
  }

  return (
    <Box
      sx={{
        backgroundColor: whiteLabel?.theme ? whiteLabel?.theme.palette.blue.primary : '#271B47',
        minHeight: media ? '80px' : '60px',
        marginTop: media ? '80px' : '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
      }}
    >
      <Typography
        sx={{
          color: '#ffffff',
          textAlign: 'center',
        }}
      >
        {text}
      </Typography>
      <Button
        sx={{
          color: whiteLabel?.theme ? whiteLabel?.theme.palette.green : '#ffffff',
          textDecoration: 'underline',
        }}
        onClick={openInNewTab}
      >
        {buttonText}
      </Button>
    </Box>
  )
}
