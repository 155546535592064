import * as React from 'react'
import SubscriptionModal from 'components/Subscription/SubscriptionModal'
import SubscriptionSuccessModal from 'components/Subscription/SubscriptionSuccessModal'
import WelcomeModal from 'components/WelcomeModal'
import { useModal } from 'hooks'
import { ModalTypes } from 'store/slices/Modal'

interface IAppProviderProps {
  children: React.ReactNode
}

const ModalProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const { modal, closeModal } = useModal()

  const modals: { [key: string]: JSX.Element } = {
    subscription: (
      <SubscriptionModal
        open={modal.SUBSCRIPTION}
        onClose={() => closeModal(ModalTypes.SUBSCRIPTION)}
      />
    ),
    subscription_success: (
      <SubscriptionSuccessModal
        open={modal.SUBSCRIPTION_SUCCESS}
        onClose={() => closeModal(ModalTypes.SUBSCRIPTION_SUCCESS)}
      />
    ),
    welcome: <WelcomeModal open={modal.WELCOME} onClose={() => closeModal(ModalTypes.WELCOME)} />,
  }

  return (
    <>
      {Object.keys(modals).map(key => (
        <React.Fragment key={key}>{modals[key]}</React.Fragment>
      ))}
      {children}
    </>
  )
}

export default ModalProvider
