export default {
  primary: {
    light: '#F0F1F9',
    main: '#271B47',
    dark: '#645888',
    contrastText: '#FFF',
    modalBackground: 'rgba(101, 88, 136, 0.3)',
  },
  secondary: {
    main: '#D7CFE6',
    light: '#F5F2FB',
    dark: '#CEBFED',
    contrastText: '#F7FBF9',
  },
  error: {
    main: '#D44333',
  },
  success: {
    main: '#09343A',
    light: '#B0DBBE',
  },
  neutral: {
    light: '#FAFAFA',
    main: '#E7E7E9',
    dark: '#B5B5B5',
  },
  text: {
    primary: '#444444',
    secondary: '#666666',
  },
  purple: {
    primary: '#1F1639',
    secondary: '#645888',
    hover: '#F0F1F9',
  },
  lilac: {
    primary: '#D7CFE6',
    secondary: '#CEBFED',
  },
  green: {
    primary: '#09343A',
    secondary: '#B0DBBE',
    viewed: '#589909',
  },
  background: {
    light: '#F0F1F9',
    default: '#FFFFFF',
  },
  common: {
    white: '#FFFFFF',
  },
}
