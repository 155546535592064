import { EMessageType, IChatMessage, IRoom } from 'models/chat'
import api from 'services/api'

interface ICreateChatReq {
  connectedUserId: string[]
}

export interface IPostChatReq {
  roomId: string
  userId: string
  message: string
  type?: EMessageType
  options?: string
}

export interface IPatchChatMessage {
  id: string
  type?: EMessageType
  message?: string
  options?: string
  selectedOption?: string
}

export const getAllChats = async (): Promise<IRoom[]> => {
  const { data } = await api.get('/chat')

  return data
}

export const getChatById = async (
  chatId: string
): Promise<IRoom & { roomMessages: IChatMessage[] }> => {
  const { data } = await api.get(`/chat/${chatId}`)

  return data
}

export const createUserChat = async ({ connectedUserId }: ICreateChatReq): Promise<IRoom> => {
  const { data } = await api.post('/chat', {
    connectedUserId,
  })

  return data
}

export const viewChatById = async (chatId: string): Promise<IRoom> => {
  const { data } = await api.post(`/chat/view/${chatId}`)

  return data
}

export const editChatMessage = async (request: IPatchChatMessage) => {
  const { id: messageId, ...body } = request

  const { data } = await api.patch(`/chat/message/${messageId}`, { ...body })

  return data
}

export const deleteChat = async (chatId: string) => {
  const { data } = await api.delete(`/chat/${chatId}`)

  return data
}
