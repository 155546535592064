export default {
  sign_up: {
    sign_up: 'Sign Up',
    have_account: 'Already have a account?',
    password_must_have: 'Your password must have:',
    password_list_check_num_characters: 'At least 8 characters',
    password_list_check_upper_case: 'At least 1 upper case letter',
    password_list_check_number: 'At least 1 number',
    error: 'Account already exists.',
  },
}
