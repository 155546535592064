/* eslint-disable quotes */
export default {
  subscription: {
    annual_card: {
      title: "Let's Do This",
      pricing: {
        discount: '$84.99/year',
        actual: '$64.99/year',
      },
      free_trial: '3 DAYS FREE TRIAL',
      description:
        'Access to our community + Unlimited Content + Player matching + Location finder',
    },
    monthly_card: {
      title: "Let's Do This",
      pricing: '$6.99/month',
      description:
        'Access to our community + Unlimited Content + Player matching + Location finder',
    },
    free_card: {
      title: 'Free Plan',
      pricing: '$0',
      description: 'Access to our Community + Limited Content',
    },
    submit_button: 'Continue',
    header: {
      title: 'Choose your plan',
      description: 'Looks like you haven’t started to explore the platform yet, but no worries!',
    },
    modal: {
      title: 'Unlock all the Content!',
      subtitle:
        'Looks like you are not a member of our Pro Plan, subscribe now and unlock all the content!',
    },
    cancel: {
      title: 'Are you sure you want to cancel your subscription?',
      subtitle:
        'Are you sure you want to cancel your subscription? We want to make sure you are always improving your game!',
      cancel: 'Cancel',
      continue: 'Continue Learning',
      message: 'Your Subscription was cancelled',
    },
    success: {
      message: 'Your Subscription is confirmed!',
    },
    settings: {
      title: 'Manage Subscription',
      plan: {
        label: 'Your Plan',
        free: 'Free Plan',
        monthly: 'Monthly Plan',
        annual: 'Annual Plan',
      },
      expire_date: {
        label: 'Expiry Date:',
        free: 'Free Forever',
        pro: 'Pro Forever',
        forever: 'Forever',
      },
      upgrade: 'Upgrade Subscription',
      activate_button: 'Activate Subscription',
      cancel_button: 'Cancel Subscription',
    },
  },
}
