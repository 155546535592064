import { rest } from 'msw'
import { API_URL } from 'common/env'
import { auth, account, content, tags, tag } from './resolvers'

export const handlers = [
  rest.post(`${API_URL}/auth/login`, auth),
  rest.post(`${API_URL}/account`, account),
  rest.get(`${API_URL}/account/:id`, account),
  rest.get(`${API_URL}/content`, content),
  rest.get(`${API_URL}/tags`, tags),
  rest.get(`${API_URL}/tags/:id`, tag),
]
