import { ETagManagerType, TTagManagerHistory } from 'models/tag-manager'
import api from 'services/api'

interface IPostTagManagerHistory {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
  type: ETagManagerType
}

export const createTagManagerHistory = async (
  userId: string,
  { utm_source, utm_medium, utm_campaign, utm_term, utm_content, type }: IPostTagManagerHistory
): Promise<TTagManagerHistory> => {
  const { data } = await api.post<TTagManagerHistory>(`/tag-manager/${userId}`, {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    type,
  })

  return data
}
