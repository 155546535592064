import { useTheme } from '@mui/material'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Box from 'components/Box'
import Typography from 'components/Typography'
import { useAchievements } from 'hooks'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { TAchievementResponse } from 'models/badge'

type TBadgeWithProgress = {
  achievement: TAchievementResponse | undefined
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          sx={{
            height: '3px',
            borderRadius: '100px',
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default function BadgeWithProgress({ achievement }: TBadgeWithProgress) {
  const { whiteLabel } = useWhiteLabel()

  const theme = useTheme()

  const { getBadge } = useAchievements()

  const badge = getBadge({ name: achievement?.achievement.name })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 2,
        [theme.breakpoints.down('md')]: {
          border: `1px solid ${theme.palette.neutral.main}`,
          borderRadius: 0.5,
          padding: '8px 16px',
        },
      }}
    >
      <img width="48px" height="48px" alt={badge?.name} src={badge?.mini_disabled_image} />
      <Box
        sx={{ margin: 'auto 0', ml: 1, flexDirection: 'column', width: '100%', maxWidth: '226px' }}
      >
        <Typography
          fontWeight="700"
          color={whiteLabel?.theme ? whiteLabel?.theme.palette.primary.dark : 'primary.dark'}
          fontSize="12px"
        >
          {badge?.name}
        </Typography>
        <Typography
          fontWeight="500"
          color={whiteLabel?.theme ? whiteLabel.theme.palette.text.secondary : 'text.secondary'}
          fontSize="12px"
        >
          {badge?.howToGet}
        </Typography>
        {achievement && (
          <LinearProgressWithLabel
            value={achievement.score * (100 / achievement.achievement.target)}
          />
        )}
      </Box>
    </Box>
  )
}
