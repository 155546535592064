/* eslint-disable quotes */
import {
  ReactContentActive,
  ReactContentInactive,
  ReactContent5Active,
  ReactContent5Inactive,
  ReactContent10Active,
  ReactContent10Inactive,
  ReactContent20Active,
  ReactContent20Inactive,
  ReactMomentActive,
  ReactMomentInactive,
  ReactMoment5Active,
  ReactMoment5Inactive,
  ReactMoment10Active,
  ReactMoment10Inactive,
  ReactMoment20Active,
  ReactMoment20Inactive,
  ShareMomentActive,
  ShareMomentInactive,
  ShareMoment5Active,
  ShareMoment5Inactive,
  ShareMoment10Active,
  ShareMoment10Inactive,
  ShareMoment20Active,
  ShareMoment20Inactive,
  WatchContentActive,
  WatchContentInactive,
  WatchContent5Active,
  WatchContent5Inactive,
  WatchContent10Active,
  WatchContent10Inactive,
  WatchContent20Active,
  WatchContent20Inactive,
  Beginner,
  BeginnerInactive,
  Intermediate,
  IntermediateInactive,
  Advanced,
  AdvancedInactive,
  Default,
  DefaultInactive,
  CompleteProfile,
  CompleteProfileInactive,
  FollowUsersActive,
  FollowUsersInactive,
  FollowUsers5Active,
  FollowUsers5Inactive,
  FollowUsers10Active,
  FollowUsers10Inactive,
  FollowUsers20Active,
  FollowUsers20Inactive,
  InviteFriendsActive,
  InviteFriendsInactive,
  InviteFriends5Active,
  InviteFriends5Inactive,
  FriendsJoinActive,
  FriendsJoinInactive,
  FriendsJoin3Active,
  FriendsJoin3Inactive,
} from 'assets/badge'
import { TBadge } from 'models/badge'

const badges: TBadge[] = [
  {
    name: 'Badge in progress',
    description: 'Badge in progress!',
    actionButtonName: 'In Progress',
    route: 'home',
    objective: 'This badge is in progress',
    howToGet: 'This badge is in progress',
    mini_active_image: Default,
    mini_disabled_image: DefaultInactive,
  },

  {
    name: 'Completing profile',
    description: 'User has completed their profile information',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Complete profile information',
    howToGet: 'User has completed their profile information',
    mini_active_image: CompleteProfile,
    mini_disabled_image: CompleteProfileInactive,
  },

  {
    name: 'Following first person',
    description: 'Follow 1 person',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Follow 1 person',
    howToGet: 'Follow 1 person',
    mini_active_image: FollowUsersActive,
    mini_disabled_image: FollowUsersInactive,
  },
  {
    name: 'Following 5 people',
    description: 'Followed 5 people',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Followed 5 people',
    howToGet: 'Followed 5 people',
    mini_active_image: FollowUsers5Active,
    mini_disabled_image: FollowUsers5Inactive,
  },
  {
    name: 'Following 10 people',
    description: 'Followed 10 people',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Followed 10 people',
    howToGet: 'Followed 10 people',
    mini_active_image: FollowUsers10Active,
    mini_disabled_image: FollowUsers10Inactive,
  },
  {
    name: 'Following 20 people',
    description: 'Followed 20 people',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Followed 20 people',
    howToGet: 'Followed 20 people',
    mini_active_image: FollowUsers20Active,
    mini_disabled_image: FollowUsers20Inactive,
  },

  {
    name: 'One friend joined Coterie',
    description: 'One friend must join Coterie through a invitation',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'One friend must join Coterie through a invitation',
    howToGet: 'One friend must join Coterie through a invitation',
    mini_active_image: FriendsJoinActive,
    mini_disabled_image: FriendsJoinInactive,
  },

  {
    name: 'Three friends joined Coterie',
    description: 'Three friends must join Coterie through a invitation',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Three friends must join Coterie through a invitation',
    howToGet: 'Three friends must join Coterie through a invitation',
    mini_active_image: FriendsJoin3Active,
    mini_disabled_image: FriendsJoin3Inactive,
  },

  {
    name: 'Inviting a friend',
    description: 'One friend must join Coterie through a invitation',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Invite 1 friend to join Coterie',
    howToGet: 'Invite 1 friend to join Coterie',
    mini_active_image: InviteFriendsActive,
    mini_disabled_image: InviteFriendsInactive,
  },

  {
    name: 'Inviting 5 friends',
    description: 'Invite 5 friends to join Coterie',
    actionButtonName: 'Close',
    route: 'home',
    objective: 'Invite 5 friends to join Coterie',
    howToGet: 'Invite 5 friends to join Coterie',
    mini_active_image: InviteFriends5Active,
    mini_disabled_image: InviteFriends5Inactive,
  },
  {
    name: 'Trainee',
    description:
      "Maybe you've played once or twice (okay, a couple times before, or not. Let's go over the basics together).",
    actionButtonName: 'Close',
    route: 'home',
    objective: "You just earned your first badge! We're proud of you for getting started. 👏",
    howToGet:
      "Maybe you've played once or twice (okay, a couple times before, or not. Let's go over the basics together).",
    mini_active_image: Intermediate,
    mini_disabled_image: IntermediateInactive,
  },
  {
    name: 'Apprentice',
    description:
      "You have a solid foundation and are ready to see some improvements. We've got you covered!",
    actionButtonName: 'Close',
    route: 'home',
    objective: "You just earned your first badge! We're proud of you for getting started. 👏",
    howToGet:
      "You have a solid foundation and are ready to see some improvements. We've got you covered!",
    mini_active_image: Beginner,
    mini_disabled_image: BeginnerInactive,
  },
  {
    name: 'Expert',
    description:
      "You know your way around the course and are confident with all the clubs. Let's take things to the next level!",
    actionButtonName: 'Close',
    route: 'home',
    objective: "You just earned your first badge! We're proud of you for getting started. 👏",
    howToGet:
      "You know your way around the course and are confident with all the clubs. Let's take things to the next level!",
    mini_active_image: Advanced,
    mini_disabled_image: AdvancedInactive,
  },
  {
    title: 'Bronze',
    name: 'React to first content',
    description:
      'Congratulations on earning your first badge for engaging with a piece of content. Keep up the great work and let the community know how you feel about another video or tip.',
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'React to one content',
    howToGet:
      'Let the Coterie community know how you feel about a piece of content and earn your first badge.',
    mini_active_image: ReactContentActive,
    mini_disabled_image: ReactContentInactive,
  },

  {
    title: 'Silver',
    name: 'React to 5 contents',
    description:
      "You're on a roll! Thanks for taking the time to let the community know how you feel about 5 pieces of content",
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'React to 5 contents.',
    howToGet: 'React to 5 pieces of content to earn a Silver badge.',
    mini_active_image: ReactContent5Active,
    mini_disabled_image: ReactContent5Inactive,
  },

  {
    title: 'Gold',
    name: 'React to 10 contents',
    description:
      'We appreciate your opinion! Thanks for letting the Coterie community know how you feel about 10 pieces of content',
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'React to 10 contents',
    howToGet: 'React to 10 pieces of content and get your Gold badge. Go on, you can do it!',
    mini_active_image: ReactContent10Active,
    mini_disabled_image: ReactContent10Inactive,
  },

  {
    title: 'Platinum',
    name: 'React to 20 contents',
    description:
      "You're a star! The community appreciates your opinion on 20 pieces of content. Keep sharing your reactions, you'll always be platinum to us!",
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'React to twenty contents',
    howToGet: 'React to 20 pieces of content and earn your Platinum bage. You got this!',
    mini_active_image: ReactContent20Active,
    mini_disabled_image: ReactContent20Inactive,
  },

  {
    title: 'Supporter',
    name: 'React to first moment',
    description:
      'You just earned your first badge in this area! Your support in the community means a lot. Keep it up!',
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'React to 1 moment',
    howToGet: 'React to 1 moment & earn your first badge.',
    mini_active_image: ReactMomentActive,
    mini_disabled_image: ReactMomentInactive,
  },
  {
    title: 'Cheerleader',
    name: 'React to 5 moments',
    description: 'You did it! Thanks for taking the time to engage with your communities moments. ',
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'React to 5 moments',
    howToGet: 'React to 5 moments & earn your Cheerleader badge. Go on, give it a try!',
    mini_active_image: ReactMoment5Active,
    mini_disabled_image: ReactMoment5Inactive,
  },
  {
    title: 'Captain',
    name: 'React to 10 moments',
    description:
      "Look at you go! You've taken the time to lift 10 people up. Keep the good vibes coming! 📣",
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'React to 10 moments',
    howToGet: 'React to 10 moments and earn your Captain badge.',
    mini_active_image: ReactMoment10Active,
    mini_disabled_image: ReactMoment10Inactive,
  },
  {
    title: 'Lead of the pack',
    name: 'React to 20 moments',
    description: 'You just earned a new badge! Your engagement helps us achieve awesomeness! 👏',
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'React to 20 moments',
    howToGet: 'Want to be a leader? React to 20 different moments and earn this badge.',
    mini_active_image: ReactMoment20Active,
    mini_disabled_image: ReactMoment20Inactive,
  },

  {
    title: 'Participant',
    name: 'Share a moment',
    description:
      'Sharing is caring. Congratulations on sharing your first moment with the Coterie community.',
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'Share your first moment',
    howToGet: 'Share your first moment and see what the Coterie community is all about.',
    mini_active_image: ShareMomentActive,
    mini_disabled_image: ShareMomentInactive,
  },
  {
    title: 'Teammate',
    name: 'Share 5 moments',
    description:
      'Your moments are important. Thanks for sharing your thoughts with the community. Keep it up!',
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'Shared 5 moments',
    howToGet: 'Share 5 moments and earn your Teammate badge.',
    mini_active_image: ShareMoment5Active,
    mini_disabled_image: ShareMoment5Inactive,
  },
  {
    title: 'Group Leader',
    name: 'Share 10 moments',
    description:
      "You're leading by example. Well done on sharing 10 moments with the Coterie community.",
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'Shared 10 moments',
    howToGet: 'Share 10 moments with the community and earn your Group Leader badge.',
    mini_active_image: ShareMoment10Active,
    mini_disabled_image: ShareMoment10Inactive,
  },
  {
    title: 'Boss',
    name: 'Share 20 moments',
    description: 'Like a boss! Well done sharing 20 moments with the Coterie community.',
    actionButtonName: 'Go to Moments',
    route: 'moments',
    objective: 'Shared 20 moments',
    howToGet: 'You can do it! Share 20 moments to earn your Boss badge.',
    mini_active_image: ShareMoment20Active,
    mini_disabled_image: ShareMoment20Inactive,
  },

  {
    title: 'Participant',
    name: 'Watch first video content',
    description:
      "Congratulations on investing in yourself. You're on your way to improving your game. 💪",
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'You did it! Well done on watching your first video!',
    howToGet: 'Watch your first video to begin your learning journey.',
    mini_active_image: WatchContentActive,
    mini_disabled_image: WatchContentInactive,
  },
  {
    title: 'Team member',
    name: 'Watch 5 contents',
    description:
      'Congrats taken time to care for yourself and learn something new. Keep it up and watch the improvements. 🚀',
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'Watch 5 videos to set you on the path to growth!',
    howToGet: 'Watch 5 videos to set you on the path to growth!',
    mini_active_image: WatchContent5Active,
    mini_disabled_image: WatchContent5Inactive,
  },
  {
    title: 'Leader',
    name: 'Watch 10 contents',
    description: "You're on your way! Keep up the great work and watch your game improve. 🏆",
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'Watch 10 videos to help improve your game. You got this!',
    howToGet: 'Watch 10 videos to help improve your game. You got this!',
    mini_active_image: WatchContent10Active,
    mini_disabled_image: WatchContent10Inactive,
  },
  {
    title: 'President',
    name: 'Watch 20 contents',
    description:
      "You're a star! We can't wait to see how your game has improved. Share your accomplishment with the Coterie community & post a moment so we can all celebrate you! 💯",
    actionButtonName: 'Go to Contents',
    route: 'content',
    objective: 'Watch 20 videos to take your game to the next level.',
    howToGet: 'Watch 20 videos to take your game to the next level.',
    mini_active_image: WatchContent20Active,
    mini_disabled_image: WatchContent20Inactive,
  },
]

export default badges
