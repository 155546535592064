import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles'
import components from './components'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

declare module '@mui/material/styles' {
  interface TypeText {
    button: string
    buttonHover: string
  }

  interface Palette {
    neutral: Palette['primary']
    text: TypeText
    purple: {
      primary: string
      secondary: string
      hover: string
    }
    lilac: {
      primary: string
      secondary: string
    }
    green: {
      primary: string
      secondary: string
      viewed: string
    }
    blue: {
      primary: string
      secondary: string
    }
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
    purple?: {
      primary: string
      secondary: string
      hover: string
    }
    lilac?: {
      primary: string
      secondary: string
    }
    green?: {
      primary: string
      secondary: string
    }
    blue?: {
      primary: string
      secondary: string
    }
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    main: string
  }
}

const theme: Theme = createTheme({
  palette,
  typography,
  components,
  shadows,
})

export default responsiveFontSizes(theme)
