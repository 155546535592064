// eslint-disable-next-line import/no-cycle
import { getAddress } from 'services/geocode'
import { updateUserLocation } from 'services/user'

export enum ESegmentTrack {
  Sign_Up_Started = 'Sign_Up_Started',
  Sign_Up_Completed = 'Sign_Up_Completed',
  User_Login = 'User_Login',
  User_Invited_Friend = 'User_Invited_Friend',
  Card_Viewed = 'Card_Viewed',
  Card_Clicked = 'Card_Clicked',
  Content_Viewed = 'Content_Viewed',
  Video_Content_Started = 'Video_Content_Started',
  Video_Content_Completed = 'Video_Content_Completed',
  User_Reacted = 'User_Reacted',
  Button_Clicked = 'Button_Clicked',
  Badge_Clicked = 'Badge_Clicked',
  Program_Clicked = 'Program_Clicked',
  Program_Started = 'Program_Started',
  Program_Finished = 'Program_Finished',
  Search = 'Search',
  Tag_Manager = 'Tag_Manager',
  Comment_Added = 'Comment_Added',
  Post_Created = 'Post_Created',
  Chat_Started = 'Chat_Started',
  Player_Match = 'Player_Match',
  Subscription_Created = 'Subscription_Created',
  Subscription_Canceled = 'Subscription_Canceled',
}

export interface PageViewedProps {
  path: string
  referrer: string
  search: string
  title: string
  url: string
}

export interface SignUpCompletedProps {
  email: string
  user_email: string
  user_id: string
}

export interface UserSignedUpProps {
  email: string
}

export interface HomePageProps {
  login: string
}

interface IIdentifyProps {
  name?: string
  email?: string
  plan?: string
  logins?: number
  sportSelected?: {
    sport?: string[] | string
    level?: string[] | string
  }[]
}

export const segmentService = {
  identify: async (userId: string, data: IIdentifyProps) => {
    window.analytics.identify(userId, data)
  },

  track: async (
    type: ESegmentTrack,
    data: object
  ): Promise<{ state: string; city: string; country: string; state_short_name: string }> => {
    if (type === ESegmentTrack.User_Login) {
      const { state, city, country, state_short_name } = await getAddress()

      const analyticsData = { ...data, address: { state, city, country, state_short_name } }

      await updateUserLocation({ state, city, country })

      window.analytics.track(type, analyticsData)

      return { state, city, country, state_short_name }
    }

    window.analytics.track(type, data)

    return {
      city: '',
      country: '',
      state: '',
      state_short_name: '',
    }
  },

  reset: () => {
    window.analytics.reset()
  },
}
