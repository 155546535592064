import { TAchievementResponse, TBadge } from 'models/badge'
import useStore from 'store'

type tGetBadge = {
  name: string | undefined
}

export type TNotificationNewAchievement = {
  achievement: TAchievementResponse | null
}

export type TDetailsAchievement = {
  achievement: TAchievementResponse | null
}

export type TUseAchievements = {
  getBadge: ({ name }: tGetBadge) => TBadge | undefined
  completeAchievement: TAchievementResponse[] | null
  getCompleteAchievement: () => Promise<TAchievementResponse[]>
  getProfileAchievement: () => TAchievementResponse | null
  getViewAchievement: () => Promise<void> | null
  profileAchievement: TAchievementResponse | null
  newAchievement: TAchievementResponse | null
  notificationNewAchievement: ({ achievement }: TNotificationNewAchievement) => void
  detailAchievement: TAchievementResponse | null
  detailsAchievement: ({ achievement }: TNotificationNewAchievement) => void
  verifyAchievements: () => Promise<void>
}

export default function useAchievements(): TUseAchievements {
  const getBadge = useStore(state => state.getBadge)
  const completeAchievement = useStore(state => state.completeAchievement)
  const profileAchievement = useStore(state => state.profileAchievement)
  const newAchievement = useStore(state => state.newAchievement)
  const detailAchievement = useStore(state => state.detailAchievement)
  const detailsAchievement = useStore(state => state.detailsAchievement)
  const verifyAchievements = useStore(state => state.verifyAchievements)
  const notificationNewAchievement = useStore(state => state.notificationNewAchievement)
  const getCompleteAchievement = useStore(state => state.getCompleteAchievement)
  const getProfileAchievement = useStore(state => state.getProfileAchievement)
  const getViewAchievement = useStore(state => state.getViewAchievement)

  return {
    getBadge,
    completeAchievement,
    getCompleteAchievement,
    getProfileAchievement,
    profileAchievement,
    newAchievement,
    notificationNewAchievement,
    detailAchievement,
    detailsAchievement,
    verifyAchievements,
    getViewAchievement,
  }
}
