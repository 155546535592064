import useStore from 'store'

export default function useChat() {
  const chatSelected = useStore(store => store.chatSelected)
  const setChatSelected = useStore(store => store.setChatSelected)
  const chatPlayerRequest = useStore(store => store.chatPlayerRequest)
  const setChatPlayerRequest = useStore(store => store.setChatPlayerRequest)
  const seenChats = useStore(store => store.seenChats)
  const deleteChat = useStore(store => store.deleteChat)

  return {
    chatSelected,
    setChatSelected,
    chatPlayerRequest,
    setChatPlayerRequest,
    seenChats,
    deleteChat,
  }
}
