/* eslint-disable complexity */
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material'
import { useQuery } from 'react-query'
import { Fade, Box, Button, Link, Modal, Typography } from 'components'
import BadgeWithProgress from 'components/BadgeWithProgress'
import { useAchievements } from 'hooks'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { TAchievementResponse } from 'models/badge'
import { getAchievementNext } from 'services/achievement'
import { StyledContainer } from './styles'

interface IBadgeDetailModalProps {
  achievement: TAchievementResponse | null
  open: boolean
  onClose: () => void
}

function BadgeDetailModal({ achievement, open, onClose }: IBadgeDetailModalProps) {
  const [abortModal, setAbortModal] = useState<boolean | null>(null)

  const { getBadge } = useAchievements()
  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()

  const badge = getBadge({ name: achievement?.achievement.name })

  const queryKey = ['getAchievementNext', achievement?.achievement.id]
  const { data: nextAchievement } = useQuery(queryKey, () =>
    achievement?.achievement.id
      ? getAchievementNext({ id: achievement?.achievement.id })
      : undefined
  )

  const handleClose = () => {
    setAbortModal(true)
    setTimeout(() => {
      onClose()
      setAbortModal(null)
    }, 500)
  }

  const showModal = abortModal ? false : open

  const getAchievementCompletedColor = (): string => {
    if (whiteLabel?.theme && achievement?.completedAt) return theme.palette.primary.main
    if (!whiteLabel?.theme && achievement?.completedAt) return '#d5eadc'
    return '#f3f3f4'
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      disableEnforceFocus
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500, style: { backgroundColor: theme.shadows[4] } } }}
    >
      <Fade in={showModal}>
        <StyledContainer>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            p={2}
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '10px',
              background: `linear-gradient(180deg, ${getAchievementCompletedColor()} 35%, white 10%)`,
            }}
          >
            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{ width: '80px', height: '80px', marginTop: '8px' }}
                src={
                  achievement?.completedAt ? badge?.mini_active_image : badge?.mini_disabled_image
                }
                alt={badge?.name}
              />
              <CloseIcon sx={{ position: 'absolute', right: 10, top: 10 }} onClick={handleClose} />
            </Box>

            <Typography
              textAlign="center"
              fontWeight={700}
              fontSize="14px"
              variant="body2"
              color={whiteLabel?.theme ? theme.palette.text.primary : theme.palette.primary.main}
            >
              {badge?.name}
            </Typography>

            <Typography textAlign="center" fontSize="12px" color="text.secondary">
              {badge?.description}
            </Typography>
          </Box>

          {achievement?.completedAt ? (
            <>
              <Typography
                textAlign="center"
                variant="subtitle2"
                mx="34px"
                color={whiteLabel?.theme ? theme.palette.text.primary : theme.palette.primary.main}
              >
                {badge?.objective}
              </Typography>
              {nextAchievement && (
                <>
                  <Typography
                    textAlign="center"
                    fontSize="12px"
                    color="text.secondary"
                    mt={2}
                    mb={1}
                  >
                    Next achievement
                  </Typography>

                  <Box sx={{ display: 'flex', margin: '0 auto', width: '236px', mb: 3 }}>
                    {nextAchievement && <BadgeWithProgress achievement={nextAchievement} />}
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Typography textAlign="center" variant="subtitle2" fontWeight={700} mx="34px">
                You haven’t unlocked this badge yet.
              </Typography>

              <Typography
                textAlign="center"
                fontSize="14px"
                variant="subtitle2"
                margin="8px auto"
                width="90%"
              >
                {badge?.howToGet}
              </Typography>
            </>
          )}

          {badge?.route && (
            <Button
              sx={{
                display: 'flex',
                width: '90%',
                margin: '24px auto',
                color: whiteLabel?.theme ? theme.palette.text.button : theme.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? theme.palette.secondary.main
                  : theme.palette.lilac.secondary,
                ':hover': {
                  color: whiteLabel?.theme
                    ? theme.palette.text.buttonHover
                    : theme.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? theme.palette.primary.light
                    : theme.palette.lilac.primary,
                },
              }}
              variant="contained"
              component={Link}
              href={`/${badge?.route}`}
              onClick={handleClose}
            >
              Go to {badge?.route} page
            </Button>
          )}
        </StyledContainer>
      </Fade>
    </Modal>
  )
}

export default BadgeDetailModal
