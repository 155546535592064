import { AppProvider } from 'containers'
import { worker } from 'mocks/browser'
import Router from 'routes'

if (process.env.REACT_APP_MOCK_ENABLE === 'true') {
  worker.start({
    onUnhandledRequest: 'bypass',
  })
}

const App = () => {
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  )
}

export default App
