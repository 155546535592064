export default {
  moments: {
    share: 'Share your Post',
    description: 'Tell the Coterie communities all the details!',
    link: 'Share an interesting link (optional)',
    upload: 'Upload file',
    shareMoment: 'Share Post',
    cancel: 'Cancel',
    add_title: 'Title of the Post',
    deleteConfirmation: 'Are you sure?',
    deleteText:
      'If you delete a Post, other users will no longer be able to visualize it. This action cannot be reversed.',
    deleteMoment: 'Delete Post',
    delete: 'Delete Post',
    delete_success: 'Your Post has been deleted',
    delete_error: 'Post could not be deleted',
    video: 'Video',
    share_success: '🙏 for sharing!',
    select_sport: 'Select Sport',
    shared: 'shared a Moment',
  },
  comment: {
    comment_button: 'Comment',
    be_the_first: 'Be the first one to comment on this',
    add: 'Add a comment',
    post: 'Post',
    deleteText:
      'If you delete a comment, other users will no longer be able to visualize it. This action cannot be reversed.',
    delete: 'Delete Comment',
    delete_success: 'Your comment has been deleted',
  },
}
