import axios from 'axios'
import { PERSISTENCE_KEYS } from 'common/constants'
import { API_URL } from 'common/env'
import { setupInterceptorsTo } from './interceptors'

const api = axios.create({
  baseURL: API_URL,
})

setupInterceptorsTo(api, PERSISTENCE_KEYS.ACCESS_TOKEN)

export default api
