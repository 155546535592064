import { ResponseComposition, MockedRequest, RestContext } from 'msw'
import { BEARER_TOKEN, EXPIRE_TOKEN } from 'common/constants'

export const auth = (_req: MockedRequest, res: ResponseComposition, ctx: RestContext) => {
  return res(
    ctx.status(200),
    ctx.delay(2000),
    ctx.json({
      access_token: {
        access: {
          token: BEARER_TOKEN,
          expires: EXPIRE_TOKEN,
        },
        refresh: {
          token: BEARER_TOKEN,
          expires: EXPIRE_TOKEN,
        },
      },
    })
  )
}

export const account = (_req: MockedRequest, res: ResponseComposition, ctx: RestContext) => {
  return res(
    ctx.status(200),
    ctx.delay(2000),
    ctx.json({
      id: '1fe29491-836a-423e-8478-4169f8b27fd9',
      email: 'alvaro1.sobral.oliveira@gmail.com',
      role: 'USER',
      createdAt: '2022-05-16T19:42:39.007Z',
      updatedAt: '2022-05-16T19:42:39.008Z',
      user: {
        id: '757ddb83-393b-43c1-b76a-ab5c56252a60',
        firstName: 'Alvaro',
        lastName: 'Oliveira',
        createdAt: '2022-05-16T19:42:39.007Z',
        updatedAt: '2022-05-16T19:42:39.008Z',
        accountId: '1fe29491-836a-423e-8478-4169f8b27fd9',
      },
    })
  )
}
