import { useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Banner from 'components/Banner'
import Box from 'components/Box'
import Footer from 'components/Footer'
import MobileNav from 'components/MobileNav'
import NavBar from 'components/NavBar'
import AchievementProvider from 'containers/AchievementProvider'
import ChatProvider from 'containers/ChatProvider'
import ModalProvider from 'containers/ModalProvider'
import NotificationProvider from 'containers/NotificationProvider'
import { useWhiteLabel } from 'hooks/useWhiteLabel'

const AppLayout = () => {
  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()
  const media = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <AchievementProvider>
      <NotificationProvider>
        <ChatProvider>
          <ModalProvider>
            <Box>
              <NavBar />
              {!whiteLabel?.theme && (
                <Banner
                  text="This is a BETA version of Coterie, which means you are testing the earliest version of the
        product."
                  buttonText="Give your Feedback!"
                  link="https://docs.google.com/forms/d/e/1FAIpQLSd0XDopiEHFkGnfnuoRFd4BBNviWw5FLLno2iKgvbsd8K2K9A/viewform?usp=sf_link"
                />
              )}
              <Box
                sx={{
                  mt: whiteLabel?.theme ? '80px' : 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',

                  [theme.breakpoints.down('md')]: {
                    mt: whiteLabel?.theme ? '80px' : 0,
                  },
                }}
              >
                <Outlet />
                {media && <MobileNav />}
              </Box>
              {!whiteLabel?.theme && <Footer />}
            </Box>
          </ModalProvider>
        </ChatProvider>
      </NotificationProvider>
    </AchievementProvider>
  )
}

export default AppLayout
