import { Box } from '@mui/material'
import { ReactComponent as NewContent } from 'assets/images/content/new_content.svg'
import { ReactComponent as NewQuickTip } from 'assets/images/content/new_quick_tip.svg'
import { ReactComponent as CompletedProgram } from 'assets/images/program/completed_program.svg'

interface MarkProps {
  type?: 'newContent' | 'completedProgram' | 'newQuickTip'
}

export default function Mark({ type = 'newContent' }: MarkProps) {
  return (
    <Box
      id="content-mark"
      sx={
        type === 'newQuickTip'
          ? {
              position: 'absolute',
              zIndex: 1000,
              top: -10,
              right: -10,
            }
          : {
              position: 'absolute',
              zIndex: 1000,
            }
      }
    >
      {type === 'newContent' && <NewContent />}
      {type === 'completedProgram' && <CompletedProgram />}
      {type === 'newQuickTip' && <NewQuickTip />}
    </Box>
  )
}
