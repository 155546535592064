/* eslint-disable prefer-destructuring */
import { GetState, SetState } from 'zustand'
import allBadges from 'common/utils/achievements'
import { TBadge, TAchievementResponse } from 'models/badge'
import {
  getAchievementCompleted,
  getAchievementGroupCompleted,
  getViewsAchievement,
} from 'services/achievement'
import { TGlobalStore } from 'store'

type tGetBadge = {
  name: string | undefined
}

export type TNotificationNewAchievement = {
  achievement: TAchievementResponse | null
}

export type TDetailsAchievement = {
  achievement: TAchievementResponse | null
}

export type TAchievementSlice = {
  badges: TBadge[]
  getBadge: ({ name }: tGetBadge) => TBadge | undefined
  completeAchievement: TAchievementResponse[] | null
  getCompleteAchievement: () => Promise<TAchievementResponse[]>
  profileAchievement: TAchievementResponse | null
  getProfileAchievement: () => TAchievementResponse | null
  getViewAchievement: () => Promise<void> | null
  achievementGroupCompleted: () => Promise<TAchievementResponse[]>
  newAchievement: TAchievementResponse | null
  notificationNewAchievement: ({ achievement }: TNotificationNewAchievement) => void
  detailAchievement: TAchievementResponse | null
  detailsAchievement: ({ achievement }: TDetailsAchievement) => void
  verifyAchievements: () => Promise<void>
}

const createAchievementSlice = (
  set: SetState<TGlobalStore>,
  get: GetState<TGlobalStore>
): TAchievementSlice => ({
  badges: allBadges,
  getBadge: ({ name }) => {
    let badge = allBadges.find(item => item.name === name)
    if (!badge) {
      badge = allBadges[0]
    }
    return badge
  },
  completeAchievement: null,
  getCompleteAchievement: async () => {
    const achievementCompleted = await getAchievementCompleted()

    set(
      { completeAchievement: achievementCompleted },
      false,
      // @ts-ignore
      'getProfileAchievement'
    )

    return achievementCompleted
  },
  profileAchievement: null,
  getProfileAchievement: () => {
    const { completeAchievement } = get()

    if (completeAchievement) {
      const profileAchievement = completeAchievement.filter(
        item => item.achievement.group.name === 'Skill Level'
      )
      const highestAchievement = profileAchievement[0]

      set(
        { profileAchievement: highestAchievement },
        false,
        // @ts-ignore
        'getProfileAchievement'
      )

      return highestAchievement
    }

    return null
  },
  achievementGroupCompleted: async () => {
    const achievementHighestCompleted = await getAchievementGroupCompleted()
    return achievementHighestCompleted
  },
  newAchievement: null,
  notificationNewAchievement: async ({ achievement }: TNotificationNewAchievement) => {
    set(
      { newAchievement: achievement },
      false,
      // @ts-ignore
      'notificationNewAchievement'
    )
  },
  detailAchievement: null,
  detailsAchievement: async ({ achievement }: TDetailsAchievement) => {
    set(
      { detailAchievement: achievement },
      false,
      // @ts-ignore
      'detailsAchievement'
    )
  },
  verifyAchievements: async () => {
    const { completeAchievement, notificationNewAchievement, getCompleteAchievement } = get()
    const achievementCompleted = await getAchievementCompleted()

    const actualLastAchievement = completeAchievement ? completeAchievement[0].achievement : null
    const newLastAchievement = achievementCompleted[0].achievement

    if (actualLastAchievement && newLastAchievement) {
      const hasNewAchievement = actualLastAchievement.id !== newLastAchievement.id
      if (hasNewAchievement) {
        notificationNewAchievement({ achievement: achievementCompleted[0] })
        getCompleteAchievement()
      }
    }
  },
  getViewAchievement: async () => {
    await getViewsAchievement()
  },
})

export default createAchievementSlice
