import { Shadows } from '@mui/material/styles/shadows'

const shadows: Shadows = [
  'none',
  '0px 0px 0px 1px #E0E0E0',
  '0px 1px 8px rgba(0, 0, 0, 0.1)',
  '0px 4px 16px rgba(0, 0, 0, 0.25)',
  'rgba(101, 88, 136, 0.3)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
]

export default shadows
