import { useState } from 'react'
import { FormLabel, Radio, RadioGroup, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { STRIPE_ANNUAL_PRICEID, STRIPE_MONTHLY_PRICEID } from 'common/env'
import FormControl from 'components/FormControl'
import FormControlLabel from 'components/FormControlLabel'
import { useAchievements, useModal, useSnackbar } from 'hooks'
import { goToCheckout } from 'services/subscription'
import useStore from 'store'
import { ModalTypes } from 'store/slices/Modal'
import Box from '../../Box'
import Card from '../../Card'
import CardContent from '../../CardContent'
import CardHeader from '../../CardHeader'
import LoadingButton from '../../LoadingButton'
import Typography from '../../Typography'

interface SubscriptionFormProps {
  plan: 'freePlan' | 'monthlyPlan' | 'annualPlan'
  title?: string
  subtitle?: string
  afterSignUp?: boolean
}

const SubscriptionForm = ({
  title,
  subtitle,
  afterSignUp = false,
}: Pick<SubscriptionFormProps, 'title' | 'subtitle' | 'afterSignUp'>) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { error } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const { closeModal } = useModal()
  const { notificationNewAchievement, getProfileAchievement } = useAchievements()

  const { account } = useStore(store => store)

  const [isLoading, setIsLoading] = useState(false)

  const subscriptionsPlan = {
    freePlan: 'free',
    monthlyPlan: STRIPE_MONTHLY_PRICEID,
    annualPlan: STRIPE_ANNUAL_PRICEID,
  }

  const SubscriptionSchema = Yup.object().shape({
    plan: Yup.string().required('select one plan!'),
  })

  const formik = useFormik<SubscriptionFormProps>({
    initialValues: {
      plan: 'annualPlan',
    },
    validationSchema: SubscriptionSchema,
    onSubmit: async ({ plan }) => {
      if (plan === 'freePlan') {
        if ((location?.state as { signup: boolean })?.signup) {
          navigate('/home', { replace: true })
          const profileAchievement = getProfileAchievement()
          notificationNewAchievement({ achievement: profileAchievement })
          return
        }

        navigate('/home', { replace: true })
        window.location.reload()
        closeModal(ModalTypes.SUBSCRIPTION)

        return
      }

      try {
        setIsLoading(true)
        const checkoutUrl = await goToCheckout({
          priceId: subscriptionsPlan[plan],
          customerId: account?.user.subscription.customerId as string,
          afterSignUp,
        })

        window.location.replace(checkoutUrl)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        error({ message: err.message })
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('plan', (event.target as HTMLInputElement).value)
  }

  const AnnualCard = () => {
    return (
      <Box>
        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeigh: 1.5,
            letterSpacing: '0.031rem',
            margin: '0.5rem 0 0.5rem 0',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {t('subscription.annual_card.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: 500,
            lineHeight: 1.25,
            color: `${theme.palette.text.primary}`,
          }}
        >
          <Typography sx={{ textDecoration: 'line-through', margin: '0 0.3rem 0.5rem 0' }}>
            {t('subscription.annual_card.pricing.discount')}
          </Typography>
          <Typography>{t('subscription.annual_card.pricing.actual')}</Typography>
        </Box>
        <Card
          sx={{
            backgroundColor: '#C7F6E4',
            color: ' #39AE73',
            fontFamily: 'Area Normal',
            fontSize: '9px',
            fontWeight: '500',
            lineHeigh: 1.5,
            letterSpacing: '0.031rem',
            width: 'max-content',
          }}
        >
          <Typography variant="overline" sx={{ margin: '0.25rem 0.75rem 0.25rem 0.75rem' }}>
            {t('subscription.annual_card.free_trial')}
          </Typography>
        </Card>
        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeight: 1,
            color: `${theme.palette.text.secondary}`,
            mt: '0.5rem',
          }}
        >
          {t('subscription.annual_card.description')}
        </Typography>
      </Box>
    )
  }

  const MonthlyCard = () => {
    return (
      <Box>
        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeigh: 1.5,
            letterSpacing: '0.031rem',
            margin: '0.5rem 0 0.5rem 0',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {t('subscription.monthly_card.title')}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Area Normal',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.25,
            color: `${theme.palette.text.primary}`,
          }}
        >
          {t('subscription.monthly_card.pricing')}
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeight: 1,
            margin: '0.5rem 2rem 0.5rem 0',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {t('subscription.monthly_card.description')}
        </Typography>
      </Box>
    )
  }

  const FreeCard = () => {
    return (
      <Box>
        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeigh: 1.5,
            letterSpacing: '0.031rem',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {t('subscription.free_card.title')}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.25,
            margin: '0.5rem 0 0.5rem 0',
            color: `${theme.palette.text.primary}`,
          }}
        >
          {t('subscription.free_card.pricing')}
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Area Normal',
            fontSize: '0.625rem',
            fontWeight: '500',
            lineHeight: 1,
            margin: '0.5rem 0.5rem 0.5rem 0',
            color: `${theme.palette.text.secondary}`,
          }}
        >
          {t('subscription.free_card.description')}
        </Typography>
      </Box>
    )
  }

  const Header = () => {
    return (
      <>
        <Typography
          color={theme.palette.purple.primary}
          sx={{
            fontFamily: 'Crimson Text',
            fontSize: '1.375rem',
            fontWeight: 600,
            lineHeight: '1.625rem',
            marginBottom: title ? '1.30rem' : 0,
          }}
        >
          {title || t('subscription.header.title')}
        </Typography>
        {subtitle && (
          <Typography
            color={theme.palette.purple.primary}
            sx={{
              fontFamily: 'Area Normal',
              fontSize: '0,75rem',
              fontWeight: 500,
              lineHeight: '1.25',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </>
    )
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Card variant="outlined" sx={{ maxWidth: '325px' }}>
        <CardHeader title={<Header />} sx={{ paddingBottom: title ? 0 : '16px' }} />
        <FormControl>
          <CardContent>
            <FormLabel
              id="subscription-header-label"
              sx={{
                fontFamily: 'Area Normal',
                fontSize: '0.75rem',
                fontWeight: 500,
                lineHeight: 1.25,
                paddingBottom: '1.5rem',
                color: `${theme.palette.text.secondary}`,
              }}
            >
              {!subtitle && t('subscription.header.description')}
            </FormLabel>
            <RadioGroup
              aria-labelledby="subscription-header-label"
              value={formik.values.plan}
              onChange={handlePlanChange}
              name="radio-buttons-group"
              sx={{
                mt: '24px',
              }}
            >
              <Card
                sx={{
                  mb: '0.6rem',
                  height: '125px',
                }}
              >
                <FormControlLabel
                  sx={{ mr: '0.5rem' }}
                  value="annualPlan"
                  control={<Radio />}
                  label={<AnnualCard />}
                  labelPlacement="start"
                />
              </Card>
              <Card
                sx={{
                  mb: '0.6rem',
                  height: '125px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '0.5rem',
                }}
              >
                <FormControlLabel
                  sx={{ mr: '0.5rem' }}
                  value="monthlyPlan"
                  control={<Radio />}
                  label={<MonthlyCard />}
                  labelPlacement="start"
                />
              </Card>

              <Card
                sx={{
                  mb: '1rem',
                  height: '125px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '0.5rem',
                }}
              >
                <FormControlLabel
                  sx={{ mr: '0.5rem' }}
                  value="freePlan"
                  control={<Radio />}
                  label={<FreeCard />}
                  labelPlacement="start"
                />
              </Card>
            </RadioGroup>

            <LoadingButton loading={isLoading} variant="contained" type="submit">
              {t('subscription.submit_button')}
            </LoadingButton>
          </CardContent>
        </FormControl>
      </Card>
    </Box>
  )
}
export default SubscriptionForm
