/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, useLocation } from 'react-router-dom'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { ESubscriptionType } from 'models/subscription'
import useStore from 'store'

const PrivateRoute = ({ children }: any) => {
  const isAuthenticated = useStore(state => state.isAuthenticated())
  const account = useStore(store => store.account)
  const { whiteLabel } = useWhiteLabel()
  const location = useLocation()

  if (
    isAuthenticated &&
    whiteLabel?.domain &&
    account?.user?.subscription?.type === ESubscriptionType.FREE &&
    location.pathname !== '/subscription' &&
    location.pathname !== '/personalize'
  ) {
    return <Navigate to="/subscription" replace />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ redirectPath: window.location.pathname }} />
  }

  return children
}

export default PrivateRoute
