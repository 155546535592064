/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate } from 'react-router-dom'
import useStore from 'store'

const ProtectRoute = ({ children }: any) => {
  const isAuthenticated = useStore(state => state.isAuthenticated())

  if (isAuthenticated) {
    return <Navigate to="/home" replace />
  }

  return children
}

export default ProtectRoute
