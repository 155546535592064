import { lazy, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { PERSISTENCE_KEYS } from 'common/constants'
import { SHOW_NEW_HOMEPAGE, SHOW_SUBSCRIPTION } from 'common/env'
import AdminLayout from 'containers/AdminLayout'
import AppLayout from 'containers/AppLayout'
import useQuery from 'hooks/useQuery'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import useStore from 'store'
import PrivateRoute from './privateRoute'
import ProtectRoute from './protectRoute'
import { WhiteLabelRoutes } from './white-label'

// const Home = lazy(() => import('pages/Home'))
const NewHome = lazy(() => import('pages/NewHome'))
const GolfHome = lazy(() => import('pages/GolfHome'))
const PickleballHome = lazy(() => import('pages/PickleballHome'))
const Privacy = lazy(() => import('pages/Privacy'))
const SignIn = lazy(() => import('pages/SignIn'))
const SignUp = lazy(() => import('pages/SignUp'))
const Subscription = lazy(() => import('pages/Subscription'))
const WaitingList = lazy(() => import('pages/WaitingList'))
const Personalize = lazy(() => import('pages/Personalize'))
const Forgot = lazy(() => import('pages/Forgot'))

const Content = lazy(() => import('pages/Content'))
const ContentDetail = lazy(() => import('pages/ContentDetail'))
const ContentFeed = lazy(() => import('pages/ContentFeed'))
const ProgramsDetail = lazy(() => import('pages/ProgramsDetail'))
const ContentListing = lazy(() => import('pages/ContentList'))
const Programs = lazy(() => import('pages/Programs'))

const Moments = lazy(() => import('pages/Moments'))
const MomentDetail = lazy(() => import('pages/MomentDetail'))

const HomePage = lazy(() => import('pages/HomePage'))
const NewHomePage = lazy(() => import('pages/NewHomePage'))

const AdminHomePage = lazy(() => import('pages/Admin/Home'))
const AdminContentDetailPage = lazy(() => import('pages/Admin/ContentDetail'))
const AdminCreateContentPage = lazy(() => import('pages/Admin/CreateContent'))
const AdminPrograms = lazy(() => import('pages/Admin/Programs'))
const AdminCreateProgramPage = lazy(() => import('pages/Admin/CreateProgram'))
const AdminEditProgramPage = lazy(() => import('pages/Admin/EditProgram'))
const AdminNotifications = lazy(() => import('pages/Admin/Notifications'))
const AdminWhiteLabels = lazy(() => import('pages/Admin/WhiteLabels'))
const AdminCreateWhiteLabel = lazy(() => import('pages/Admin/CreateWhiteLabel'))
const AdminEditWhiteLabel = lazy(() => import('pages/Admin/EditWhiteLabel'))

const EditProfile = lazy(() => import('pages/Settings/EditProfile'))
const Account = lazy(() => import('pages/Settings/Account'))
const InviteFriends = lazy(() => import('pages/Settings/InviteFriends'))
const EditSubscription = lazy(() => import('pages/Settings/EditSubscription'))
const Notifications = lazy(() => import('pages/Settings/Notifications'))
const NotificationPreferences = lazy(() => import('pages/Settings/NotificationPreferences'))

const PlayerConnect = lazy(() => import('pages/PlayerConnect'))
const Availability = lazy(() => import('pages/Availability'))

const Callback = lazy(() => import('pages/Callback'))
const Page404 = lazy(() => import('pages/404'))

const Chat = lazy(() => import('pages/Chat'))

const Router = () => {
  const getAccountLevel = useStore(state => state.getAccountLevel)
  const getAccount = useStore(state => state.getAccount)
  const account = useStore(state => state.account)
  const isAdmin = useStore(state => state.isAdmin)
  const { whiteLabel } = useWhiteLabel()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()

  useEffect(() => {
    if (account && getAccountLevel() === false && location.pathname !== '/personalize') {
      navigate('/personalize')
    }
  }, [account, getAccountLevel, location.pathname, navigate])

  useEffect(() => {
    if (!account) getAccount()
  }, [account, getAccount])

  useEffect(() => {
    const utm_source = query.get('utm_source')
    const utm_medium = query.get('utm_medium')
    const utm_campaign = query.get('utm_campaign')
    const utm_term = query.get('utm_term')
    const utm_content = query.get('utm_content')

    if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
      const utmObjectString = JSON.stringify({
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
      })

      localStorage.setItem(PERSISTENCE_KEYS.TAG_MANAGER, utmObjectString)
    }
  }, [query])

  return whiteLabel && whiteLabel.domain ? (
    <WhiteLabelRoutes whiteLabel={whiteLabel} />
  ) : (
    <Routes>
      <Route
        index
        element={
          <ProtectRoute>
            <NewHome />
          </ProtectRoute>
        }
      />

      <Route
        path="/golf"
        element={
          <ProtectRoute>
            <GolfHome />
          </ProtectRoute>
        }
      />

      <Route
        path="/pickleball"
        element={
          <ProtectRoute>
            <PickleballHome />
          </ProtectRoute>
        }
      />

      <Route
        path="/privacy"
        element={
          <ProtectRoute>
            <Privacy />
          </ProtectRoute>
        }
      />

      <Route
        path="/waiting-list"
        element={
          <ProtectRoute>
            <WaitingList />
          </ProtectRoute>
        }
      />

      <Route
        path="/login"
        element={
          <ProtectRoute>
            <SignIn />
          </ProtectRoute>
        }
      />

      <Route
        path="/sign-up"
        element={
          <ProtectRoute>
            <SignUp />
          </ProtectRoute>
        }
      />

      {SHOW_SUBSCRIPTION && (
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/personalize"
        element={
          <PrivateRoute>
            <Personalize />
          </PrivateRoute>
        }
      />

      <Route
        path="/forgot"
        element={
          <ProtectRoute>
            <Forgot />
          </ProtectRoute>
        }
      />

      {/* Auth pages */}
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={SHOW_NEW_HOMEPAGE ? <NewHomePage /> : <HomePage />} />
      </Route>

      <Route
        path="/content"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route path=":slug" element={<ContentDetail />} />
        <Route index element={<Content />} />
      </Route>

      <Route
        path="/content-feed"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<ContentFeed />} />
      </Route>

      <Route
        path="/programs"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Programs />} />
      </Route>
      <Route
        path="/content-list"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<ContentListing />} />
      </Route>

      <Route
        path="/program/:slug"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<ProgramsDetail />} />
        <Route path=":contentSlug" element={<ProgramsDetail />} />
      </Route>

      <Route
        path="/moments"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Moments />} />
      </Route>
      <Route
        path="/moment-detail/:id"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<MomentDetail />} />
        <Route path=":id" element={<MomentDetail />} />
      </Route>

      <Route
        path="/player-connect"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route path="availability" element={<Availability />} />
        <Route path="users-available" />
        <Route index element={<PlayerConnect />} />
      </Route>

      <Route
        path="/chat"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<Chat />} />
        <Route path=":id" element={<Chat />} />
      </Route>

      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route path="edit-profile" element={<EditProfile />} />
        <Route path="account" element={<Account />} />
        <Route path="invite-friends" element={<InviteFriends />} />
        <Route path="subscription" element={<EditSubscription />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="notification-preferences" element={<NotificationPreferences />} />

        <Route index element={<EditProfile />} />
      </Route>

      {isAdmin() ? (
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminHomePage />} />
          <Route path="/admin/content/:id" element={<AdminContentDetailPage />} />
          <Route path="/admin/create-content" element={<AdminCreateContentPage />} />
          <Route path="/admin/programs" element={<AdminPrograms />} />
          <Route path="/admin/create-program" element={<AdminCreateProgramPage />} />
          <Route path="/admin/edit-program/:slug" element={<AdminEditProgramPage />} />
          <Route path="/admin/notifications" element={<AdminNotifications />} />
          <Route path="/admin/create-white-label" element={<AdminCreateWhiteLabel />} />
          <Route path="/admin/white-labels" element={<AdminWhiteLabels />} />
          <Route path="/admin/white-label/:domain" element={<AdminEditWhiteLabel />} />
        </Route>
      ) : null}
      <Route path="/callback" element={<Callback />} />
      <Route path="/privacy.pdf" />
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default Router
