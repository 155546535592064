import { GetState, SetState } from 'zustand'
import { TGlobalStore } from 'store'

export enum ModalTypes {
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS',
  WELCOME = 'WELCOME',
}

type Modal = {
  [key in ModalTypes]: boolean
}

export type TModalSlice = {
  modal: Modal
  openModal: (modal: ModalTypes, openInNext?: ModalTypes[]) => void
  closeModal: (modal: ModalTypes) => void
  nextModals: ModalTypes[]
}

const createModalSlice = (
  set: SetState<TGlobalStore>,
  get: GetState<TGlobalStore>
): TModalSlice => ({
  modal: { SUBSCRIPTION: false, SUBSCRIPTION_SUCCESS: false, WELCOME: false },
  nextModals: [],
  openModal: (modalType: ModalTypes, openInNext?: ModalTypes[]) => {
    const { modal } = get()

    if (openInNext && openInNext.length > 0) {
      set(
        { modal: { ...modal, [modalType]: true }, nextModals: openInNext },
        false,
        // @ts-ignore
        'openModal'
      )

      return
    }

    set(
      { modal: { ...modal, [modalType]: true } },
      false,
      // @ts-ignore
      'openModal'
    )
  },
  closeModal: (modalType: ModalTypes) => {
    const { modal, nextModals } = get()

    if (nextModals.length > 0) {
      set(
        {
          modal: { ...modal, [modalType]: false, [nextModals[0]]: true },
          nextModals: [...nextModals.filter((_val, idx) => idx !== 0)],
        },
        false,
        // @ts-ignore
        'closeModal'
      )

      return
    }

    set(
      { modal: { ...modal, [modalType]: false } },
      false,
      // @ts-ignore
      'closeModal'
    )
  },
})

export default createModalSlice
