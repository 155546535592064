/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { convertToHTML } from 'draft-convert'
import { FormikProps } from 'formik'
import { Editor, EditorState } from 'react-draft-wysiwyg'
import './styles.css'
import { StyledContainer } from './styles'

interface TextEditorProps {
  editorState: EditorState
  onEditorStateChange: (editorState: EditorState) => void
  label: string
  formik?: FormikProps<any>
  field: string
}

export default function TextEditor({
  editorState,
  onEditorStateChange,
  label,
  formik,
  field,
}: TextEditorProps) {
  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent())

    formik?.setFieldValue(`${field}`, html)
  }, [editorState])

  return (
    <StyledContainer
      sx={{
        wordBreak: 'break-all',
        my: '10px',
      }}
    >
      <Editor
        placeholder={label}
        toolbarClassName="toolbar"
        editorClassName="editor"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'emoji'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            className: 'text-styles',
            inDropdown: false,
          },
          emoji: {
            popupClassName: 'emoji-board',
          },
        }}
      />
    </StyledContainer>
  )
}
