import { GetState, SetState } from 'zustand'
import { TGlobalStore } from 'store'

export type TContentSlice = {
  playingContentVideoId?: string
  setPlayingContentVideoId: (contentId: string) => void
}

const createContentSlice = (
  set: SetState<TGlobalStore>,
  get: GetState<TGlobalStore>
): TContentSlice => ({
  playingContentVideoId: undefined,
  setPlayingContentVideoId(contentId) {
    set({ playingContentVideoId: contentId })
  },
})

export default createContentSlice
