/* eslint-disable quotes */
export default {
  player_connect: {
    title: 'Availability',
    loading: 'Searching players...',
    subtitle: 'Signal to others where and when you are available to play!',
    location: {
      title: 'Location',
      subtitle: 'Where are you planning to play?',
      form_error: 'location is required',
    },
    days: {
      title: 'Days',
      subtitle:
        'When are you planning to play? Select the times of the week that best describe your availability.',
      form_error: 'select at least one interval of days',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun',
    },
    skill_level: {
      title: 'Skill Level',
      subtitle: 'What is the skill level of the players you want to be matched?',
      form_error: 'select at least one',
    },
    additional_information: {
      title: 'Additional Information',
      input_placeholder:
        'I just moved to Manhattan and I am looking for new friends to play Pickleball',
    },
    submit: 'Send Request',
    save_button: 'Save',
    submit_success: 'Availability updated!',
    banner: {
      title: 'When do you prefer to play?',
      button: 'Find Players',
    },
    match_found: {
      title: 'We found matches for you!',
      button: 'View All',
    },
    find_players: {
      title1: 'Find players close to you to pratice your skills and have fun!',
      title2: 'Create a match and find players next to you!',
      title3: 'Create a match and find players to practice together!',
      title4: 'Practicing is better with people around. Create a match and find them!',
      title5: 'Find other players nearby and practice together!',
      title6: 'Create a match and have fun with other players close to you!',
    },
  },
  active_requests: {
    title: 'View players in your area',
    connect: 'Connect',
    select: 'Select',
    unselect: 'Unselect',
  },
  your_active_requests: {
    title: 'Your Active Play Requests',
    connect: 'Connect',
  },
  match_found: {
    title: 'Player Connect',
    description: 'Signal to others when and where you are available to play!',
    wait_button: 'I will wait for more matches',
  },
  match_not_found: {
    title: `We’ll keep looking`,
    subtitle: `We couldn’t find other players to match you with yet, but don’t worry! We’ll continue to look for them and pop you an email when we find someone!`,
    emote: '👀',
    view_current_players: 'View current player requests in your area',
    see_my_active: 'See My Active Play Requests',
  },
  your_requests: {
    title: 'Your Active Play Requests',
    location: 'Location',
    date: 'Date',
    time: 'Time',
    skill_level: 'Skill Level',
    more_info: 'More Info',
    sport: 'Sport',
    see_matches: 'View Players',
  },
  request_detail: {
    title: 'Request Details',
    player_matches: 'Player Matches',
    to_main_page: 'Back to Player Connect',
    no_matches: 'No matches for this request yet 🫤',
    not_informed: 'not informed',
  },
  player_location: {
    no_location:
      'It looks like we don’t know your location, so we could not find players in your area!',
    enter_manually_location: 'Enter location manually',
    no_current_players:
      'There are no active players in your area. Invite your friends to Coterie and stay tuned for more matches',
    be_the_first: 'Be the first one to create a Play Request!',
  },
  cancel_request: {
    title: 'Cancel Play Request',
    subtitle1: 'Are you sure you want to cancel this play request?',
    subtitle2: 'This action cannot be undone.',
    cancel: 'Cancel play request',
    quit: 'Don’t cancel request',
  },
  chat: {
    subtitle: 'Chat with a match and get ready to play!',
    tooltip_ignore_preview: 'Ignore location suggestion message',
    preview_title: 'Message Preview',
    delete: 'Delete chat',
    delete_success: 'Chat deleted successfully!',
    modal: {
      title: 'Court Suggestions',
      subtitle1: `Based on your Play Request location, we’ve found these courts.`,
      subtitle2: `Select the ones you’d like to play and send them to your connection!`,
      loading: 'Hang on tight! We are looking for court suggestions for you to get out and play!',
      submit: 'Send court suggestion',
      cancel: 'Skip',
    },
  },
}
