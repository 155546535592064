import { useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Carousel as CarouselComponent } from 'react-responsive-carousel'
import { useNavigate } from 'react-router-dom'
import CoterieLogoImage from 'assets/images/coterie-thumb-name.png'
import Step2Thumb from 'assets/images/welcome-thumb-2.png'
import Step3Thumb from 'assets/images/welcome-thumb-3.png'
import Step4Thumb from 'assets/images/welcome-thumb-4.png'
import Step5Thumb from 'assets/images/welcome-thumb-5.png'
import { Box, Button, Modal, Typography, Stack } from 'components'
import { postWelcomeFlowView } from 'services/user'
import useStore from 'store'
import { CustomCarousel, BackgroundImageContent } from './styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

interface WelcomeModalProps {
  open?: boolean
  onClose: () => void
}

const WelcomeModal = ({ open = false, onClose }: WelcomeModalProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getAccount } = useStore(state => state)
  const media = useMediaQuery(theme.breakpoints.down('sm'))

  const [stepNumber, setStepNumber] = useState<number>(0)

  const steps = [
    {
      title: t('welcome_flow.step_one.title'),
      description: t('welcome_flow.step_one.description'),
      thumbnail: CoterieLogoImage,
    },
    {
      title: t('welcome_flow.step_two.title'),
      description: t('welcome_flow.step_two.description'),
      thumbnail: Step2Thumb,
    },
    {
      title: t('welcome_flow.step_three.title'),
      description: t('welcome_flow.step_three.description'),
      thumbnail: Step3Thumb,
    },
    {
      title: t('welcome_flow.step_four.title'),
      description: t('welcome_flow.step_four.description'),
      thumbnail: Step4Thumb,
    },
    {
      title: t('welcome_flow.step_five.title'),
      description: t('welcome_flow.step_five.description'),
      thumbnail: Step5Thumb,
    },
  ]

  const handleNextStep = async () => {
    if (stepNumber < steps.length - 1) {
      setStepNumber(stepNumber + 1)
    }

    if (stepNumber === steps.length - 1) {
      await postWelcomeFlowView()
      navigate('/content-list')
      onClose()
    }
  }

  const handleClose = async () => {
    await postWelcomeFlowView()
    await getAccount()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      disableAutoFocus
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        borderRadius: '4px',
        maxWidth: '634px',
        px: 1,
      }}
    >
      <Box
        m="1rem"
        sx={{
          backgroundColor: `${theme.palette.background.paper}`,
          borderRadius: '4px',
          width: '100%',
        }}
      >
        <CustomCarousel>
          <CarouselComponent
            infiniteLoop
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            selectedItem={stepNumber}
            onChange={index => {
              setStepNumber(index)
            }}
          >
            {steps.map(step => (
              <Box key={`welcome-flow-${step.title}`}>
                <BackgroundImageContent image={step.thumbnail} />
                <Stack maxWidth="560px" margin="auto" mb={media ? 3 : 7}>
                  <Typography
                    variant="h3"
                    color={theme.palette.green.primary}
                    mt={3}
                    mb={media ? 1 : 3}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="12px"
                    lineHeight="20px"
                    color={theme.palette.text.secondary}
                    width="100%"
                  >
                    {step.description}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </CarouselComponent>
          <Stack spacing={2} width="120px" mt={media ? 2 : 4} mb={1}>
            <Button variant="contained" color="primary" onClick={() => handleNextStep()}>
              {stepNumber === steps.length - 1 ? 'Start Now!' : 'Next'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => handleClose()}>
              {stepNumber === steps.length - 1 ? 'Close' : 'Skip'}
            </Button>
          </Stack>
        </CustomCarousel>
      </Box>
    </Modal>
  )
}

export default WelcomeModal
