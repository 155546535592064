import { EMomentSport } from 'models/moment'
import { NotificationType, TUserNotifications } from 'models/notification'
import { IPlayerRequest as PlayerRequestModel } from 'models/player-request'
import { IUser } from 'models/user'
import api from 'services/api'

export interface IUserViews {
  userId: string
  viewedContentsIds: string[]
  viewedProgramsIds: string[]
  completedProgramsIds: string[]
  programsInProgress?: { programId: string; viewedContentsIds: string[] }[]
}

export interface IPatchUserLocation {
  state?: string
  city?: string
  country?: string
}

interface IDeleteNotification {
  notificationId: string
}

export interface IPostNotification {
  userId: string | null
  userIds?: string[]
  message: string
  redirectLink: string
  type: NotificationType
  forUsersSport?: string[]
  image?: Blob
}

export interface IPatchNotificationPreferences {
  allowCommentNotifications: boolean
  allowPostNotifications: boolean
}

export interface IPlayerRequest {
  sport: EMomentSport
  skillLevel: string[]
  minRating?: number
  maxRating?: number
  state: string
  city: string
  country: string
  start_date: string
  end_date?: string
  information?: string
}

interface IGetPlayerRequestArea {
  requestId?: string
  state?: string
  city?: string
}

interface IDeleteRequest {
  playerRequestId: string
}

interface IAvailabilityRequest {
  isActive: boolean
  primaryLocation: { id?: string; state?: string; city?: string; country?: string }
  secondaryLocation?: {
    state?: string
    city?: string
    country?: string
  }
  tertiaryLocation?: {
    state?: string
    city?: string
    country?: string
  }
  days?: { intervals: string[] }[]
  skillLevel: string[]
  exceptions?: { time_begin: string; time_end: string }[]
  information?: string
}

export const getUserViews = async (): Promise<IUserViews> => {
  const { data } = await api.get('user/views/all')

  return data
}

export const getAvailablePlayers = async (sendMail?: boolean): Promise<PlayerRequestModel[]> => {
  const { data } = await api.get<PlayerRequestModel[]>(`user/player-match?sendMail=${sendMail}`)

  return data
}

export const getPlayerRequestById = async (requestId: string): Promise<PlayerRequestModel> => {
  const { data } = await api.get(`/user/player-request/${requestId}`)

  return data
}

export const getPlayerRequestsInArea = async ({
  requestId,
  state,
  city,
}: IGetPlayerRequestArea): Promise<PlayerRequestModel[] | undefined> => {
  let data: PlayerRequestModel[] | undefined

  if (state || city) {
    const response = await api.post('user/player-match/area', {
      requestId,
      state: state?.toUpperCase(),
      city: city?.toUpperCase(),
    })

    data = response.data
  }

  return data
}

export const deletePlayerRequest = async ({
  playerRequestId,
}: IDeleteRequest): Promise<PlayerRequestModel[]> => {
  const { data } = await api.delete(`/user/player-request/${playerRequestId}`)

  return data
}

export const getAllPlayerRequests = async (): Promise<PlayerRequestModel[]> => {
  const { data } = await api.get('user/player-request/all')

  return data
}

export const postPlayerRequest = async ({
  sport,
  skillLevel,
  minRating,
  maxRating,
  state,
  city,
  country,
  start_date,
  end_date,
  information,
}: IPlayerRequest): Promise<PlayerRequestModel> => {
  const { data } = await api.post<PlayerRequestModel>('user/player-request', {
    sport,
    skillLevel: JSON.stringify(skillLevel),
    minRating,
    maxRating,
    state: state.toUpperCase(),
    city: city.toUpperCase(),
    country: country.toUpperCase(),
    availability: JSON.stringify({ start_date, end_date }),
    information,
  })

  return data
}

export const updatePlayerRequest = async (
  requestId: string,
  {
    sport,
    skillLevel,
    minRating,
    maxRating,
    state,
    city,
    country,
    start_date,
    end_date,
    information,
  }: IPlayerRequest
) => {
  const { data } = await api.patch<PlayerRequestModel>(`user/player-request/${requestId}`, {
    sport,
    skillLevel: JSON.stringify(skillLevel),
    minRating,
    maxRating,
    state: state.toUpperCase(),
    city: city.toUpperCase(),
    country: country.toUpperCase(),
    availability: JSON.stringify({ start_date, end_date }),
    information,
  })

  return data
}

export const postWelcomeFlowView = async (): Promise<IUser> => {
  const { data } = await api.post('user/welcome')

  return data
}

export const updateUserLocation = async ({
  state,
  city,
  country,
}: IPatchUserLocation): Promise<IUser> => {
  const { data } = await api.patch('/user/location', {
    state,
    city,
    country,
  })

  return data
}

export const getUserNotifications = async (
  userId: string
): Promise<{ newNotifications: number; notifications: TUserNotifications[] }> => {
  const { data } = await api.get(`/notification/user/${userId}`)

  return data
}

export const postNotificationView = async (notificationId: string): Promise<TUserNotifications> => {
  const { data } = await api.post(`/notification/user/${notificationId}/read`)

  return data
}

export const deleteNotification = async (notificationId: string): Promise<IDeleteNotification> => {
  const { data } = await api.delete(`/notification/${notificationId}`)

  return data
}

export const postAdminNotification = async ({
  message,
  redirectLink,
  type,
  forUsersSport,
  image,
}: IPostNotification) => {
  if (image && image?.size !== 0) {
    const formData = new FormData()
    formData.append('image', image)

    formData.append('message', message)
    if (redirectLink) {
      formData.append('redirectLink', redirectLink)
    }

    formData.append('type', type)
    formData.append('forUsersSport', JSON.stringify(forUsersSport))

    const { data } = await api.post('/notification', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data
  }

  const { data } = await api.post('/notification', {
    message,
    redirectLink,
    type,
    forUsersSport,
  })

  return data
}

export const postUserSeenNotifications = async (notificationSeen: boolean): Promise<unknown> => {
  const { data } = await api.post(`user/notification-view?view=${notificationSeen}`)

  return data
}

export const updateUserNotificationsPreference = async ({
  allowCommentNotifications,
  allowPostNotifications,
}: IPatchNotificationPreferences) => {
  const { data } = await api.patch('user/notification/preferences', {
    allowCommentNotifications,
    allowPostNotifications,
  })

  return data
}

export const readAllNotifications = async (): Promise<void> => {
  const { data } = await api.post('notification/user/read/all')

  return data
}

export const postUserSeenChatMessages = async (
  chatSeen: boolean
): Promise<{ seenChatMessages: boolean; newChatMessages: number }> => {
  const { data } = await api.post(`user/chat-view?view=${chatSeen}`)

  return data
}

export const getPlayerRequestSuggestedLocations = async (
  playerRequestId: string
): Promise<{ text: string }[]> => {
  const { data } = await api.get(`/user/player-request/${playerRequestId}/suggest-locations`)

  return data
}

export const postAvailability = async ({
  isActive,
  primaryLocation,
  secondaryLocation,
  tertiaryLocation,
  days,
  exceptions,
  skillLevel,
  information,
}: IAvailabilityRequest) => {
  const { data } = await api.post('/user/availability', {
    isActive,
    primaryLocation,
    secondaryLocation,
    tertiaryLocation,
    days,
    exceptions,
    skillLevel,
    information,
  })

  return data
}

export const updateAvailability = async ({
  isActive,
  primaryLocation,
  secondaryLocation,
  tertiaryLocation,
  days,
  exceptions,
  skillLevel,
  information,
}: IAvailabilityRequest) => {
  const { data } = await api.patch('user/availability', {
    isActive,
    primaryLocation,
    secondaryLocation,
    tertiaryLocation,
    days,
    exceptions,
    skillLevel,
    information,
  })

  return data
}
