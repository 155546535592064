export default {
  home: {
    login: 'Login',
    sign_up: 'Sign Up',
    menu: 'Menu',
    join_us: 'Join us',
    stats: 'Stats',
    teaser_video: 'Learn',
    testimonials: 'Founders',
    title_h1: 'Welcome to React and react-i18next',
    main_message:
      'Are you looking to connect with like-minded women, and (re)discover your passion for sports & activities?',
    sub_message:
      'Welcome to Coterie - the ultimate solution designed by women, for women to provide a sense of belonging, regardless of your age, background, or skill level.',
    sign_up_main_button: 'Sign up to find out more',
    about_women: 'Let’s talk about women in sport',
    font: 'Font: Women in Sport, 2021',
    begin_learn_journey: 'Begin your learning journey here',
    enjoy_trailer: 'Discover what our platform has to offer.',
    people_behind: 'The Founders Behind the Company',
    frequently_ask: 'Frequently Asked Questions',
    frequently_ask_description:
      'If you are hesitating, do not worry - we are here to explain everything you might want to know. Let us help!',
    carol_miranda_description:
      'I truly believe that sport is a great way to connect with people, be active, healthy, have fun and enjoy life. So I could not be more excited to be in this journey, connecting women - one sport and one opportunity at a time.',
    larissa_homes_description:
      'There are very few products for women over 50 yrs. old that is about anything other than kids, menopause or career. While I’m not looking 50 in the mirror yet, I know I’ll want to talk about other things at that point in my life. Enter Coterie: our mission is to democratize private club sports for women (starting with golf).',
    people_behind_description:
      'We’re Larissa & Carol, two female entrepreneurs founding a company to inspire women to find their community',
    engage_with_community: 'Engage with the community and improve your game',
    about_women_3:
      'of women were worried that getting back into the habit of exercise post lockdown would be hard.',
    about_women_2:
      'of women have seen their professional performance judged by their sporting ability.',
    about_women_1:
      'of the women working in sport feel they are valued less than men in the workplace because of their gender.',
    how_it_works: {
      title: 'How it works',
      description:
        'Coterie is the first online community designed to improve longevity for women by helping them build stronger social connections. Lets redefine what it means to be an athlete together.',
      play: {
        title: 'Play',
        description:
          'Get on the course with other women who share your interest in sports and socializing.',
      },
      connect: {
        title: 'Connect',
        description:
          'Meet other like-minded players, virtually and in real life. Coterie is a private club for all women.',
      },
      learn: {
        title: 'Learn',
        description:
          'Access everything you need to know about playing club sports - starting with golf - designed by and for women.',
      },
    },
    president: {
      title: 'President',
      description: 'Watch 20 Video Contents',
    },
    platinum: {
      title: 'Platinum',
      description: 'React to 20 Contents',
    },
    boss: {
      title: 'Boss',
      description: 'Share 20 Moments',
    },
    lead_of_the_pack: {
      title: 'Lead of the Pack',
      description: 'React to 20 Moments',
    },
    blog: 'Our Blog',
    golf_menu: 'Golf',
    pickleball_menu: 'Pickleball',
    blog_menu: 'Blog',
    pricing: {
      title: 'Invest in Yourself',
      subtitle: 'You can choose the plan that fits your needs',
      features: {
        community: 'Access to our community',
        limited_content: 'Access to limited content',
        unlimited_content: 'Access to unlimited content',
        matching: 'Player matching',
        finder: 'Location finder',
        pro: 'Find & book a Pro',
        gear: 'Unique discounts',
        clinic: 'Local League and Clinic finder',
      },
      freePlan: {
        title: 'Free Plan',
        yearly_price: '$0',
        action: 'Free',
      },
      proPlan: {
        // eslint-disable-next-line quotes
        title: "Let's Do this",
        yearly_price: '$64.99/year',
        monthly_price: 'or $6.99/month',
        // eslint-disable-next-line quotes
        action: "Let's start",
      },
      premiumPlan: {
        // eslint-disable-next-line quotes
        title: "I'm In It To Win It",
        yearly_price: '$89.99/year',
        monthly_price: 'or $9.99/month',
        // eslint-disable-next-line quotes
        action: "I'm ready",
      },
      modal: {
        title: 'So Close',
        subtitle1: 'Our Premium Plan is not available just yet',
        subtitle2: 'BUT we we will keep you updated once it is',
        body1: 'Coterie is currently running a very EARLY version of the platform.',
        body2: 'We are offering to all our members our Pro Plan for free!',
        action: 'Join Now!',
      },
    },
  },

  new_home: {
    login: 'Login',
    sign_up: 'Sign Up',
    menu: 'Menu',
    // eslint-disable-next-line quotes
    join_us: "Let's chat",
    stats: 'Stats',
    teaser_video: 'Learn',
    testimonials: 'Founders',
    title_h1: 'Welcome to React and react-i18next',
    main_message: 'Where professionals monetize their community.',
    sub_message: 'Block out the noise for your players & make money while you’re not playing',
    // eslint-disable-next-line quotes
    sign_up_main_button: "Let's chat",
    about_women: 'Let’s talk about women in sport',
    font: 'Font: Women in Sport, 2021',
    begin_learn_journey: 'Begin your learning journey here',
    enjoy_trailer: 'Discover what our platform has to offer.',
    people_behind: 'The Founders Behind the Company',
    frequently_ask: 'Frequently Asked Questions',
    frequently_ask_description:
      'If you are hesitating, do not worry - we are here to explain everything you might want to know. Let us help!',
    carol_miranda_description:
      'I truly believe that sport is a great way to connect with people, be active, healthy, have fun and enjoy life. So I could not be more excited to be in this journey, connecting women - one sport and one opportunity at a time.',
    larissa_homes_description:
      'There are very few products for women over 50 yrs. old that is about anything other than kids, menopause or career. While I’m not looking 50 in the mirror yet, I know I’ll want to talk about other things at that point in my life. Enter Coterie: our mission is to democratize private club sports for women (starting with golf).',
    people_behind_description:
      'We’re Larissa & Carol, two female entrepreneurs founding a company to inspire women to find their community',
    engage_with_community: 'Engage with the community and improve your game',
    about_women_3:
      'of women were worried that getting back into the habit of exercise post lockdown would be hard.',
    about_women_2:
      'of women have seen their professional performance judged by their sporting ability.',
    about_women_1:
      'of the women working in sport feel they are valued less than men in the workplace because of their gender.',
    how_it_works: {
      title: 'How it works',
      description:
        // eslint-disable-next-line quotes
        "Coterie is the premier platform for sport professionals, designed exclusively for certified pros, instructors, coaches, and clubs. It’s not just a platform; it's your ticket to unlocking the full potential of your players in a centralized, easy-to-use customized platform",
      play: {
        title: 'Closed Community',
        description:
          // eslint-disable-next-line quotes
          "Block out the noise of other instructors and coaches by creating a closed community, exclusively for your players. It's branded with your logo and colors, providing a personalized space for your community.",
      },
      connect: {
        title: 'Replace fragmented communication',
        description:
          'Say goodbye to scattered messages on email, Instagram, Slack, Facebook groups, and more. Coterie offers one easy-to-use platform that keeps your players engaged and connected. Streamline your communication for a seamless experience.',
      },

      learn: {
        title: 'Automated Matching',
        description:
          'Tired of players asking you to find them other players to play with? Say farewell to the hassle of WhatsApp & text introductions. Let your players connect with each other effortlessly to schedule games and matches. No more worrying about them switching to another instructor; Coterie keeps it all in the family.',
      },
    },
    president: {
      title: 'President',
      description: 'Watch 20 Video Contents',
    },
    platinum: {
      title: 'Platinum',
      description: 'React to 20 Contents',
    },
    boss: {
      title: 'Boss',
      description: 'Share 20 Moments',
    },
    lead_of_the_pack: {
      title: 'Lead of the Pack',
      description: 'React to 20 Moments',
    },
    blog: 'Our Blog',
    golf_menu: 'Golf',
    pickleball_menu: 'Pickleball',
    blog_menu: 'Blog',
    pricing: {
      title: 'Invest in your Business',
      // eslint-disable-next-line quotes
      button_chat: "Let's chat",
      subtitle: 'You can choose the plan that fits your needs',
      features: {
        community: 'Access to our community',
        limited_content: 'Access to limited content',
        unlimited_content: 'Access to unlimited content',
        matching: 'Player matching',
        finder: 'Location finder',
        pro: 'Find & book a Pro',
        gear: 'Unique discounts',
        clinic: 'Local League and Clinic finder',
      },
      freePlan: {
        title: 'Free Plan',
        yearly_price: '$0',
        action: 'Free',
      },
      proPlan: {
        // eslint-disable-next-line quotes
        title: "Let's Do this",
        yearly_price: '$64.99/year',
        monthly_price: 'or $6.99/month',
        // eslint-disable-next-line quotes
        action: "Let's start",
      },
      premiumPlan: {
        // eslint-disable-next-line quotes
        title: "I'm In It To Win It",
        yearly_price: '$89.99/year',
        monthly_price: 'or $9.99/month',
        // eslint-disable-next-line quotes
        action: "I'm ready",
      },
      modal: {
        title: 'So Close',
        subtitle1: 'Our Premium Plan is not available just yet',
        subtitle2: 'BUT we we will keep you updated once it is',
        body1: 'Coterie is currently running a very EARLY version of the platform.',
        body2: 'We are offering to all our members our Pro Plan for free!',
        action: 'Join Now!',
      },
    },
  },
  golf: {
    main_message: 'Forget the ‘old boys club’ – its time you join the Coterie community of women!',
    sub_message: 'Learn the rules, etiquette and make some friends you can hit the green with.',
    sign_up_main_button: 'Join Our Community',
    about_women_3: 'of new golfers who played during the pandemic were female',
    about_women_2:
      'of new golfers in 2021 were female. This represents a proportion higher than 25% female representation among overall U.S golf population.',
    about_women_1:
      'women who want to play golf don’t know where to get started or have people to play with.',
    font: 'Font: golf.com , 2021',
    how_it_works: {
      title: 'How it works',
      description:
        'Coterie will be the first online community hub to empower women by democratizing private club sports through technology.',
      play: {
        title: 'Play',
        description:
          'Get on the course with other women who share your interest in sports and socializing.',
      },
      connect: {
        title: 'Connect',
        description:
          'Find your people, virtually and IRL who share your interest in golf. Learn together and celebrate each other’s wins!',
      },
      learn: {
        title: 'Learn',
        description:
          'Power through our tutorials and tip sheets; everything from what to wear on the course to technical swing tips from our coaches. We’ve got you covered from head to tee!',
      },
    },
    video: {
      title: 'See how Coterie is learning that fits your life',
      description:
        'whether you’re learning for the first time or looking to brush up on some skills, Coterie has it all. Check out our full library of content to grow your game.',
      videoTitle: 'Your First Swing: Beginner Golf Posture',
      videoAuthor: 'Gianna Augustine,',
      videoAuthorTitle: 'PGA Professional',
      videoDescription:
        'Golf is complicated. It’s never too early or too late to learn (or review) the basics. Our custom curated sports content will arm you with the skills to support your game on and off the course!',
      buttonText: 'Join Our Community',
    },
    frequently_ask: 'Frequently Asked Questions',
    frequently_ask_description:
      'If you are hesitating, do not worry - we are here to explain everything you might want to know. Let us help!',
  },
  pickleball: {
    main_message:
      'You want to play pickleball? Get into the game through Coterie’s fun, active and social space!',
    sub_message: 'Coterie is changing the game, literally. You belong here.',
    sign_up_main_button: 'Join Our Community',
    about_women_3:
      'of increase of people interested in pickleball over the past 5 years, according to Google Trends.',
    about_women_2: 'is the total prize pool of pickleball tournaments held in 2022.',
    about_women_1: 'is the year-over-year growth of female pickleball players in 2021.',
    font: 'Font: pickleheads.com , 2021',
    how_it_works: {
      title: 'How it works',
      description:
        'Coterie will be the first online community hub to empower women by democratizing private club sports through technology.',
      play: {
        title: 'Play',
        description:
          'Get on the court with other women who share your interest in sports and socializing.',
      },
      connect: {
        title: 'Connect',
        description:
          'Meet other like-minded players, virtually and in real life. Coterie is a private club for all women. ',
      },
      learn: {
        title: 'Learn',
        description: 'Grow your Pickleball game – designed by women for women.',
      },
    },
    video: {
      title: '',
      description: '',
      videoTitle: '',
      videoAuthor: '',
      videoAuthorTitle: '',
      videoDescription: '',
      buttonText: '',
    },
    frequently_ask: 'Frequently Asked Questions',
    frequently_ask_description:
      'If you are hesitating, do not worry - we are here to explain everything you might want to know. Let us help!',
  },
}
