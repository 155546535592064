import { TMoment } from './moment'

export enum NotificationType {
  ADMIN = 'ADMIN',
  COMMENT = 'COMMENT',
  POST = 'POST',
  AVAILABILITY = 'AVAILABILITY',
}

export interface TNotification {
  id: string
  type: NotificationType
  authorId: string
  moment: TMoment | null
  momentId: string | null
  message: string
  redirectLink: string
  createdAt: Date
  user: {
    newNotifications: number
  }
  author: {
    name: string
    file: {
      fileUrl: string
    } | null
  }
  file: {
    fileUrl: string
  } | null
}

export interface TUserNotifications {
  userId: string
  notificationId: string
  read: boolean
  notification: TNotification
}
