import { IStripeProduct } from './stripe-product'

export enum ESubscriptionType {
  FREE = 'FREE',
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
  ONE_TIME = 'ONE_TIME',
}

export interface ISubscription {
  id: string
  userId: string
  customerId: string
  subscriptionId: string
  stripeProductId?: string
  product?: IStripeProduct
  type: ESubscriptionType
  createdAt: Date
  updatedAt: Date
  expiresIn?: Date
  expiredAt?: Date
}
