import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'hooks'
import useNotification from 'hooks/useNotification'
import { useSocket } from 'hooks/useSocket'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { TContent } from 'models/content'
import { TMoment } from 'models/moment'
import useStore from 'store'

interface INotificationProviderProps {
  children: React.ReactNode
}

const NotificationProvider = ({ children }: INotificationProviderProps) => {
  const { notifications, getNotifications } = useNotification()
  const account = useStore(store => store.account)
  const { socket } = useSocket()
  const { newPost } = useSnackbar()
  const queryClient = useQueryClient()
  const { whiteLabel } = useWhiteLabel()

  useEffect(() => {
    if (account && !notifications) {
      getNotifications(account.user.id)
    }

    socket.on('notification', (notificationsUsersIds: string[]) => {
      if (account && notificationsUsersIds.find(id => account.user.id === id)) {
        getNotifications(account.user.id)
      }
    })

    socket.on('content:new', async (content: TContent & { userRole: string }) => {
      if (
        (whiteLabel?.theme && content.userRole === 'USER_GRETA') ||
        (!whiteLabel?.theme && content.userRole !== 'USER_GRETA')
      ) {
        await queryClient.invalidateQueries('getContentsForMe')
      }

      if (
        window.location.pathname.includes('/home') &&
        window.scrollY !== 0 &&
        account?.user?.id !== content.createdById
      ) {
        newPost({
          message: 'We have a new content!',
        })
      }
    })

    socket.on('moment:new', async (moment: TMoment & { userRole: string }) => {
      if (
        (whiteLabel && moment.userRole === 'USER_GRETA') ||
        (!whiteLabel && moment.userRole !== 'USER_GRETA')
      ) {
        await queryClient.invalidateQueries('getMoments')
      }

      if (
        window.location.pathname.includes('/home') &&
        window.scrollY !== 0 &&
        account?.user?.id !== moment.createdById &&
        !!account?.user.tags.find(tag => tag.tag.sport === moment.sport.toLowerCase())
      ) {
        newPost({
          message: 'We have a new post from the community!',
        })
      }
    })

    return () => {
      socket.off('notification')
      socket.off('content:new')
      socket.off('moment:new')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, getNotifications, notifications])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default NotificationProvider
