import useStore from 'store'
import { ModalTypes } from 'store/slices/Modal'

type Modal = {
  [key in ModalTypes]: boolean
}

type TUseModal = {
  modal: Modal
  openModal: (modalType: ModalTypes, openInNext?: ModalTypes[]) => void
  closeModal: (modal: ModalTypes) => void
}

export default function useModal(): TUseModal {
  const modal = useStore(state => state.modal)
  const openModal = useStore(state => state.openModal)
  const closeModal = useStore(state => state.closeModal)

  return {
    modal,
    openModal,
    closeModal,
  }
}
