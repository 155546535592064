export const BASE_URL = ''
export const BEARER_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aWN0b3IxIiwiYXV0aCI6WyJST0xFX0FETSJdLCJib3QiOiIxIiwiaWF0IjoxNjQ4MjU2ODMzLCJleHAiOjE2NDgzNDMyMzN9.QC7awg-cugd_FDg98PWsCpX-u7SO2I1DX5pZ4y3PMD4'
export const EXPIRE_TOKEN = '2022-05-17T18:04:36.595Z'

export const PERSISTENCE_KEYS = {
  ACCESS_TOKEN: '@coterie:token',
  REFRESH_TOKEN: '@coterie:refresh',
  NEW_VIEWED_CONTENTS: '@coterie:newViewedContents',
  USER_LOCATION: '@coterie:user:location',
  TAG_MANAGER: '@coterie:tagManager',
  WHITE_LABEL: '@coterie:whiteLabel',
}
