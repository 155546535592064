import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { Fade, Box, Button, Modal, Typography } from 'components'
import { useAchievements } from 'hooks'
import useQuery from 'hooks/useQuery'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import useStore from 'store'
import { StyledContainer } from './styles'

interface SubscriptionSuccessModalProps {
  open: boolean
  onClose: () => void
}

function SubscriptionSuccessModal({ open, onClose }: SubscriptionSuccessModalProps) {
  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()
  const account = useStore(state => state.account)
  const query = useQuery()
  const location = useLocation()
  const { getBadge } = useAchievements()
  const badge = getBadge({ name: 'Apprentice' })
  const navigate = useNavigate()
  const afterSignUp = query.get('after_signup')

  const handleClose = () => {
    navigate(location.pathname, { state: { subscription: '', after_signup: '' } })
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500, style: { backgroundColor: theme.shadows[4] } } }}
    >
      <Fade in={open}>
        <StyledContainer>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            p={2}
            sx={{
              borderRadius: '10px',
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box />
              <Typography
                color={whiteLabel?.theme ? 'text.primary' : theme.palette.primary.main}
                fontWeight={600}
                fontSize="22px"
                variant="h3"
                textAlign="center"
                marginBottom="8px"
              >
                Congrats, {account?.user.name}!
              </Typography>

              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>

          <Typography textAlign="center">
            Your are now a{' '}
            {whiteLabel?.theme ? account?.user.subscription.product?.name : 'Pro Member'} of our
            community! Enjoy the access to all our programs, videos and tips sheets. Make sure to
            share your progress and check out what other members of the community are saying! 👏
          </Typography>

          {afterSignUp === 'true' && (
            <>
              <Typography textAlign="center" mt="16px">
                {badge?.objective}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  component="img"
                  sx={{ width: '150px', height: '150px', marginTop: '16px' }}
                  src={badge?.mini_active_image}
                  alt={badge?.name}
                />
              </Box>

              <Typography
                textAlign="center"
                fontWeight={600}
                fontSize="22px"
                variant="h3"
                marginBottom="8px"
              >
                Beginner
              </Typography>

              <Typography textAlign="center" fontSize="14px" color="secondary.text">
                Choose your skill level
              </Typography>
            </>
          )}
          <Box
            sx={{
              mt: 2,
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              sx={{
                marginTop: '16px',
                px: '24px',
                [theme.breakpoints.down('md')]: {
                  marginBottom: '8px',
                  width: '100%',
                },

                color: whiteLabel?.theme ? theme.palette.text.button : theme.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? theme.palette.secondary.main
                  : theme.palette.lilac.secondary,
                ':hover': {
                  color: whiteLabel?.theme
                    ? theme.palette.text.buttonHover
                    : theme.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? theme.palette.primary.light
                    : theme.palette.lilac.primary,
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              Share with the Community
            </Button>
            {/* <Button
              sx={{
                marginTop: '16px',
                px: '24px',
                [theme.breakpoints.down('md')]: {
                  marginBottom: '8px',
                  width: '100%',
                },
              }}
              variant="outlined"
              onClick={handleViewOnProfile}
            >
              View on Profile
            </Button> */}
          </Box>
        </StyledContainer>
      </Fade>
    </Modal>
  )
}

export default SubscriptionSuccessModal
