import { ITokenResponse, IAccount } from 'models/auth'
import api from 'services/api'

type tSignUp = {
  name: string
  profession?: string
}

export const signIn = async (email: string, password: string): Promise<ITokenResponse> => {
  const { data } = await api.post('/auth/login', { email, password })
  return data
}

export const signUp = async (
  email: string,
  password: string,
  user: tSignUp,
  project?: string
): Promise<IAccount> => {
  const { data } = await api.post('/account', {
    email,
    password,
    user,
    project,
  })

  return data
}

export const getAccount = async (id: string): Promise<IAccount> => {
  // WIP
  /**
   * App is getting the account by passing the reference auth0 id, instead of the actual account id
   * maybe this will have to change to get the id of the account on the db
   */
  const { data } = await api.get(`/account/ref/${id}`)
  return data
}

export const getResetPassword = async (email: string): Promise<null> => {
  const { data } = await api.post('/auth/change_password', {
    email,
  })
  return data
}

export const editProfile = async (
  id: string,
  name: string,
  email: string,
  about: string | undefined,
  tag: { id: string | undefined }[],
  image: Blob | string | undefined
): Promise<IAccount> => {
  if (image && typeof image !== 'string') {
    const formData = new FormData()

    formData.append('image', image)
    formData.append('name', name)
    formData.append('email', email)
    formData.append('about', about || '')
    formData.append('tags', JSON.stringify(tag) || JSON.stringify(['']))

    const { data } = await api.patch(`/user/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data
  }

  const { data } = await api.patch(`/user/${id}`, { name, email, about, tags: tag, image })
  return data
}
