import { TFile } from 'models/file'
import { TTheme, TWhiteLabel } from 'models/white-label'
import api from 'services/api'

export interface IGetWhiteLabelResponse {
  id: string
  client: string
  domain: string
  theme: string // theme object like
  blockedRoutes: string // like ['/settings/admin', '/programs']
  files: TFile[]
  createdAt: Date
  updatedAt: Date
}

export interface IPostWhiteLabel {
  client: string
  domain: string
  theme: TTheme
  blockedRoutes: string[]
  files: { name: string; file: Blob }[]
}

export interface IPatchWhiteLabel {
  client: string
  domain: string
  theme: TTheme
  blockedRoutes: string[]
  files: { name: string; file?: Blob; isUpdated: boolean }[]
}

export const getWhiteLabelByDomain = async (domain: string): Promise<IGetWhiteLabelResponse> => {
  const { data } = await api.get<IGetWhiteLabelResponse>(`/white-label/domain/${domain}`)

  return data
}

export const getWhiteLabels = async (): Promise<IGetWhiteLabelResponse[]> => {
  const { data } = await api.get('white-label')

  return data
}

export const createWhiteLabel = async ({
  client,
  domain,
  theme,
  blockedRoutes,
  files,
}: IPostWhiteLabel) => {
  const formData = new FormData()

  formData.append('client', client)
  formData.append('domain', domain)
  formData.append('theme', JSON.stringify(theme))
  formData.append('blockedRoutes', JSON.stringify(blockedRoutes))
  formData.append(
    'sign_in_background',
    files.find(file => file.name === 'sign_in_background')?.file || new Blob()
  )
  formData.append(
    'sign_up_background',
    files.find(file => file.name === 'sign_up_background')?.file || new Blob()
  )
  formData.append('logo', files.find(file => file.name === 'logo')?.file || new Blob())

  const { data } = await api.post<TWhiteLabel>('/white-label', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

export const editWhiteLabel = async (
  id: string,
  { client, domain, theme, blockedRoutes, files }: IPatchWhiteLabel
): Promise<IGetWhiteLabelResponse> => {
  const formData = new FormData()

  formData.append('client', client)
  formData.append('domain', domain)
  formData.append('theme', JSON.stringify(theme))
  formData.append('blockedRoutes', JSON.stringify(blockedRoutes))
  files.forEach(file => {
    if (file.file && !file.isUpdated) {
      return
    }

    if (!file.file && !file.isUpdated) {
      formData.append(file.name, file?.file || new Blob())
    }

    formData.append(file.name, file?.file || new Blob())
  })

  const { data } = await api.patch<IGetWhiteLabelResponse>(`/white-label/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}
