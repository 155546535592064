import { GetState, SetState } from 'zustand'
import { EMomentSport } from 'models/moment'
import { deleteChat, viewChatById } from 'services/chat'
import { postUserSeenChatMessages } from 'services/user'
import { TGlobalStore } from 'store'

export type TChatSlice = {
  seenChats: (chatSeen: boolean, newChats?: number) => Promise<void>
  viewChat: (chatId: string) => Promise<void>
  chatSelected: string
  setChatSelected: (chatId: string) => void
  chatPlayerRequest?: { id?: string; sport?: EMomentSport }
  setChatPlayerRequest: (requestId?: string, sport?: EMomentSport) => void
  deleteChat: (chatId: string) => Promise<void>
}

const createChatSlice = (set: SetState<TGlobalStore>, get: GetState<TGlobalStore>): TChatSlice => ({
  chatSelected: '',
  chatPlayerRequest: { id: undefined, sport: undefined },
  setChatSelected: (chatId: string) => {
    set({
      chatSelected: chatId,
    })
  },
  setChatPlayerRequest: (requestId, sport) => {
    set({
      chatPlayerRequest: { id: requestId, sport },
    })
  },
  seenChats: async (chatSeen: boolean) => {
    const { account } = get()

    const { newChatMessages, seenChatMessages } = await postUserSeenChatMessages(chatSeen)

    if (account) {
      set(
        {
          account: {
            ...account,
            user: {
              ...account.user,
              newChatMessages,
              seenChatMessages,
            },
          },
        },
        false
      )
    }
  },

  viewChat: async (chatId: string) => {
    await viewChatById(chatId)
  },

  deleteChat: async (chatId: string) => {
    await deleteChat(chatId)

    set({
      chatSelected: '',
    })
  },
})

export default createChatSlice
