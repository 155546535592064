import { useTheme } from '@mui/material'
import { ChatContainedIcon } from 'assets/icon'
import { Link, Box, Typography } from 'components'
import { TWhiteLabel } from 'models/white-label'

interface ChatBellProps {
  seenChatMessages?: boolean
  newChatMessages?: number
  whiteLabel?: TWhiteLabel
}

export default function ChatBell({ seenChatMessages, newChatMessages, whiteLabel }: ChatBellProps) {
  const theme = useTheme()

  return (
    <Link
      underline="none"
      href="/chat"
      sx={{
        position: 'relative',
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row-reverse',
        borderRadius: '50%',
        transition: '0.2s all',
        ':hover': {
          backgroundColor: whiteLabel?.theme
            ? theme.palette.primary.light
            : theme.palette.secondary.main,
        },

        [theme.breakpoints.down('md')]: {
          marginLeft: 'auto',
        },
      }}
    >
      {!seenChatMessages && !!newChatMessages && newChatMessages > 0 && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1000,
            top: 3,
            right: 2,
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              color: theme.palette.error.contrastText,
              backgroundColor: '#B0140A',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              padding: '2px',
              fontSize: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {newChatMessages}
          </Typography>
        </Box>
      )}
      <ChatContainedIcon sx={{ fontSize: 28, mt: '1px' }} />
    </Link>
  )
}
