export default {
  admin: {
    admin: 'Admin',
    content_list: 'Content List',
    content_edit: 'Content Edit',
    content_delete: 'Content Delete',
    content_create: 'Content Create',
    deleteConfirmation: 'Are you sure?',
    deleteText:
      'If you delete a Content, other users will no longer be able to visualize it. This action cannot be reversed.',
    content_delete_success: 'Content has been deleted',
    content_create_success: 'Content has been created',
    content_update_success: 'Content has been updated',
    table_cell_title: 'Title',
    table_cell_description: 'Description',
    table_cell_created_by: 'Created By',
    edit: 'Edit',
    cancel: 'Cancel',
    contents: {
      button_create: 'Create Contents',
      button_contents: 'Contents',
    },
    programs: {
      button: 'Programs',
      list_title: 'Programs List',
      create_button: 'Create a new Program',
      edit_program: 'Edit Program',
      select_contents: 'Select the contents of this Program',
      tier_label: 'Will this Program be free?',
      delete_success: 'Program has been deleted',
      create_success: 'Program has been created',
      update_success: 'Program has been updated',
    },
    white_label: {
      create_title: 'White Label Create',
      menu_create_title: 'Create White Label',
      list_title: 'White Labels',
      edit_title: 'Edit White Label',
      edit_button: 'Update',
      theme_title: 'Theme',
      palette: {
        title: 'Palette',
        primary_title: 'Primary',
        secondary_title: 'Secondary',
        text_title: 'Text',
        purple_title: 'Purple',
        lilac_title: 'Lilac',
        green_title: 'Green',
        blue_title: 'Blue',
        background_title: 'Background',
      },
    },
    table: {
      title: 'Title',
      description: 'Description',
      created_by: 'Created By',
      edit: 'Edit',
      tier: 'Tier',
      content: 'Contents',
      thumbnail: 'Thumbnail',
      tags: 'Tags',
      view: 'View',
      close: 'Close',
    },
  },
}
