import { TMoment, TComment, EMomentType, TPaginationMoment } from 'models/moment'
import api from 'services/api'

interface iPostMoment {
  title: string
  description: string
  link: string
  file?: Blob
  sport: string
}

interface IPostMomentReaction {
  momentId: string
  reaction: string
}

interface IDeleteMomentReaction {
  momentId: string
  reactionId: string
}

interface IDeleteMoment {
  momentId: string
}

interface IComment {
  momentId: string
  comment: string
}

interface IDeleteComment {
  momentId: string
  commentId: string
}

export const getMoments = async (
  take?: number,
  skip?: number
): Promise<TMoment[] | TPaginationMoment> => {
  const { data } = await api.get<TMoment[] | TPaginationMoment>(`/moment?take=${take}&skip=${skip}`)

  return data
}

export const getMomentById = async (momentId: string) => {
  const { data } = await api.get<TMoment>(`/moment/${momentId}`)

  return data
}

export const getComments = async (momentId: string): Promise<TComment[]> => {
  const { data } = await api.get<TComment[]>(`/moment/${momentId}/comments`)

  return data
}

export const postComment = async ({ momentId, comment }: IComment) => {
  const { data } = await api.post(`/moment/${momentId}/comment`, {
    comment,
  })

  return data
}

export const postMoment = async ({ title, description, link, file, sport }: iPostMoment) => {
  if (file?.size !== 0) {
    const formData = new FormData()

    if (file) {
      formData.append('file', file)
    }
    formData.append('title', title)
    formData.append('description', description)
    formData.append('link', link)
    formData.append('sport', sport)

    const { data } = await api.post('/moment', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data
  }

  const { data } = await api.post('/moment', { title, description, link, sport })

  return data
}

export const postMomentWithFileId = async ({
  title,
  description,
  link,
  sport,
  fileId,
  type,
}: iPostMoment & { fileId: string; type: EMomentType }) => {
  const { data } = await api.post(`moment/file/${fileId}`, {
    title,
    description,
    link,
    sport,
    type,
  })

  return data
}

export const postMomentReaction = async ({ momentId, reaction }: IPostMomentReaction) => {
  const { data } = await api.post(`/moment/${momentId}/reaction`, { reaction })
  return data
}

export const deleteMomentReaction = async ({ momentId, reactionId }: IDeleteMomentReaction) => {
  const { data } = await api.delete(`/moment/${momentId}/reaction/${reactionId}`)
  return data
}

export const deleteMoment = async ({ momentId }: IDeleteMoment) => {
  const { data } = await api.delete(`/moment/${momentId}`)
  return data
}

export const deleteComment = async ({ momentId, commentId }: IDeleteComment) => {
  const { data } = await api.delete(`/moment/${momentId}/comment/${commentId}`)

  return data
}
