import admin from './Admin'
import common from './common'
import components from './components'
import content from './Content'
import FAQ from './FAQ'
import featuredContent from './featuredContent'
import footer from './Footer'
import forgot from './Forgot'
import home from './Home'
import moments from './moments'
import personalize from './Personalize'
import player_connect from './PlayerConnect'
import settings from './Settings'
import sign_in from './SignIn'
import sign_up from './SignUp'
import subscription from './Subscription'
import waiting_list from './WaitingList'
import welcome_flow from './Welcome'

export default Object.assign(
  common,
  home,
  sign_in,
  components,
  sign_up,
  personalize,
  content,
  moments,
  featuredContent,
  admin,
  settings,
  forgot,
  waiting_list,
  subscription,
  footer,
  welcome_flow,
  player_connect,
  FAQ
)
