export default {
  edit_profile: {
    title: 'Edit Profile',
    name: 'Name',
    email: 'Email',
    about: 'About',
    skill_level: 'What is your golf skill level?',
    save_changes: 'Save changes',
    edit_profile: 'Edit profile',
    success: 'Profile has been edited',
    error: 'Check profile information and try again, please',
  },
  account: {
    title: 'Account',
    password: 'Password',
    current_password: 'Current password',
    forgot_password: 'Forgot password?',
    new_password: 'New password',
    change_password: 'Change password',
    password_reset_success: 'Password changed',
    password_reset_error: 'Check current password and type again, please',
  },
  invite_friends: {
    title: 'Invite Friends',
    invite: 'Invite your friends via email',
    success: 'Thanks for contributing to the community!',
    next_achievements: 'Next achievements',
    achievements_description:
      'Did you know? You can earn badges depending on how many people you invited, and how many of them joined us.',
    email_address: 'Enter an email address',
    email_error: 'Please, enter a valid email address',
    invite_description:
      'Invite some of your friends to join community. We’ll love to welcome more awesome women just like you!',
    send_invite: 'Send invite',
  },
  notifications: {
    title: 'Notifications',
    new_post: 'shared a post you might be interested in',
    new_comment: 'commented on your post',
    new_admin: 'has sent a new notification',
    new_match: 'has matched with you!',
    content: 'Notification content',
    user_selection: 'Which users should receive it?',
    all_users: 'All users',
    add_image: 'Add an image',
    preferences: {
      title: 'Notification Preferences',
      email: 'Email Notification',
      new_comment: 'New Comment',
      new_post: 'New Post',
      comment_tooltip: 'Turn off email notifications for new comments',
      post_tooltip: 'Turn off email notifications for new posts',
    },
    deleteText:
      'If you delete a notification, other users will no longer be able to visualize it. This action cannot be reversed.',
    delete: 'Delete Notification',
    delete_success: 'Notification deleted!',
  },
}
