import { useTheme } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { Link as LinkRouter, useLocation } from 'react-router-dom'
import { IconEmail, IconInstagram, IconLinkedin, IconFacebook } from 'assets/icon'
import { Logo } from 'assets/images'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Link from 'components/Link'
import Typography from 'components/Typography'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { ESegmentTrack, segmentService } from 'services/segment'
import palette from 'theme/palette'

function Footer() {
  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Container
      component="footer"
      sx={{
        height: '228px',
        padding: 4,
        marginTop: 5,
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" sx={{ width: '232px' }}>
          <Link component={LinkRouter} underline="none" to="/home" pb={2}>
            {whiteLabel?.theme && location.pathname !== '/' ? (
              <img
                src={
                  whiteLabel?.files?.find(file =>
                    file.fileName.includes(`${whiteLabel.domain}-white-label/logo`)
                  )?.fileUrl
                }
                alt={`${whiteLabel.client}-logo`}
                width="150px"
              />
            ) : (
              <Logo height="32px" />
            )}
          </Link>
          <Typography
            fontWeight={500}
            fontSize="12px"
            variant="body2"
            color={whiteLabel?.theme ? theme.palette.blue.primary : 'purple.primary'}
            pb={2}
          >
            {t('footer.main')}
          </Typography>
          <Button
            component={Link}
            href="/settings/invite-friends"
            onClick={() => {
              segmentService.track(ESegmentTrack.Button_Clicked, { button: 'Invite Friend footer' })
            }}
            variant="contained"
            color="secondary"
            sx={{ bgcolor: whiteLabel?.theme ? whiteLabel?.theme.palette.blue.primary : '' }}
          >
            <Typography
              textAlign="center"
              fontWeight={500}
              fontSize="12px"
              variant="body2"
              lineHeight="20px"
              color={
                whiteLabel?.theme
                  ? whiteLabel?.theme.palette.primary.contrastText
                  : 'purple.primary'
              }
              p={0.5}
            >
              {t('footer.invite')}
            </Typography>
          </Button>
          <Typography fontWeight={500} fontSize="10px" variant="body2" color="neutral.dark" pt={4}>
            {t('footer.copyright')}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            width: '232px',
          }}
        >
          <Typography
            fontWeight={600}
            fontSize="18px"
            variant="body2"
            color={
              whiteLabel?.theme ? whiteLabel?.theme.palette.blue.primary : palette.purple.secondary
            }
            pb={2}
            sx={{ fontFamily: 'Crimson Text' }}
          >
            {t('footer.social_media.title')}
          </Typography>
          <List sx={{ paddingTop: 1 }}>
            <ListItem sx={{ padding: 0, paddingBottom: 1 }}>
              <ListItemText
                primary={
                  <Link
                    component={Link}
                    href="mailto:info@activecoterie.com"
                    sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                  >
                    <IconEmail
                      fill={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.lilac.secondary
                      }
                      width="14px"
                      height="14px"
                    />
                    <Typography
                      fontWeight={500}
                      fontSize="12px"
                      variant="body2"
                      color={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.purple.primary
                      }
                      sx={{ marginLeft: 1 }}
                    >
                      {t('footer.social_media.email')}
                    </Typography>
                  </Link>
                }
              />
            </ListItem>
            <ListItem sx={{ padding: 0, paddingBottom: 1 }}>
              <ListItemText
                primary={
                  <Link
                    component={Link}
                    href="https://www.facebook.com/coteriecommunity"
                    sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                  >
                    <IconFacebook
                      fill={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.lilac.secondary
                      }
                      width="14px"
                      height="14px"
                    />
                    <Typography
                      fontWeight={500}
                      fontSize="12px"
                      variant="body2"
                      color={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.purple.primary
                      }
                      sx={{ marginLeft: 1 }}
                    >
                      {t('footer.social_media.facebook')}
                    </Typography>
                  </Link>
                }
              />
            </ListItem>
            <ListItem sx={{ padding: 0, paddingBottom: 1 }}>
              <ListItemText
                primary={
                  <Link
                    component={Link}
                    href="https://www.instagram.com/coteriecommunity/"
                    sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                  >
                    <IconInstagram
                      fill={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.lilac.secondary
                      }
                      width="14px"
                      height="14px"
                    />
                    <Typography
                      fontWeight={500}
                      fontSize="12px"
                      variant="body2"
                      color={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.purple.primary
                      }
                      sx={{ marginLeft: 1 }}
                    >
                      {t('footer.social_media.instagram')}
                    </Typography>
                  </Link>
                }
              />
            </ListItem>
            <ListItem sx={{ padding: 0, paddingBottom: 1 }}>
              <ListItemText
                primary={
                  <Link
                    component={Link}
                    href="https://www.linkedin.com/company/coteriecommunity/"
                    sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                  >
                    <IconLinkedin
                      fill={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.lilac.secondary
                      }
                      width="14px"
                      height="14px"
                    />
                    <Typography
                      fontWeight={500}
                      fontSize="12px"
                      variant="body2"
                      color={
                        location.pathname !== '/' && whiteLabel?.theme
                          ? theme.palette.lilac.secondary
                          : palette.purple.primary
                      }
                      sx={{ marginLeft: 1 }}
                    >
                      {t('footer.social_media.linkedin')}
                    </Typography>
                  </Link>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Container>
  )
}

export default Footer
