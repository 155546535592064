import { useState, useEffect } from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import SnackbarContent from '@mui/material/SnackbarContent/SnackbarContent'
import { useTranslation } from 'react-i18next'
import { Snackbar, Button, Typography } from 'components'
import { IAccount } from 'models/auth'

interface NotificatioNSnackbarProps {
  account: IAccount
}

export default function NotificationSnackbar({ account }: NotificatioNSnackbarProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const media = useMediaQuery(theme.breakpoints.down('md'))

  const redirectToChat = (
    <Button size="small" href="/chat" variant="text">
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {t('player_connect.view_chat')}
      </Typography>
    </Button>
  )

  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(true)
  }, [account.user.newChatMessages])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {account.user.newChatMessages > 0 && (
        <Snackbar
          anchorOrigin={{ horizontal: media ? 'center' : 'right', vertical: 'bottom' }}
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={6000}
          sx={{ marginBottom: media ? theme.spacing(8) : '' }}
        >
          <SnackbarContent
            message={
              account.user.newChatMessages > 1
                ? `You have ${account.user.newChatMessages} new messages`
                : `You have ${account.user.newChatMessages} new message`
            }
            action={redirectToChat}
            sx={{
              border: `1px solid ${theme.palette.lilac.secondary}`,
              borderRadius: '4px',
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          />
        </Snackbar>
      )}
    </>
  )
}
