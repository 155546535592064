import { ESubscriptionType, ISubscription } from 'models/subscription'
import api from 'services/api'

interface IPostCheckout {
  priceId: string
  customerId: string
  afterSignUp?: boolean
  whiteLabelDomain?: string
}

interface IDeleteSubscription {
  id: string
  subscriptionId: string
  whiteLabelDomain?: string
}

interface IPatchSubscription {
  subscriptionId: string
  priceId: string
}

interface IGetSubscriptionProducts {
  id: string
  name: string
  description?: string
  price: number
  type: ESubscriptionType
  priceId: string
  productId: string
  stripeConfigId: string
}

export const goToCheckout = async ({
  priceId,
  customerId,
  afterSignUp,
  whiteLabelDomain,
}: IPostCheckout) => {
  const { data } = await api.post(`/subscription/checkout/${customerId}`, {
    priceId,
    afterSignUp,
    whiteLabelDomain,
  })

  return data
}

export const upgradeSubscription = async ({
  subscriptionId,
  priceId,
}: IPatchSubscription): Promise<string> => {
  const { data } = await api.patch('subscription', { subscriptionId, priceId })

  return data
}

export const cancelSubscription = async ({
  id,
  subscriptionId,
  whiteLabelDomain,
}: IDeleteSubscription): Promise<ISubscription> => {
  const { data } = await api.delete(
    `/subscription/${id}/${subscriptionId}?whiteLabelDomain=${whiteLabelDomain}`
  )

  return data
}

export const getSubscriptionProductsByDomain = async (
  whiteLabelDomain: string
): Promise<IGetSubscriptionProducts[]> => {
  const { data } = await api.get(`/stripe/product/white-label/${whiteLabelDomain}`)

  return data
}
