import { SvgIcon, SvgIconProps } from '@mui/material'

type IconAddButtonProps = SvgIconProps & {
  backgroundColor?: string
}

export function IconAddButton({ backgroundColor = '#CEBFED', ...props }: IconAddButtonProps) {
  return (
    <SvgIcon
      {...props}
      width="66"
      height="67"
      viewBox="0 0 66 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        fontSize: 67,
      }}
    >
      <svg>
        <g filter="url(#filter0_ddd_10300_31193)">
          <rect x="13" y="13" width="40" height="40" rx="20" fill={backgroundColor} />
          <path
            d="M38 33.7143H33.7143V38H32.2857V33.7143H28V32.2857H32.2857V28H33.7143V32.2857H38V33.7143Z"
            fill="#344568"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddd_10300_31193"
            x="0.142859"
            y="0.857144"
            width="65.7143"
            height="65.7143"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.714286" />
            <feGaussianBlur stdDeviation="6.42857" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10300_31193"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4.28571" />
            <feGaussianBlur stdDeviation="3.57143" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_10300_31193"
              result="effect2_dropShadow_10300_31193"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="0.714286"
              operator="erode"
              in="SourceAlpha"
              result="effect3_dropShadow_10300_31193"
            />
            <feOffset dy="2.14286" />
            <feGaussianBlur stdDeviation="1.78571" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_10300_31193"
              result="effect3_dropShadow_10300_31193"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect3_dropShadow_10300_31193"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  )
}
