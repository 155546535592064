import { useState } from 'react'
import { FileUploadOutlined } from '@mui/icons-material'
import { useTheme, useMediaQuery } from '@mui/material'
import { EditorState } from 'draft-js'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import Resizer from 'react-image-file-resizer'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { IconLink, IconBack } from 'assets/icon'
import { PUBLIC_URL } from 'common/env'
import { Uploader } from 'common/utils/uploader'
import {
  Select,
  MenuItem,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  LoadingButton,
  TextField,
  Typography,
  OutlinedInput,
} from 'components'
import LinearProgressWithLabel from 'components/LinearProgress'
import TextEditor from 'components/TextEditor'
import { useAchievements, useSnackbar } from 'hooks'
import { useSocket } from 'hooks/useSocket'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { EMomentType, TMoment } from 'models/moment'
import { NotificationType } from 'models/notification'
import * as MomentService from 'services/moment'
import { ESegmentTrack, segmentService } from 'services/segment'
import useStore from 'store'
import PreviewShareMoment from '../PreviewShareMoment'
import 'draft-js/dist/Draft.css'

interface iPostMoment {
  title: string
  description: string
  link: string
  file: File
  sport: string
}

interface IFormShareMomentsProps {
  setOpen: (open: boolean) => void
}

interface IPrepareFileProps {
  file: Blob
}

const ShareMomentForm = ({ setOpen }: IFormShareMomentsProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { success } = useSnackbar()
  const { verifyAchievements } = useAchievements()
  const tags = useStore(store => store.tags)
  const account = useStore(store => store.account)
  const { socket } = useSocket()
  const { whiteLabel } = useWhiteLabel()

  const queryClient = useQueryClient()

  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const [isLoading, setIsLoading] = useState(false)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  const [upload, setUpload] = useState<Uploader>()
  const [progress, setProgress] = useState<number>()

  const handleEditorState = (state: EditorState) => {
    setEditorState(state)
  }

  const MomentsSchema = Yup.object().shape({
    title: Yup.string().required('This field is required'),
    link: Yup.string(),
    description: Yup.string()
      .when('link', {
        is: (link: string) => !link,
        then: Yup.string().required('This field is required'),
      })
      .when('file', {
        is: (file: string) => !file,
        then: Yup.string().required('This field is required'),
      }),
    sport: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      link: '',
      file: new File([], ''),
      sport: tags[0].tag.sport,
    },
    validationSchema: MomentsSchema,
    onSubmit: async ({ title, description, link, file, sport }: iPostMoment) => {
      try {
        let momentPostResponse: TMoment = {} as TMoment
        setIsLoading(true)

        const isImage = /(^image)(\/)[a-zA-Z0-9_]*/gm.test(file.type)

        const entityId = uuidv4()

        const idFile = uuidv4()

        if (file.size > 0 && !isImage) {
          const videoUploaderOptions = {
            fileName: `${account?.user.id}/${entityId}/${idFile}/${file.name}`,
            file,
          }

          let percentage: number

          const uploader = new Uploader(videoUploaderOptions)
          setUpload(uploader)

          const uploadVideo = new Promise<{ fileId: string }>((res, rej) => {
            uploader
              .onProgress(({ percentage: newPercentage }: { percentage: number }) => {
                // to avoid the same percentage to be logged twice
                if (newPercentage !== percentage) {
                  percentage = newPercentage
                  setProgress(percentage)
                }
              })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .onError((error: any) => {
                formik.setFieldValue('file', new Blob())
                rej(error)
              })
              .onSuccess(async ({ id: fileId }) => {
                res({ fileId })
              })

            uploader.start()
          })

          const uploadRes = await uploadVideo

          momentPostResponse = await MomentService.postMomentWithFileId({
            title,
            description,
            link,
            fileId: uploadRes.fileId,
            sport: sport.toUpperCase(),
            type: EMomentType.VIDEO,
          })
        } else {
          momentPostResponse = await MomentService.postMoment({
            title,
            description,
            link,
            file: file.size > 0 ? file : undefined,
            sport: sport.toUpperCase(),
          })
        }

        socket.emit('notification', {
          userId: momentPostResponse.createdById,
          role: account?.role,
          message:
            momentPostResponse.description.length > 7
              ? momentPostResponse.description
              : momentPostResponse.title,
          redirectLink: whiteLabel?.domain
            ? `${window.location.protocol}//${window.location.host}/moment-detail/${momentPostResponse.id}`
            : `${PUBLIC_URL}/moment-detail/${momentPostResponse.id}`,
          momentId: momentPostResponse.id,
          forUsersSport: [momentPostResponse.sport.toLowerCase()],
          type: NotificationType.POST,
          whiteLabelDomain: whiteLabel?.domain,
        })

        segmentService.track(ESegmentTrack.Post_Created, {
          user_id: momentPostResponse.createdById,
          post_id: momentPostResponse.id,
        })

        success({ message: t('moments.share_success') })
        await queryClient.invalidateQueries('getMoments')
        await verifyAchievements()
      } finally {
        setOpen(false)
        setIsLoading(false)
        setEditorState(() => EditorState.createEmpty())
        segmentService.track(ESegmentTrack.Button_Clicked, { button: 'Submit share moment' })
      }
    },
  })

  const resizeImageFile = (file: Blob) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'blob'
      )
    })

  const handlePrepareFile = async ({ file }: IPrepareFileProps) => {
    const isImage = /(^image)(\/)[a-zA-Z0-9_]*/gm.test(file.type)

    if (isImage) {
      const image = await resizeImageFile(file)
      return formik.setFieldValue('file', image)
    }

    return formik.setFieldValue('file', file)
  }

  const handleClearFile = async () => {
    if (upload) {
      upload.abort()
    }
    await formik.setFieldValue('file', new Blob())
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={2}
      sx={{
        backgroundColor: '#FAFAFA',
        borderRadius: '10px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
          },
        }}
      >
        {isDownMd && <IconBack onClick={() => setOpen(false)} />}
        <Typography
          color={theme.palette.text.primary}
          fontWeight={600}
          fontSize="22px"
          variant="h3"
          textAlign="center"
          mb={2}
        >
          {t('moments.share')}
        </Typography>
        <Box />
      </Box>
      <PreviewShareMoment moment={formik.values} clearFile={handleClearFile} />
      <Box component="form" onSubmit={formik.handleSubmit} noValidate mb={0}>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.dark,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.secondary.dark,
                borderWidth: '2px',
              },
            },
          }}
          margin="normal"
          fullWidth
          id="title"
          label={t('moments.add_title')}
          name="title"
          autoComplete="title"
          autoFocus
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <Box
          sx={{
            marginTop: theme.spacing(2),
          }}
        >
          <TextEditor
            editorState={editorState}
            onEditorStateChange={handleEditorState}
            label={t('moments.description')}
            formik={formik}
            field="description"
          />
        </Box>

        <FormControl margin="normal" required fullWidth variant="outlined">
          <InputLabel error={formik.touched.link && Boolean(formik.errors.link)} htmlFor="link">
            {t('moments.link')}
          </InputLabel>
          <OutlinedInput
            id="link"
            type="text"
            sx={{
              '&.MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.secondary.dark,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.secondary.dark,
                  borderWidth: '2px',
                },
              },
            }}
            value={formik.values.link}
            onChange={formik.handleChange('link')}
            error={formik.touched.link && Boolean(formik.errors.link)}
            endAdornment={
              <InputAdornment position="end">
                <IconLink />
              </InputAdornment>
            }
            label={t('moments.link')}
          />
          {formik.touched.link && Boolean(formik.errors.link) && (
            <FormHelperText error id="link">
              {formik.touched.link && formik.errors.link}
            </FormHelperText>
          )}
        </FormControl>

        {tags.length > 1 && (
          <FormControl sx={{ marginTop: 2 }} fullWidth>
            <InputLabel
              id="sport"
              htmlFor="sport"
              error={formik.touched.sport && Boolean(formik.errors.sport)}
            >
              {t('moments.select_sport')}
            </InputLabel>

            <Select
              name="sport"
              labelId="sport"
              id="sport"
              value={formik.values.sport}
              label={t('moments.select_sport')}
              onChange={formik.handleChange}
              required
            >
              {tags.map(item => (
                <MenuItem
                  style={{ textTransform: 'capitalize' }}
                  key={`${item.tag.id}-option`}
                  value={item.tag.sport}
                >
                  <Typography sx={{ textTransform: 'capitalize' }}>{item.tag.sport}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {(formik.values.file.size === 0 || (formik.values.file && Boolean(formik.errors.file))) && (
          <Button
            component="label"
            startIcon={<FileUploadOutlined />}
            variant="outlined"
            sx={{
              mt: 2,
              mb: 2,
              px: 2,
              py: 1,
            }}
          >
            {t('moments.upload')}
            <input
              id="file"
              name="file"
              type="file"
              accept=".gif,.jpg,.jpeg,.png, .mov, .mp4"
              onChange={({ target }) => {
                handlePrepareFile({ file: target.files ? target.files[0] : new Blob() })
              }}
              hidden
            />
          </Button>
        )}
        {Boolean(formik.errors.file) && (
          <Typography sx={{ color: theme.palette.error.main, mb: 2 }}>
            {formik.errors.file as string}
          </Typography>
        )}

        {!!progress && progress > 0 && (
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            mt: formik.values.file.size > 0 ? 2 : 0,
          }}
        >
          <LoadingButton
            id="submit-moment-button"
            loading={isLoading}
            type="submit"
            variant="contained"
            sx={{
              mb: {
                xs: 2,
                sm: 0,
              },
              marginRight: {
                xs: 0,
                sm: 2,
              },
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },

              color: whiteLabel?.theme ? theme.palette.text.button : theme.palette.primary.main,
              backgroundColor: whiteLabel?.theme
                ? theme.palette.secondary.main
                : theme.palette.lilac.secondary,
              ':hover': {
                color: whiteLabel?.theme
                  ? theme.palette.text.buttonHover
                  : theme.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? theme.palette.primary.light
                  : theme.palette.lilac.primary,
              },
            }}
          >
            {t('moments.shareMoment')}
          </LoadingButton>
          <Button
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
            variant="outlined"
            onClick={() => [
              setOpen(false),
              segmentService.track(ESegmentTrack.Button_Clicked, { button: 'Cancel share moment' }),
            ]}
          >
            {t('moments.cancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ShareMomentForm
