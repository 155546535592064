export default {
  footer: {
    main: 'We are changing the game, literally. Tell your friends about it!',
    invite: 'Invite Friends to Coterie',
    copyright: 'Coterie © 2022. All rights reserved.',
    social_media: {
      title: 'Get in touch',
      email: 'info@activecoterie.com',
      facebook: '@coteriecommunity',
      instagram: '@coteriecommunity',
      linkedin: '/coteriecommunity',
    },
  },
}
