import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { GetState, SetState } from 'zustand'
import { PERSISTENCE_KEYS } from 'common/constants'
import { ITokenResponse, IAccount, IUser } from 'models/auth'
import { TTag } from 'models/tag'
import { ETagManagerType } from 'models/tag-manager'
import api from 'services/api'
import { signUp, signIn, getAccount as fetchAccount } from 'services/auth'
import { ESegmentTrack, segmentService } from 'services/segment'
import { createTagManagerHistory } from 'services/tagManager'
import { AddTag } from 'services/tags'
import { TGlobalStore } from 'store'

type tSignIn = {
  email: string
  password: string
}

type tSignInSocial = {
  code: string
  project?: string
}

type tSignUpUser = {
  name: string
}

type tSignUp = {
  email: string
  password: string
  user: tSignUpUser
  project?: string
}
export interface ISignUpResponse {
  id: string
  email: string
  role: string
}

type tTag = {
  id: string
}

type tAddTags = {
  account: IAccount
  tags: tTag[]
}

type tToken = {
  sub: string
  permissions: string[]
}

export type TAuthSlice = {
  getAccount: () => Promise<IAccount | undefined>
  getAccountLevel: () => boolean
  signIn: (data: tSignIn, afterSignUp?: boolean) => Promise<ITokenResponse | undefined>
  signInSocial: (data: tSignInSocial) => Promise<ITokenResponse | undefined>
  signUp: (data: tSignUp) => Promise<ISignUpResponse | undefined>
  logout: (whiteLabelDomain?: string) => void
  isAuthenticated: () => boolean
  isAdmin: () => boolean
  account: IAccount | null
  accountLevel: boolean
  tags: TTag[]
  addTags: ({ account, tags }: tAddTags) => Promise<IUser>
}

const createAuthSlice = (set: SetState<TGlobalStore>, get: GetState<TGlobalStore>): TAuthSlice => ({
  account: null,
  tags: [],
  accountLevel: false,
  getAccount: async () => {
    const token = localStorage.getItem(PERSISTENCE_KEYS.ACCESS_TOKEN)
    if (token) {
      const decode: { sub: string } = jwt_decode(token)

      const newAccount = await fetchAccount(decode.sub)

      set(
        { account: newAccount, tags: newAccount.user.tags },
        false,
        // @ts-ignore
        'getAccount'
      )

      return newAccount
    }
  },
  getAccountLevel: () => {
    const { account } = get()

    let hasLevel = true

    if (account && account.user.tags.length === 0) {
      hasLevel = false
    }

    set(
      { accountLevel: hasLevel },
      false,
      // @ts-ignore
      'hasLevel'
    )

    return hasLevel
  },
  signIn: async ({ email, password }: tSignIn, afterSignUp?: boolean) => {
    try {
      const auth = await signIn(email, password)

      const { access_token, refresh_token } = auth

      localStorage.setItem(PERSISTENCE_KEYS.ACCESS_TOKEN, access_token)
      localStorage.setItem(PERSISTENCE_KEYS.REFRESH_TOKEN, refresh_token)

      if (access_token) {
        const decode: { sub: string } = jwt_decode(access_token)

        const newAccount = await fetchAccount(decode.sub)

        const { state_short_name: state, city } = await segmentService.track(
          ESegmentTrack.User_Login,
          {
            email: newAccount.email,
          }
        )

        const tagManager = localStorage.getItem(PERSISTENCE_KEYS.TAG_MANAGER)

        if (tagManager && !afterSignUp) {
          const tagManagerObj = JSON.parse(tagManager)

          await createTagManagerHistory(newAccount.user.id, {
            ...tagManagerObj,
            type: ETagManagerType.LOGIN,
          })

          // save tag manager in database
          segmentService.track(ESegmentTrack.Tag_Manager, {
            userId: newAccount.user.id,
            type: ETagManagerType.LOGIN,
            ...tagManagerObj,
          })

          localStorage.removeItem(PERSISTENCE_KEYS.TAG_MANAGER)
        }

        localStorage.setItem(PERSISTENCE_KEYS.USER_LOCATION, JSON.stringify({ state, city }))

        let identifyData = {}

        if (afterSignUp) {
          identifyData = {
            name: newAccount.user.name,
            email: newAccount.email,
            type: newAccount.user.subscription.type,
          }

          if (tagManager) {
            const tagManagerObj = JSON.parse(tagManager)

            await createTagManagerHistory(newAccount.user.id, {
              ...tagManagerObj,
              type: ETagManagerType.SIGN_UP,
            })

            segmentService.track(ESegmentTrack.Tag_Manager, {
              userId: newAccount.user.id,
              type: ETagManagerType.SIGN_UP,
              ...tagManagerObj,
            })

            localStorage.removeItem(PERSISTENCE_KEYS.TAG_MANAGER)
          }
        } else {
          identifyData = {
            name: newAccount.user.name,
            email: newAccount.email,
            type: newAccount.user.subscription.type,
            sportSelected:
              newAccount.user.tags[0].tag.sport && newAccount.user.tags[0].tag.level
                ? newAccount.user.tags.map(tagSelected => ({
                    sport: tagSelected.tag.sport,
                    level: tagSelected.tag.level,
                  }))
                : '',
          }
        }

        segmentService.identify(newAccount.user.id, identifyData)

        set(
          {
            account: { ...newAccount, user: { ...newAccount.user, state, city } },
            tags: newAccount.user.tags,
          },
          false,
          // @ts-ignore
          'getAccount'
        )
      }

      return auth
    } catch (error) {
      if (axios.isAxiosError(error)) throw error
    }
  },
  signInSocial: async ({ code, project }: tSignInSocial): Promise<ITokenResponse | undefined> => {
    try {
      const { data } = await api.post('/auth/social', {
        code,
        project,
      })

      const { access_token, refresh_token, hasSignUp } = data

      localStorage.setItem(PERSISTENCE_KEYS.ACCESS_TOKEN, access_token)
      localStorage.setItem(PERSISTENCE_KEYS.REFRESH_TOKEN, refresh_token)

      if (access_token) {
        const decode: { sub: string } = jwt_decode(access_token)

        const newAccount = await fetchAccount(decode.sub)

        let identifyData = {}

        const tagManager = localStorage.getItem(PERSISTENCE_KEYS.TAG_MANAGER)

        if (tagManager && !hasSignUp) {
          const tagManagerObj = JSON.parse(tagManager)

          await createTagManagerHistory(newAccount.user.id, {
            ...tagManagerObj,
            type: ETagManagerType.LOGIN,
          })

          segmentService.track(ESegmentTrack.Tag_Manager, {
            userId: newAccount.user.id,
            type: ETagManagerType.LOGIN,
            ...tagManagerObj,
          })

          localStorage.removeItem(PERSISTENCE_KEYS.TAG_MANAGER)
        }

        if (hasSignUp) {
          identifyData = {
            name: newAccount.user.name,
            email: newAccount.email,
            type: newAccount.user.subscription.type,
          }

          await segmentService.track(ESegmentTrack.Sign_Up_Completed, {
            name: newAccount.user.name,
            email: newAccount.email,
          })

          if (tagManager) {
            const tagManagerObj = JSON.parse(tagManager)

            await createTagManagerHistory(newAccount.user.id, {
              ...tagManagerObj,
              type: ETagManagerType.SIGN_UP,
            })

            segmentService.track(ESegmentTrack.Tag_Manager, {
              userId: newAccount.user.id,
              type: ETagManagerType.SIGN_UP,
              ...tagManagerObj,
            })

            localStorage.removeItem(PERSISTENCE_KEYS.TAG_MANAGER)
          }
        } else {
          identifyData = {
            name: newAccount.user.name,
            email: newAccount.email,
            type: newAccount.user.subscription.type,
            sportSelected:
              newAccount.user.tags[0].tag.sport && newAccount.user.tags[0].tag.level
                ? newAccount.user.tags.map(tagSelected => ({
                    sport: tagSelected.tag.sport,
                    level: tagSelected.tag.level,
                  }))
                : '',
          }
        }

        const { city, state_short_name: state } = await segmentService.track(
          ESegmentTrack.User_Login,
          {
            email: newAccount.email,
          }
        )

        localStorage.setItem(PERSISTENCE_KEYS.USER_LOCATION, JSON.stringify({ state, city }))

        await segmentService.identify(newAccount.user.id, identifyData)

        set(
          { account: newAccount, tags: newAccount.user.tags },
          false,
          // @ts-ignore
          'getAccount'
        )
      }

      return data
    } catch (error) {
      if (axios.isAxiosError(error)) throw error
    }
  },
  signUp: async ({ email, password, user, project }: tSignUp) => {
    try {
      const account = await signUp(email, password, user, project)

      segmentService.track(ESegmentTrack.Sign_Up_Completed, {
        name: account.user.name,
        email,
      })

      return account
    } catch (error) {
      if (axios.isAxiosError(error)) throw error
    }
  },
  logout: (whiteLabelDomain?: string) => {
    localStorage.removeItem(PERSISTENCE_KEYS.ACCESS_TOKEN)
    localStorage.removeItem(PERSISTENCE_KEYS.REFRESH_TOKEN)
    localStorage.removeItem(PERSISTENCE_KEYS.NEW_VIEWED_CONTENTS)
    localStorage.removeItem(PERSISTENCE_KEYS.USER_LOCATION)
    localStorage.removeItem(PERSISTENCE_KEYS.TAG_MANAGER)
    localStorage.removeItem(`${PERSISTENCE_KEYS.WHITE_LABEL}:${whiteLabelDomain}`)

    set(
      { account: null, tags: null },
      false,
      // @ts-ignore
      'logout'
    )
  },
  isAuthenticated: () => {
    const authToken = localStorage.getItem(PERSISTENCE_KEYS.ACCESS_TOKEN)
    return Boolean(authToken)
  },
  isAdmin: () => {
    const token = localStorage.getItem(PERSISTENCE_KEYS.ACCESS_TOKEN)

    if (token) {
      const { permissions }: tToken = jwt_decode(token)
      return permissions.some(item => item === 'coterie:admin')
    }

    return false
  },

  addTags: async ({ account, tags }: tAddTags) => {
    const newAccount = await AddTag({ id: account.user.id, tags })

    const { getAccount: getAccountStore } = get()

    await getAccountStore()

    return newAccount
  },
})

export default createAuthSlice
