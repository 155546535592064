/* eslint-disable quotes */
export default {
  welcome_flow: {
    step_one: {
      title: 'Welcome to Coterie!!',
      description:
        'Coterie is here to give you the confidence, skills and community to get out and play. We’ve made it easy to find trusted content that will build your confidence, content that you can trust was made specifically for women.',
    },
    step_two: {
      title: 'Our Community',
      description:
        'Don’t be shy, engage with the Coterie community! Post your latest sports moments, questions or tips to the Community section and react to others. Are you ready for a supportive community of women that celebrates all your wins? 🙋‍♀️',
    },
    step_three: {
      title: 'Learn and Grow Your Game',
      description:
        'Improve your skills with our 3-minute golf and pickleball lessons. Looking for more structure? Our Programs will guide you in the right direction with a clear pathway to success! We have the best content on the internet to help you feel confident on and off the course.',
    },
    step_five: {
      title: 'Earn Badges!',
      description:
        'Engage with the Coterie community and track your achievements with badges. With Coterie you can learn in the comfort and privacy of your own home and earn bragging rights as you go!',
    },
    step_four: {
      title: 'Connect with other players',
      description: `Find other players who share your interests and skill level. Whether you're looking for a golf partner or a pickleball opponent, we make it easier than ever to find the perfect match.`,
    },
    button: {
      start_now: 'Start Now!',
      next: 'Next',
      skip: 'Skip',
      close: 'Close',
    },
  },
}
