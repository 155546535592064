import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import useChat from 'hooks/useChat'
import { useSocket } from 'hooks/useSocket'
import { IChatMessage } from 'models/chat'
import useStore from 'store'

interface IChatProviderProps {
  children: React.ReactNode
}

export default function ChatProvider({ children }: IChatProviderProps) {
  const queryClient = useQueryClient()
  const { socket } = useSocket()
  const account = useStore(store => store.account)
  const chatSelected = useStore(store => store.chatSelected)
  const viewChat = useStore(store => store.viewChat)
  const { seenChats } = useChat()

  useEffect(() => {
    socket.on('chat:message', (chatMessage: IChatMessage) => {
      const queryKey = ['getUserChatById', chatMessage.roomId]
      queryClient.invalidateQueries(queryKey)

      if (chatMessage.room?.usersRoom.find(user => user.userId === account?.user.id)) {
        if (chatSelected === chatMessage.roomId) {
          viewChat(chatSelected)
        }

        seenChats(false)
      }
    })

    return () => {
      socket.off('chat:message')
    }
  }, [account, chatSelected, queryClient, seenChats, socket, viewChat])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
