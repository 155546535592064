import Geocode from 'react-geocode'
import { ENABLE_GET_LOCATION, GOOGLE_MAPS_API_KEY } from 'common/env'
import { getRegion } from './get-region'

Geocode.setApiKey(GOOGLE_MAPS_API_KEY)

Geocode.setLanguage('en')

Geocode.setRegion('es')

const getCoordinates = () =>
  new Promise(
    (res: (value: GeolocationPosition) => void, rej: (value: GeolocationPositionError) => void) => {
      navigator.geolocation.getCurrentPosition(
        success => {
          return res(success)
        },
        err => rej(err)
      )
    }
  )

export async function getAddress() {
  if (!ENABLE_GET_LOCATION) {
    return {
      state: '',
      city: '',
      country: '',
      state_short_name: '',
    }
  }

  try {
    const location = await getCoordinates()

    const response = await Geocode.fromLatLng(
      String(location.coords.latitude),
      String(location.coords.longitude)
    )

    const { state, city, country, state_short_name } = getRegion(response)

    return {
      city,
      state,
      country,
      state_short_name,
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      city: '',
      state: '',
      country: '',
      state_short_name: '',
    }
  }
}
