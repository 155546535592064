import useStore from 'store'

export type AlertColor = 'success' | 'info' | 'warning' | 'error' | 'newPost'

export type TMessage = {
  message: string
}

export type TUseSnackbar = {
  message: string | undefined
  type: AlertColor | undefined
  success: ({ message }: TMessage) => void
  error: ({ message }: TMessage) => void
  newPost: ({ message }: TMessage) => void
  close: () => void
}

export default function useSnackbar(): TUseSnackbar {
  const { message, type, success, error, newPost, close } = useStore(state => state)

  return {
    message,
    type,
    success,
    error,
    newPost,
    close,
  }
}
