/* eslint-disable quotes */
export default {
  forgot: {
    reset_password: 'Reset password',
    how: "Enter the email associated with your account and we'll send an email with a password reset link",
    cancel: 'Cancel',
    email: 'Email',
    reset_link: 'Send password reset link',
    success: 'Please check your email and click on the link we sent to set your new password.',
    error: 'Something went wrong. Please try again.',
  },
}
