import create, { GetState, SetState } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  TAuthSlice,
  createAuthSlice,
  TToastlice,
  createToastSlice,
  TAchievementSlice,
  createAchievementSlice,
  createModalSlice,
  TModalSlice,
  createNotificationSlice,
  TNotificationSlice,
  createChatSlice,
  TChatSlice,
  createContentSlice,
  TContentSlice,
} from './slices'

export type TGlobalStore = TAuthSlice &
  TToastlice &
  TAchievementSlice &
  TModalSlice &
  TNotificationSlice &
  TChatSlice &
  TContentSlice

const store = (set: SetState<TGlobalStore>, get: GetState<TGlobalStore>): TGlobalStore => ({
  ...createAuthSlice(set, get),
  ...createToastSlice(set, get),
  ...createAchievementSlice(set, get),
  ...createModalSlice(set, get),
  ...createNotificationSlice(set, get),
  ...createChatSlice(set, get),
  ...createContentSlice(set, get),
})

const useStore = create<TGlobalStore>(devtools(store))

export default useStore
