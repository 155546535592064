export default {
  personalize: {
    proceed: 'I am done! Take me to the Home Page',
    what_your_training_level: 'What is your golf skill level?',
    curret_abilities:
      'To give you the best experience we need to know what is your level of experience with:',
    welcome: 'Welcome to Coterie',
    welcome_white_label: 'Welcome',
    beginner: 'I want to learn it all!',
    intermediate: 'I already play but want to improve!',
    advanced: 'I am a pro but want to continue to learn',
    not_interested: 'I am not interested in it',
    error: 'Please, select at least one sport',
  },
}
