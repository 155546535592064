import { useEffect } from 'react'
import BadgeDetailModal from 'components/BadgeDetailModal'
import Box from 'components/Box'
import EarnBadgeModal from 'components/EarnBadgeModal'
import { useAchievements } from 'hooks'

interface IAchievementProviderProps {
  children: React.ReactNode
}

const AchievementProvider = ({ children }: IAchievementProviderProps) => {
  const {
    profileAchievement,
    completeAchievement,
    newAchievement,
    detailAchievement,
    getCompleteAchievement,
    getProfileAchievement,
    notificationNewAchievement,
    detailsAchievement,
  } = useAchievements()

  useEffect(() => {
    if (!completeAchievement) getCompleteAchievement()
    if (!profileAchievement) getProfileAchievement()
  }, [getCompleteAchievement, getProfileAchievement, completeAchievement, profileAchievement])

  const clearNewAchievement = () => {
    notificationNewAchievement({ achievement: null })
    detailsAchievement({ achievement: null })
  }

  return (
    <Box>
      <EarnBadgeModal
        achievement={newAchievement}
        open={!!newAchievement}
        onClose={clearNewAchievement}
      />

      <BadgeDetailModal
        achievement={detailAchievement}
        open={!!detailAchievement}
        onClose={clearNewAchievement}
      />

      {children}
    </Box>
  )
}

export default AchievementProvider
