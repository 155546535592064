import { TAchievementResponse } from 'models/badge'
import api from 'services/api'

interface IGetAchievement {
  id: string
}

interface IGetAchievementNext {
  id: string
}

export const getAllAchievements = async (): Promise<TAchievementResponse[]> => {
  const { data } = await api.get('/achievement')
  return data
}

export const getAchievement = async ({ id }: IGetAchievement): Promise<TAchievementResponse> => {
  const { data } = await api.get(`/achievement/${id}`)
  return data
}

export const getAchievementCompleted = async (): Promise<TAchievementResponse[]> => {
  const { data } = await api.get('/achievement/completed')
  return data
}

export const getAchievementGroupCompleted = async (): Promise<TAchievementResponse[]> => {
  const { data } = await api.get('/achievement/groupcompleted')
  return data
}

export const getAchievementGroupInactive = async (): Promise<TAchievementResponse[]> => {
  const { data } = await api.get('/achievement/groupinactive')
  return data
}

export const getAchievementActive = async (): Promise<TAchievementResponse[]> => {
  const { data } = await api.get('/achievement/active')
  return data
}

export const getAchievementInactive = async (): Promise<TAchievementResponse[]> => {
  const { data } = await api.get('/achievement/inactive')
  return data
}

export const getAchievementNext = async ({
  id,
}: IGetAchievementNext): Promise<TAchievementResponse> => {
  const { data } = await api.get(`/achievement/${id}/next`)
  return data
}

export const getViewsAchievement = async (): Promise<unknown> => {
  const { data } = await api.get('achievement/views')

  return data
}
