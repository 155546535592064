import { ResponseComposition, MockedRequest, RestContext } from 'msw'

export const tags = (_req: MockedRequest, res: ResponseComposition, ctx: RestContext) => {
  return res(
    ctx.status(200),
    ctx.delay(2000),
    ctx.json([
      {
        id: 'ecbea4e1-4972-472f-acce-a649330ecf0c',
        sport: 'Golf',
        level: 'medium',
      },
      {
        id: 'c5e16392-dcd8-4a6a-a284-a5e097366dcb',
        sport: 'Golf',
        level: 'hard',
      },
      {
        id: '191e07de-0954-4f34-a8bb-f5d9fc5212f3',
        sport: 'Golf',
        level: 'beginner',
      },
    ])
  )
}

export const tag = (_req: MockedRequest, res: ResponseComposition, ctx: RestContext) => {
  return res(
    ctx.status(200),
    ctx.delay(2000),
    ctx.json({
      id: 'ecbea4e1-4972-472f-acce-a649330ecf0c',
      sport: 'Golf',
      level: 'medium',
    })
  )
}
