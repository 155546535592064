import { BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from 'components/Box'

export type TBackgroundImageContent = { image: string } & BoxProps

export const CustomCarousel = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  & .carousel .control-dots .dot {
    width: 6px;
    height: 6px;
    background: #d7cfe6 !important;
    opaticy: 1 !important;
    margin: 0 4px;

    &.selected {
      background: #271b47 !important;
    }
  }

  & .carousel {
    border-radius: 4px;
  }

  & .carousel-root {
    width: 100%;
  }
`

export const BackgroundImageContent = styled(Box)<TBackgroundImageContent>`
  @media (max-width: 800px) {
    height: 180px;
    background-size: contain;
    background-origin: content-box;
    padding: 16px;
  }

  background-image: url(${({ image }) => image});
  height: 260px;
  width: 100%;
  background-size: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #271b47;
  display: flex;
  border-radius: 0 0 40px 0;
`
