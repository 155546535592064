import * as React from 'react'
import { useTheme } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { IconHome, IconMoments, IconContent, IconPlanToPlay, IconSettings } from 'assets/icon'
import BottomNavigation from 'components/BottomNavigation'
import BottomNavigationAction from 'components/BottomNavigationAction'
import Box from 'components/Box'
import { IconAddButton } from 'components/IconAddButton'
import ShareMoment from 'containers/ShareMoment'
import { useWhiteLabel } from 'hooks/useWhiteLabel'

export default function MobileNav() {
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()

  const [value, setValue] = React.useState(0)
  const [shareMomentOpen, setShareMomentOpen] = React.useState(false)

  function iconFill(pathname: string) {
    if (location.pathname === pathname && whiteLabel?.theme) {
      return `${whiteLabel?.theme.palette.primary.main}`
    }
    if (location.pathname === pathname && !whiteLabel?.theme) {
      return `${theme.palette.primary.dark}`
    }
    if (whiteLabel?.theme) return `${whiteLabel?.theme.palette.blue.secondary}`
    return `${theme.palette.secondary.main}`
  }

  return (
    <Box sx={{ marginTop: 10, zIndex: 999 }}>
      <ShareMoment open={shareMomentOpen} setOpen={() => setShareMomentOpen(!shareMomentOpen)} />

      <BottomNavigation
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: '0px',
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
      >
        <BottomNavigationAction
          onClick={() => navigate('/home', { replace: true })}
          label="Home"
          icon={<IconHome fill={iconFill('/home')} />}
        />
        <BottomNavigationAction
          onClick={() => navigate('/moments', { replace: true })}
          label="Your Posts"
          icon={<IconMoments fill={iconFill('/moments')} />}
        />
        <BottomNavigationAction
          onClick={() => setShareMomentOpen(true)}
          icon={
            <div
              style={{
                marginBottom: 2,
              }}
            >
              <IconAddButton
                backgroundColor={
                  whiteLabel?.theme ? theme.palette.secondary.main : theme.palette.secondary.dark
                }
              />
            </div>
          }
        />
        {!whiteLabel?.theme ||
          (!!whiteLabel?.blockedRoutes && !whiteLabel?.blockedRoutes.includes('content') && (
            <BottomNavigationAction
              onClick={() => navigate('/content', { replace: true })}
              label="Improve Your Game"
              icon={
                <IconContent
                  fill={
                    location.pathname === '/content'
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.main
                  }
                />
              }
            />
          ))}

        <BottomNavigationAction
          onClick={() => navigate('/player-connect', { replace: true })}
          label="Connect and Play"
          icon={
            <IconPlanToPlay fill={iconFill('/player-connect' || '/player-connect/create-match')} />
          }
        />

        {((!!whiteLabel?.blockedRoutes &&
          !whiteLabel?.blockedRoutes.includes('settings/edit-profile')) ||
          !whiteLabel?.theme) && (
          <BottomNavigationAction
            onClick={() => navigate('/settings/edit-profile', { replace: true })}
            label="Settings"
            icon={
              <IconSettings fill={iconFill('/settings/edit-profile')} width="23px" height="23px" />
            }
          />
        )}
      </BottomNavigation>
    </Box>
  )
}
