import React, { Suspense } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import GlobalStylesCss from 'common/styles/GlobalStyleCss'
import AppLoading from 'containers/AppLoading'
import { ErrorHandlerBoundary } from 'containers/ErrorHandler'
import SnackbarProvider from 'containers/SnackbarProvider'
import { SocketProvider } from 'hooks/useSocket'
import { WhiteLabelProvider } from 'hooks/useWhiteLabel'
import { i18n } from 'plugin'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

interface IAppProviderProps {
  children: React.ReactNode
}

const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  // const { whiteLabelTheme } = useWhiteLabel()

  return (
    <ErrorHandlerBoundary>
      <QueryClientProvider client={queryClient}>
        <SocketProvider>
          <WhiteLabelProvider>
            <CssBaseline />
            <GlobalStyles styles={GlobalStylesCss} />
            <SnackbarProvider>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <Suspense fallback={<AppLoading />}>{children}</Suspense>
                </BrowserRouter>
              </I18nextProvider>
            </SnackbarProvider>
            {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
          </WhiteLabelProvider>
        </SocketProvider>
      </QueryClientProvider>
    </ErrorHandlerBoundary>
  )
}

export default AppProvider
