import { styled } from '@mui/material/styles'
import Box from 'components/Box'

export const StyledContainer = styled(Box)`
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 448px;
  box-shadow: ${props => props.theme.shadows[2]};
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: 4px;
  :focus-visible {
    outline: none;
  }
`
