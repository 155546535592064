/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect, useCallback, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useMediaQuery, useTheme } from '@mui/material'
import { useDebounce } from '@react-hooks-library/core'
import Box from 'components/Box'
import IconButton from 'components/IconButton'

interface iPostMoment {
  title: string
  description: string
  link: string
  file: Blob
}
interface IPreviewShareMomentProps {
  moment: iPostMoment
  clearFile: () => void
}

const PreviewShareMoment = ({ moment, clearFile }: IPreviewShareMomentProps) => {
  const [previewType, setPreviewType] = useState<string>()
  const [previewFile, setPreviewFile] = useState<string>()
  const theme = useTheme()

  const media = useMediaQuery(theme.breakpoints.down('md'))

  const momentBounce = useDebounce(moment, 1000)

  const videoRef = useRef<HTMLVideoElement>(null)

  const getMomentType = useCallback(() => {
    if (moment.file.size > 0) {
      const isImage = /(^image)(\/)[a-zA-Z0-9_]*/gm.test(moment.file.type)
      const isVideo = /(^video)(\/)[a-zA-Z0-9_]*/gm.test(moment.file.type)

      if (isImage) return 'IMAGE'
      if (isVideo) return 'VIDEO'
    }

    if (moment.link) return 'LINK'

    return 'TEXT'
  }, [moment])

  useEffect(() => {
    const newType = getMomentType()
    setPreviewType(newType)
  }, [momentBounce, getMomentType])

  useEffect(() => {
    if (previewType === 'IMAGE') {
      const reader = new FileReader()
      reader.readAsDataURL(moment.file)

      reader.onloadend = () => {
        setPreviewFile(reader.result?.toString())
      }
    }

    if (previewType === 'VIDEO') {
      const url = URL.createObjectURL(moment.file)

      setPreviewFile(url)
    }
  }, [previewType, moment.file])

  useEffect(() => {
    videoRef.current?.load()
  }, [previewFile])

  const PreviewImage = () => {
    return (
      <Box sx={{ height: '364px', maxWidth: '100%', objectFit: 'cover', marginBottom: 2 }}>
        <IconButton
          color="primary"
          aria-label="menu"
          sx={{
            position: 'relative',
            top: 50,
            left: '88%',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #271B47',
          }}
          onClick={clearFile}
        >
          <CloseIcon color="secondary" />
        </IconButton>
        {previewFile && (
          <img
            height="90%"
            width="100%"
            style={{ borderRadius: 4 }}
            alt={moment.title}
            src={previewFile}
          />
        )}
      </Box>
    )
  }

  const renderPreviewVideo = useCallback(() => {
    return (
      <Box
        sx={{
          position: 'relative',
          maxWidth: '100%',
          objectFit: 'cover',
          borderRadius: 1,
        }}
      >
        <IconButton
          color="primary"
          aria-label="menu"
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #271B47',
            zIndex: 1,

            [theme.breakpoints.down('md')]: {
              top: 4,
            },
          }}
          onClick={clearFile}
        >
          <CloseIcon color="secondary" />
        </IconButton>
        <video ref={videoRef} style={{ height: media ? '160px' : '240px', width: '100%' }} controls>
          <source src={previewFile} />
        </video>
      </Box>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFile, previewFile])

  const PreviewLink = () => {
    return <Box />
  }

  return (
    <Box>
      {previewType === 'IMAGE' && <PreviewImage />}
      {previewType === 'VIDEO' && renderPreviewVideo()}
      {previewType === 'LINK' && <PreviewLink />}
    </Box>
  )
}

export default PreviewShareMoment
