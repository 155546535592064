import { useTheme } from '@mui/material'
import { Fade, Modal } from 'components'
import { ESegmentTrack, segmentService } from 'services/segment'
import FormShareMoment from './FormShareMoment'
import { StyledContainer } from './styles'

interface IShareMomentsProps {
  open: boolean
  setOpen: (open: boolean) => void
}

function ShareMoment({ open, setOpen }: IShareMomentsProps) {
  const theme = useTheme()
  return (
    <Modal
      open={open}
      onClose={() => [
        setOpen(false),
        segmentService.track(ESegmentTrack.Button_Clicked, { button: 'Cancel share moment' }),
      ]}
      disableEnforceFocus
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500, style: { backgroundColor: theme.shadows[4] } } }}
    >
      <Fade in={open}>
        <StyledContainer>
          <FormShareMoment
            setOpen={() => {
              setOpen(false)
            }}
          />
        </StyledContainer>
      </Fade>
    </Modal>
  )
}

export default ShareMoment
