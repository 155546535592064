import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box)`
  .rdw-editor-main {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    height: 55px;
    padding: calc(1rem);

    :hover {
      border: 1px solid ${({ theme }) => theme.palette.secondary.dark};
    }

    :focus {
      border: 2px solid ${({ theme }) => theme.palette.secondary.dark};
    }

    :focus-within {
      border: 2px solid ${({ theme }) => theme.palette.secondary.dark};
    }
  }
`
