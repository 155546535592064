import * as React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LockResetIcon from '@mui/icons-material/LockReset'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PaidIcon from '@mui/icons-material/Paid'
import { useMediaQuery, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { Link as LinkRouter, useLocation } from 'react-router-dom'
import {
  IconSettings,
  IconLogout,
  IconInviteFriendsFilled,
  NotificationsOutlinedIcon,
} from 'assets/icon'
import { Logo } from 'assets/images'
import AppBar from 'components/AppBar'
import Avatar from 'components/Avatar'
import Box from 'components/Box'
import ChatBell from 'components/ChatBell'
import Container from 'components/Container'
import Drawer from 'components/Drawer'
import IconButton from 'components/IconButton'
import Link from 'components/Link'
import NotificationBell from 'components/NotificationBell'
import Stack from 'components/Stack'
import Toolbar from 'components/Toolbar'
import Tooltip from 'components/Tooltip'
import Typography from 'components/Typography'
import NotificationSnackbar from 'containers/NotificationSnackbar'
import { useSocket } from 'hooks/useSocket'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { ESegmentTrack, segmentService } from 'services/segment'
import useStore from 'store'

export default function BasicMenu() {
  const { account, logout, isAdmin } = useStore(state => state)

  // const navigate = useNavigate()
  const themeHook = useTheme()
  const media = useMediaQuery(themeHook.breakpoints.down('md'))
  // const isSmallMedia = useMediaQuery(themeHook.breakpoints.down(310))

  const location = useLocation()

  const { socket } = useSocket()

  const { whiteLabel } = useWhiteLabel()

  const firstLetters = account?.user.name.charAt(0)

  const handleLogout = () => {
    logout(whiteLabel?.domain)

    socket.disconnect()

    segmentService.track(ESegmentTrack.Button_Clicked, {
      button: 'logout',
    })

    segmentService.reset()

    window.location.replace(`${window.location.protocol}//${window.location.host}/`)
  }

  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    segmentService.track(ESegmentTrack.Button_Clicked, {
      button: 'User Menu',
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { t } = useTranslation()

  const route = window.location.pathname

  const UserMenu = () => (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          width: '212px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem>
        <ListItemIcon>
          <IconUser fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1" color="primary.dark">
          {t('common.menu.profile')}
        </Typography>
      </MenuItem> */}

      <MenuItem
        component={Link}
        href="/settings/edit-profile"
        onClick={() => {
          segmentService.track(ESegmentTrack.Button_Clicked, {
            button: 'User settings',
          })
        }}
        sx={{
          ':hover': {
            backgroundColor: whiteLabel?.theme
              ? themeHook.palette.primary.light
              : themeHook.palette.neutral.light,
          },
        }}
      >
        <ListItemIcon>
          <IconSettings
            width="16px"
            fill={
              whiteLabel?.theme
                ? whiteLabel?.theme.palette.primary.main
                : themeHook.palette.primary.dark
            }
          />
        </ListItemIcon>
        <Typography
          variant="body1"
          color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
          sx={{
            ':hover': {
              color: whiteLabel?.theme ? themeHook.palette.text.buttonHover : 'purple.primary',
            },
          }}
        >
          {t('common.menu.settings')}
        </Typography>
      </MenuItem>

      {isAdmin() && (
        <MenuItem
          component={Link}
          href="/admin"
          sx={{
            ':hover': {
              color: whiteLabel?.theme
                ? themeHook.palette.text.buttonHover
                : themeHook.palette.primary.main,
              backgroundColor: whiteLabel?.theme
                ? themeHook.palette.primary.light
                : themeHook.palette.neutral.light,
            },
          }}
        >
          <ListItemIcon>
            <IconSettings
              width="16px"
              fill={
                whiteLabel?.theme
                  ? whiteLabel?.theme.palette.primary.main
                  : themeHook.palette.primary.dark
              }
            />
          </ListItemIcon>
          <Typography
            variant="body1"
            color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
            sx={{
              ':hover': {
                color: whiteLabel?.theme ? themeHook.palette.text.buttonHover : 'purple.primary',
              },
            }}
          >
            {t('common.menu.admin')}
          </Typography>
        </MenuItem>
      )}

      {/* <Divider /> */}

      <MenuItem
        onClick={handleLogout}
        sx={{
          ':hover': {
            color: whiteLabel?.theme
              ? themeHook.palette.text.buttonHover
              : themeHook.palette.primary.main,
            backgroundColor: whiteLabel?.theme
              ? themeHook.palette.primary.light
              : themeHook.palette.neutral.light,
          },
        }}
      >
        <ListItemIcon>
          <IconLogout
            fontSize="small"
            fill={
              whiteLabel?.theme
                ? whiteLabel?.theme.palette.primary.main
                : themeHook.palette.primary.dark
            }
          />
        </ListItemIcon>
        <Typography
          variant="body1"
          color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
          sx={{
            ':hover': {
              color: whiteLabel?.theme ? themeHook.palette.text.buttonHover : 'purple.primary',
            },
          }}
        >
          {t('common.menu.logout')}
        </Typography>
      </MenuItem>
    </Menu>
  )

  const NavMenu = () => {
    return (
      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ justifyItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 3 }}>
          <ArrowBackIcon fontSize="small" sx={{ mr: 2 }} />
          <Typography
            fontSize="20px"
            fontWeight="600"
            variant="h3"
            color={themeHook.palette.text.primary}
          >
            Menu
          </Typography>
        </Box>

        {/* <MenuItem>
          <ListItemIcon>
            <IconUser fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1" color="primary.dark">
            {t('common.menu.profile')}
          </Typography>
        </MenuItem> */}

        <MenuItem
          component={Link}
          href="/settings/edit-profile"
          sx={{
            ':hover': {
              color: whiteLabel?.theme
                ? themeHook.palette.text.buttonHover
                : themeHook.palette.primary.main,
              backgroundColor: whiteLabel?.theme
                ? themeHook.palette.primary.light
                : themeHook.palette.neutral.light,
            },
          }}
        >
          <ListItemIcon>
            <IconSettings
              width="16px"
              fill={whiteLabel?.theme ? whiteLabel?.theme.palette.primary.main : 'primary.dark'}
            />
          </ListItemIcon>
          <Typography
            variant="body1"
            color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
            sx={{
              ':hover': {
                color: whiteLabel?.theme
                  ? whiteLabel?.theme.palette.text.buttonHover
                  : 'primary.dark',
              },
            }}
          >
            {t('common.menu.settings')}
          </Typography>
        </MenuItem>

        <MenuItem
          component={Link}
          href="/settings/notifications"
          sx={{
            ':hover': {
              color: whiteLabel?.theme
                ? themeHook.palette.text.buttonHover
                : themeHook.palette.primary.main,
              backgroundColor: whiteLabel?.theme
                ? themeHook.palette.primary.light
                : themeHook.palette.neutral.light,
            },
          }}
        >
          <ListItemIcon>
            <NotificationsOutlinedIcon
              style={{
                color: whiteLabel?.theme ? whiteLabel?.theme.palette.primary.main : 'primary.dark',
              }}
            />
          </ListItemIcon>
          <Typography
            variant="body1"
            color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
            sx={{
              ':hover': {
                color: whiteLabel?.theme
                  ? whiteLabel?.theme.palette.text.buttonHover
                  : 'primary.dark',
              },
            }}
          >
            {t('notifications.title')}
          </Typography>
        </MenuItem>

        {isAdmin() && (
          <MenuItem
            component={Link}
            href="/admin"
            sx={{
              ':hover': {
                color: whiteLabel?.theme
                  ? themeHook.palette.text.buttonHover
                  : themeHook.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? themeHook.palette.primary.light
                  : themeHook.palette.neutral.light,
              },
            }}
          >
            <ListItemIcon>
              <IconSettings
                width="16px"
                fill={whiteLabel?.theme ? whiteLabel?.theme.palette.primary.main : 'primary.dark'}
              />
            </ListItemIcon>
            <Typography
              variant="body1"
              color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
              sx={{
                ':hover': {
                  color: whiteLabel?.theme
                    ? whiteLabel?.theme.palette.text.buttonHover
                    : 'primary.dark',
                },
              }}
            >
              {t('common.menu.admin')}
            </Typography>
          </MenuItem>
        )}

        {/* <Divider /> */}

        {route.includes('/settings') && (
          <>
            <MenuItem
              component={Link}
              href="/settings/edit-profile"
              sx={{
                ':hover': {
                  color: whiteLabel?.theme
                    ? themeHook.palette.text.buttonHover
                    : themeHook.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? themeHook.palette.primary.light
                    : themeHook.palette.neutral.light,
                },
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon
                  width="20px"
                  height="18px"
                  style={{
                    color: whiteLabel?.theme
                      ? whiteLabel?.theme.palette.primary.main
                      : 'primary.dark',
                  }}
                />
              </ListItemIcon>
              <Typography
                variant="body1"
                color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
                sx={{
                  ':hover': {
                    color: whiteLabel?.theme
                      ? whiteLabel?.theme.palette.text.buttonHover
                      : 'primary.dark',
                  },
                }}
              >
                {t('edit_profile.edit_profile')}
              </Typography>
            </MenuItem>

            <MenuItem
              component={Link}
              href="/settings/change-password"
              sx={{
                ':hover': {
                  color: whiteLabel?.theme
                    ? themeHook.palette.text.buttonHover
                    : themeHook.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? themeHook.palette.primary.light
                    : themeHook.palette.neutral.light,
                },
              }}
            >
              <ListItemIcon>
                <LockResetIcon
                  width="20px"
                  height="18px"
                  style={{
                    color: whiteLabel?.theme
                      ? whiteLabel?.theme.palette.primary.main
                      : 'primary.dark',
                  }}
                />
              </ListItemIcon>
              <Typography
                variant="body1"
                color={whiteLabel?.theme ? themeHook.palette.text.primary : 'primary.dark'}
                sx={{
                  ':hover': {
                    color: whiteLabel?.theme
                      ? whiteLabel?.theme.palette.text.buttonHover
                      : 'primary.dark',
                  },
                }}
              >
                {t('account.change_password')}
              </Typography>
            </MenuItem>

            {!whiteLabel?.blockedRoutes ||
              (!!whiteLabel?.blockedRoutes &&
                !whiteLabel?.blockedRoutes?.includes('subscription') && (
                  <MenuItem
                    component={Link}
                    href="/settings/subscription"
                    sx={{
                      ':hover': {
                        color: whiteLabel?.theme
                          ? themeHook.palette.text.buttonHover
                          : themeHook.palette.primary.main,
                        backgroundColor: whiteLabel?.theme
                          ? themeHook.palette.primary.light
                          : themeHook.palette.neutral.light,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <PaidIcon width="20px" height="18px" style={{ color: '#645788' }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="text.primary">
                      {t('common.menu.subscription')}
                    </Typography>
                  </MenuItem>
                ))}

            <MenuItem
              component={Link}
              href="/settings/invite-friends"
              sx={{
                ':hover': {
                  color: whiteLabel?.theme
                    ? themeHook.palette.text.buttonHover
                    : themeHook.palette.primary.main,
                  backgroundColor: whiteLabel?.theme
                    ? themeHook.palette.primary.light
                    : themeHook.palette.neutral.light,
                },
              }}
            >
              <ListItemIcon>
                <IconInviteFriendsFilled
                  fill={whiteLabel?.theme ? whiteLabel?.theme.palette.primary.main : '#645888'}
                />
              </ListItemIcon>
              <Typography
                variant="body1"
                color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
                sx={{
                  ':hover': {
                    color: whiteLabel?.theme
                      ? whiteLabel?.theme.palette.text.buttonHover
                      : 'primary.dark',
                  },
                }}
              >
                {t('common.menu.invite_friends')}
              </Typography>
            </MenuItem>

            <Divider />
          </>
        )}

        {!route.includes('/settings') && (
          <MenuItem
            component={Link}
            href="/settings/invite-friends"
            sx={{
              ':hover': {
                color: whiteLabel?.theme
                  ? themeHook.palette.text.buttonHover
                  : themeHook.palette.primary.main,
                backgroundColor: whiteLabel?.theme
                  ? themeHook.palette.primary.light
                  : themeHook.palette.neutral.light,
              },
            }}
          >
            <ListItemIcon>
              <IconInviteFriendsFilled
                fill={whiteLabel?.theme ? whiteLabel?.theme.palette.primary.main : 'primary.dark'}
              />
            </ListItemIcon>
            <Typography
              variant="body1"
              color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
              sx={{
                ':hover': {
                  color: whiteLabel?.theme
                    ? whiteLabel?.theme.palette.text.buttonHover
                    : 'primary.dark',
                },
              }}
            >
              {t('common.menu.invite_friends')}
            </Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={handleLogout}
          sx={{
            ':hover': {
              color: whiteLabel?.theme
                ? themeHook.palette.text.buttonHover
                : themeHook.palette.primary.main,
              backgroundColor: whiteLabel?.theme
                ? themeHook.palette.primary.light
                : themeHook.palette.neutral.light,
            },
          }}
        >
          <ListItemIcon>
            <IconLogout
              fontSize="small"
              fill={whiteLabel?.theme ? whiteLabel?.theme.palette.primary.main : '#645888'}
            />
          </ListItemIcon>
          <Typography
            variant="body1"
            color={whiteLabel?.theme ? whiteLabel?.theme.palette.text.primary : 'primary.dark'}
            sx={{
              ':hover': {
                color: whiteLabel?.theme
                  ? whiteLabel?.theme.palette.text.buttonHover
                  : 'primary.dark',
              },
            }}
          >
            {t('common.menu.logout')}
          </Typography>
        </MenuItem>
      </Stack>
    )
  }

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          maxHeight: 80,
          py: theme => theme.spacing(2),
          border: 0,
          top: 0,
          zIndex: theme => theme.zIndex.appBar,
          backgroundColor: theme => theme.palette.common.white,
          boxShadow: 2,
        }}
      >
        <Container>
          <Toolbar
            sx={{
              '&.MuiToolbar-root': {
                padding: 0,
                minHeight: 'auto',
                justifyContent: 'space-between',
              },
            }}
          >
            <Link
              component={LinkRouter}
              underline="none"
              to="/home"
              onClick={() => {
                segmentService.track(ESegmentTrack.Button_Clicked, {
                  button: 'Home Logo',
                })
              }}
            >
              {whiteLabel?.theme ? (
                <img
                  src={
                    whiteLabel.files.find(file =>
                      file.fileName.includes(`${whiteLabel.domain}-white-label/logo`)
                    )?.fileUrl
                  }
                  alt="white-label-logo"
                  height="32px"
                />
              ) : (
                <Logo height="32px" />
              )}
            </Link>

            {media && (
              <>
                <ChatBell
                  newChatMessages={account?.user.newChatMessages}
                  seenChatMessages={account?.user.seenChatMessages}
                  whiteLabel={whiteLabel}
                />
                <NotificationBell
                  seenNotifications={account?.user.seenNotifications}
                  newNotifications={account?.user.newNotifications}
                  whiteLabel={whiteLabel}
                />
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="menu"
                  onClick={() => setOpenDrawer(!openDrawer)}
                >
                  <Avatar
                    sx={{
                      bgcolor: whiteLabel?.theme
                        ? whiteLabel?.theme.palette.primary.main
                        : themeHook.palette.success.main,
                      width: '40px',
                      height: '40px',
                    }}
                    aria-label="recipe"
                    src={account?.user.file?.fileUrl || ''}
                  >
                    {firstLetters}
                  </Avatar>
                </IconButton>
              </>
            )}
            {!media && (
              <>
                {location.pathname !== '/subscription' && (
                  <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
                    <Button
                      component={Link}
                      href="/home"
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        segmentService.track(ESegmentTrack.Button_Clicked, {
                          button: 'Nav bar home',
                        })
                      }}
                      sx={{
                        color: whiteLabel?.theme
                          ? themeHook.palette.text.primary
                          : themeHook.palette.primary.main,
                        ':hover': {
                          color: whiteLabel?.theme
                            ? themeHook.palette.text.buttonHover
                            : themeHook.palette.primary.main,
                          backgroundColor: theme =>
                            whiteLabel?.theme
                              ? theme.palette.primary.light
                              : theme.palette.secondary.main,
                        },
                      }}
                    >
                      {t('common.menu.home')}
                    </Button>
                    {(!whiteLabel?.blockedRoutes ||
                      (!!whiteLabel?.blockedRoutes &&
                        !whiteLabel?.blockedRoutes?.includes('content'))) && (
                      <Button
                        component={Link}
                        href="/content-list"
                        variant="text"
                        color="secondary"
                        onClick={() => {
                          segmentService.track(ESegmentTrack.Button_Clicked, {
                            button: 'Nav bar content',
                          })
                        }}
                        sx={{
                          color: whiteLabel?.theme
                            ? themeHook.palette.text.primary
                            : themeHook.palette.primary.main,
                          ':hover': {
                            color: whiteLabel?.theme
                              ? themeHook.palette.text.buttonHover
                              : themeHook.palette.primary.main,
                            backgroundColor: theme =>
                              whiteLabel?.theme
                                ? theme.palette.primary.light
                                : theme.palette.secondary.main,
                          },
                        }}
                      >
                        {t('common.menu.content')}
                      </Button>
                    )}
                    <Button
                      id="moment-navbar-button"
                      component={Link}
                      href="/moments"
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        segmentService.track(ESegmentTrack.Button_Clicked, {
                          button: 'Nav bar Moments',
                        })
                      }}
                      sx={{
                        color: whiteLabel?.theme
                          ? themeHook.palette.text.primary
                          : themeHook.palette.primary.main,
                        ':hover': {
                          color: whiteLabel?.theme
                            ? themeHook.palette.text.buttonHover
                            : themeHook.palette.primary.main,
                          backgroundColor: theme =>
                            whiteLabel?.theme
                              ? theme.palette.primary.light
                              : theme.palette.secondary.main,
                        },
                      }}
                    >
                      {t('common.menu.your_posts')}
                    </Button>

                    <Button
                      component={Link}
                      href="/player-connect"
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        segmentService.track(ESegmentTrack.Button_Clicked, {
                          button: 'Nav Bar Player Connect',
                        })
                      }}
                      sx={{
                        color: whiteLabel?.theme
                          ? themeHook.palette.text.primary
                          : themeHook.palette.primary.main,
                        ':hover': {
                          color: whiteLabel?.theme
                            ? themeHook.palette.text.buttonHover
                            : themeHook.palette.primary.main,
                          backgroundColor: theme =>
                            whiteLabel?.theme
                              ? theme.palette.primary.light
                              : theme.palette.secondary.main,
                        },
                      }}
                    >
                      {t('common.menu.player_match')}
                    </Button>
                  </Stack>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                  <Tooltip title="">
                    <>
                      <ChatBell
                        newChatMessages={account?.user.newChatMessages}
                        seenChatMessages={account?.user.seenChatMessages}
                        whiteLabel={whiteLabel}
                      />

                      <NotificationBell
                        seenNotifications={account?.user.seenNotifications}
                        newNotifications={account?.user.newNotifications}
                        whiteLabel={whiteLabel}
                      />
                      <Button
                        onClick={handleClick}
                        color="secondary"
                        sx={{
                          ':hover': {
                            color: whiteLabel?.theme
                              ? themeHook.palette.text.buttonHover
                              : themeHook.palette.primary.main,
                            backgroundColor: theme =>
                              whiteLabel?.theme
                                ? theme.palette.primary.light
                                : theme.palette.secondary.main,
                          },
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: whiteLabel?.theme
                              ? whiteLabel?.theme.palette.primary.main
                              : themeHook.palette.success.main,
                            width: '40px',
                            height: '40px',
                            mr: '8px',
                          }}
                          aria-label="recipe"
                          src={account?.user.file?.fileUrl || ''}
                        >
                          {firstLetters}
                        </Avatar>
                        <Typography
                          variant="body1"
                          color={whiteLabel?.theme ? 'text.primary' : 'purple.primary'}
                          sx={{
                            mr: '8px',
                            ':hover': {
                              color: whiteLabel?.theme
                                ? themeHook.palette.text.buttonHover
                                : 'purple.primary',
                            },
                          }}
                        >
                          {account?.user.name}
                        </Typography>

                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </Button>
                    </>
                  </Tooltip>
                </Box>
              </>
            )}

            <UserMenu />
          </Toolbar>
        </Container>

        <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
          {media && (
            <Box minWidth="60vw" py={4} px={3}>
              <NavMenu />
            </Box>
          )}
          {!media && <NavMenu />}
        </Drawer>
      </AppBar>
      {account && <NotificationSnackbar account={account} />}
    </>
  )
}
