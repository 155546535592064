import { useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Box from 'components/Box'
import Footer from 'components/Footer'
import MobileNav from 'components/MobileNav'
import NavBar from 'components/NavBar'
import { useWhiteLabel } from 'hooks/useWhiteLabel'

const AppLayout = () => {
  const theme = useTheme()
  const media = useMediaQuery(theme.breakpoints.down('md'))
  const { whiteLabel } = useWhiteLabel()

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.common.white }}>
      <NavBar />
      <Box
        sx={{
          pt: {
            xs: 9,
            md: 8,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Outlet />
        {media && <MobileNav />}
      </Box>
      {whiteLabel && <Footer />}
    </Box>
  )
}

export default AppLayout
