import { Components } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import AreaNormalBold from 'assets/fonts/AreaNormal-Bold.woff'
import AreaNormalMedium from 'assets/fonts/AreaNormal-Medium.woff'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Area Normal';
        font-style: normal;
        font-weight: 500;
        src: local('Area Normal'), url(${AreaNormalMedium}) format('woff');
      }
      @font-face {
        font-family: 'Area Normal';
        font-style: normal;
        font-weight: 700;
        src: local('Area Normal'), url(${AreaNormalBold}) format('woff');
      }
    `,
  },
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        backgroundColor: palette.lilac.secondary,
        color: palette.primary.main,
        textTransform: 'none',
        fontFamily: 'Area Normal',
        ':hover': {
          backgroundColor: palette.lilac.primary,
        },
      },
      containedSecondary: {
        backgroundColor: palette.secondary.dark,
        color: palette.primary.main,
        boxShadow: shadows[2],
        textTransform: 'capitalize',
      },
      outlined: {
        backgroundColor: palette.primary.contrastText,
        color: palette.purple.primary,
        border: `1px solid ${palette.primary.main}`,
        fontFamily: 'Area Normal',
        textTransform: 'none',
        ':hover': {
          backgroundColor: palette.neutral.light,
          color: palette.primary.dark,
          borderColor: palette.primary.dark,
        },
      },
      text: {
        color: palette.purple.primary,
        border: `0px solid ${palette.primary.main}`,
        fontFamily: 'Area Normal',
        textTransform: 'none',
        ':hover': {
          backgroundColor: palette.secondary.main,
          color: palette.primary.dark,
          borderColor: palette.primary.dark,
        },
      },
      textSecondary: {
        color: palette.purple.primary,
        border: `0px solid ${palette.primary.main}`,
        fontFamily: 'Area Normal',
        textTransform: 'none',
        ':hover': {
          backgroundColor: palette.purple.hover,
          color: palette.purple.primary,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      outlined: {
        minHeight: 22,
        height: 'auto',
        borderRadius: 8,
      },
      label: {
        ...(typography as TypographyOptions).caption,
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontFamily: 'Area Normal',
        fontSize: '12px',
        borderRadius: '50px',
        padding: '2px 10px',
        backgroundColor: '#444444',
      },
    },
  },

  MuiTextField: {
    styleOverrides: {
      root: {
        marginBottom: 0,
        '& label': {
          color: palette.primary.dark,
        },
        '& label.Mui-focused': {
          color: palette.primary.dark,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: palette.secondary.dark,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: palette.secondary.dark,
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.secondary.dark,
          },
        },
      },
    },
  },

  MuiFormControl: {
    styleOverrides: {
      root: {
        '& label': {
          color: 'palette.primary.dark',
        },
        '& label.Mui-focused': {
          color: palette.primary.dark,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: palette.secondary.dark,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: palette.secondary.dark,
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.secondary.dark,
          },
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        '& label': {
          color: palette.primary.dark,
        },
        '& label.Mui-focused': {
          color: palette.primary.dark,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: palette.secondary.dark,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: palette.secondary.dark,
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.secondary.dark,
          },
        },
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: palette.error.main,
        },
        '&.MuiFormLabel-asterisk': {
          color: palette.text.primary,
        },
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        textTransform: 'initial',
        fontSize: '12px',
      },
    },
  },

  MuiBottomNavigationAction: {
    styleOverrides: {
      label: {
        fontFamily: 'Area Normal',
        fontSize: '10px',
        '&.Mui-selected': {
          fontSize: '10px',
          fontWeight: 'bold',
        },
      },
      root: {
        minWidth: 0,
        padding: 0,
      },
    },
  },

  MuiCardHeader: {
    styleOverrides: {
      subheader: {
        color: '#B5B5B5',
      },
    },
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          padding: '16px',
          paddingTop: '8px',
        },
      },
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      bar: {
        backgroundColor: '#B0DBBE',
      },
      root: {
        backgroundColor: '#E7E7E9',
      },
    },
  },

  MuiAlert: {
    styleOverrides: {
      filledError: {
        backgroundColor: '#8F0000',
      },
    },
  },
}

export default components
