/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { createTheme, responsiveFontSizes, Theme, ThemeProvider } from '@mui/material/styles'
import { PERSISTENCE_KEYS } from 'common/constants'
import { useSocket } from 'hooks/useSocket'
import { TWhiteLabel } from 'models/white-label'
import { IGetWhiteLabelResponse, getWhiteLabelByDomain } from 'services/white-label'
import theme from 'theme'

interface WhiteLabelContextProps {
  whiteLabel?: TWhiteLabel
}

interface WhiteLabelProviderProps {
  children: ReactNode
}

const WhiteLabelContext = createContext<WhiteLabelContextProps>({} as WhiteLabelContextProps)

export function WhiteLabelProvider({ children }: WhiteLabelProviderProps) {
  const [whiteLabel, setWhiteLabel] = useState<TWhiteLabel>({} as TWhiteLabel)
  const [whiteLabelTheme, setWhiteLabelTheme] = useState<Theme>(theme)

  const { socket } = useSocket()

  const getWhiteLabel = async (domain: string) => {
    const whiteLabelStoraged = localStorage.getItem(`${PERSISTENCE_KEYS.WHITE_LABEL}:${domain}`)

    // If theme has storaged, return this
    if (whiteLabelStoraged) {
      const parsedWhiteLabelStorage: IGetWhiteLabelResponse = JSON.parse(whiteLabelStoraged)

      setWhiteLabel({
        ...parsedWhiteLabelStorage,
        theme: JSON.parse(parsedWhiteLabelStorage.theme) as Theme,
        blockedRoutes: JSON.parse(parsedWhiteLabelStorage.blockedRoutes) as string[],
      })

      setWhiteLabelTheme(
        responsiveFontSizes(
          createTheme({
            ...(JSON.parse(parsedWhiteLabelStorage.theme) as Theme),
            components: theme.components,
          })
        )
      )

      return
    }

    const whiteLabelResponse = await getWhiteLabelByDomain(domain)

    setWhiteLabel({
      ...whiteLabelResponse,
      theme: { ...(JSON.parse(whiteLabelResponse.theme) as Theme) },
      blockedRoutes: JSON.parse(whiteLabelResponse.blockedRoutes) as string[],
    })

    setWhiteLabelTheme(
      responsiveFontSizes(
        createTheme({
          ...(JSON.parse(whiteLabelResponse.theme) as Theme),
          components: theme.components,
        })
      )
    )

    localStorage.setItem(
      `${PERSISTENCE_KEYS.WHITE_LABEL}:${domain}`,
      JSON.stringify(whiteLabelResponse)
    )
  }

  useEffect(() => {
    const { hostname } = window.location

    const subdomain = hostname.replace('localhost', '').split(/\.|-/)[0]

    if (subdomain) {
      getWhiteLabel(subdomain)
    } else {
      setWhiteLabelTheme(theme)
    }
  }, [])

  useEffect(() => {
    socket.on('white-label:update', async (whiteLabelDomain: string) => {
      if (whiteLabelDomain === whiteLabel.domain) {
        localStorage.removeItem(`${PERSISTENCE_KEYS.WHITE_LABEL}:${whiteLabel.domain}`)

        await getWhiteLabel(whiteLabel.domain)
      }
    })

    return () => {
      socket.off('white-label:update')
    }
  }, [socket, whiteLabel.domain])

  return (
    <WhiteLabelContext.Provider value={{ whiteLabel }}>
      <ThemeProvider theme={whiteLabelTheme}>{children}</ThemeProvider>
    </WhiteLabelContext.Provider>
  )
}

export function useWhiteLabel() {
  const context = useContext(WhiteLabelContext)

  return context
}
