import { ResponseComposition, MockedRequest, RestContext } from 'msw'
import { v4 as uuidv4 } from 'uuid'
import chance from 'common/utils/fake'

export const content = (_req: MockedRequest, res: ResponseComposition, ctx: RestContext) => {
  const data = [
    {
      id: uuidv4(),
      title: chance.sentence({ words: 2 }),
      description: chance.paragraph(),
      isHighlighted: true,
      createdBy: chance.name({ middle: true }),
      richContent: 'https://source.unsplash.com/random',
      videoContent: null,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: uuidv4(),
      title: 'Test novatics 1',
      description: 'Description test',
      isHighlighted: true,
      createdBy: 'Emanoel',
      richContent: 'https://source.unsplash.com/random',
      videoContent: null,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: uuidv4(),
      title: 'Test novatics 2',
      description: 'Descrição teste',
      isHighlighted: false,
      createdBy: 'Victor',
      richContent: 'https://source.unsplash.com/random',
      videoContent: 'https://vimeo.com/226260195',
      createdAt: '',
      updatedAt: '',
    },
    {
      id: uuidv4(),
      title: 'Teste novatics 3',
      description: 'John',
      isHighlighted: true,
      createdBy: 'Renato',
      richContent: 'https://source.unsplash.com/random',
      videoContent: 'https://vimeo.com/226260195',
      createdAt: '',
      updatedAt: '',
    },

    {
      id: uuidv4(),
      title: 'Teste novatics 4',
      description: 'John',
      isHighlighted: false,
      createdBy: 'Iarlen',
      richContent: 'https://source.unsplash.com/random',
      videoContent: null,
      createdAt: '',
      updatedAt: '',
    },
    {
      id: uuidv4(),
      title: 'Teste novatics 5',
      description: 'Alvaro',
      isHighlighted: true,
      createdBy: 'Alvaro',
      richContent: 'https://source.unsplash.com/random',
      videoContent: 'https://vimeo.com/226260195',
      createdAt: '',
      updatedAt: '',
    },
    {
      id: uuidv4(),
      title: 'Teste novatics 6',
      description: 'John',
      isHighlighted: false,
      createdBy: 'Flávio',
      richContent: 'https://source.unsplash.com/random',
      videoContent: null,
      createdAt: '',
      updatedAt: '',
    },
  ]

  return res(ctx.json(data))
}
