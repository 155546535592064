/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useContext, useEffect } from 'react'
import { io, Socket } from 'socket.io-client'
import { API_URL } from 'common/env'
import useStore from 'store'

interface SocketContextProps {
  socket: Socket
}

interface SocketProviderProps {
  children: ReactNode
}

const socket = io(API_URL, {
  autoConnect: false,
})

const SocketContext = createContext<SocketContextProps>({} as SocketContextProps)

export function SocketProvider({ children }: SocketProviderProps) {
  const account = useStore(store => store.account)

  useEffect(() => {
    if (!!account?.user.id && !socket.connected) {
      socket.connect()
      socket.emit('chat:join', {
        userId: account?.user.id,
      })
    }
  }, [account])

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}

export function useSocket() {
  const context = useContext(SocketContext)

  return context
}
