import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Fade, Typography, Modal } from 'components'
import { useWhiteLabel } from 'hooks/useWhiteLabel'
import { ESegmentTrack, segmentService } from 'services/segment'
import SubscriptionForm from '../SubscriptionForm'
import SubscriptionFormWhiteLabel from '../SubscriptionFormWhiteLabel'
import { StyledContainer } from './styles'

interface SubscriptionModalProps {
  open: boolean
  onClose: () => void
}

const SubscriptionModal = ({ open, onClose }: SubscriptionModalProps) => {
  const theme = useTheme()
  const { whiteLabel } = useWhiteLabel()
  const { t } = useTranslation()

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={() => [
        handleCloseModal(),
        segmentService.track(ESegmentTrack.Button_Clicked, { button: 'Activate Subscription' }),
      ]}
      disableEnforceFocus
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500, style: { backgroundColor: theme.shadows[4] } } }}
    >
      <Fade in={open}>
        <StyledContainer sx={{ '& > form > div:first-of-type': { border: 'none' } }}>
          {whiteLabel?.domain ? (
            <SubscriptionFormWhiteLabel
              title={t('subscription.modal.title')}
              subtitle={t('subscription.modal.subtitle')}
            />
          ) : (
            <SubscriptionForm
              title={t('subscription.modal.title')}
              subtitle={t('subscription.modal.subtitle')}
            />
          )}
          <Typography
            onClick={() => handleCloseModal()}
            sx={{
              display: 'flex',
              alignSelf: 'flex-start',
              marginTop: '-50px',
              marginLeft: '14px',
              color: theme.palette.neutral.dark,
              fontSize: '50px',
              float: 'right',
              transition: '0.2s',
              '&:hover, &:focus': {
                cursor: 'pointer',
                color: theme.palette.success.main,
              },
            }}
          >
            &times;
          </Typography>
        </StyledContainer>
      </Fade>
    </Modal>
  )
}

export default SubscriptionModal
