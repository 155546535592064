import { Fragment, lazy, useCallback, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { SHOW_NEW_HOMEPAGE } from 'common/env'
import AdminLayout from 'containers/AdminLayout'
import AppLayout from 'containers/AppLayout'
import { TRoutes } from 'models/routes'
import { TWhiteLabel } from 'models/white-label'
import PrivateRoute from 'routes/privateRoute'
import ProtectRoute from 'routes/protectRoute'
import useStore from 'store'

const Home = lazy(() => import('pages/NewHome'))
const GolfHome = lazy(() => import('pages/GolfHome'))
const PickleballHome = lazy(() => import('pages/PickleballHome'))
const SignIn = lazy(() => import('pages/SignIn'))
const SignUp = lazy(() => import('pages/SignUp'))
const Subscription = lazy(() => import('pages/Subscription'))
const WaitingList = lazy(() => import('pages/WaitingList'))
const Personalize = lazy(() => import('pages/Personalize'))
const Forgot = lazy(() => import('pages/Forgot'))

const Content = lazy(() => import('pages/Content'))
const ContentDetail = lazy(() => import('pages/ContentDetail'))
const ContentFeed = lazy(() => import('pages/ContentFeed'))
const ProgramsDetail = lazy(() => import('pages/ProgramsDetail'))
const ContentListing = lazy(() => import('pages/ContentList'))
const Programs = lazy(() => import('pages/Programs'))

const Moments = lazy(() => import('pages/Moments'))
const MomentDetail = lazy(() => import('pages/MomentDetail'))

const HomePage = lazy(() => import('pages/HomePage'))
const NewHomePage = lazy(() => import('pages/NewHomePage'))

const AdminHomePage = lazy(() => import('pages/Admin/Home'))
const AdminContentDetailPage = lazy(() => import('pages/Admin/ContentDetail'))
const AdminCreateContentPage = lazy(() => import('pages/Admin/CreateContent'))
const AdminPrograms = lazy(() => import('pages/Admin/Programs'))
const AdminCreateProgramPage = lazy(() => import('pages/Admin/CreateProgram'))
const AdminEditProgramPage = lazy(() => import('pages/Admin/EditProgram'))
const AdminNotifications = lazy(() => import('pages/Admin/Notifications'))
const AdminCreateWhiteLabel = lazy(() => import('pages/Admin/CreateWhiteLabel'))

const EditProfile = lazy(() => import('pages/Settings/EditProfile'))
const Account = lazy(() => import('pages/Settings/Account'))
const InviteFriends = lazy(() => import('pages/Settings/InviteFriends'))
const EditSubscription = lazy(() => import('pages/Settings/EditSubscription'))
const Notifications = lazy(() => import('pages/Settings/Notifications'))
const NotificationPreferences = lazy(() => import('pages/Settings/NotificationPreferences'))

const PlayerConnect = lazy(() => import('pages/PlayerConnect'))
const Availability = lazy(() => import('pages/Availability'))

const Callback = lazy(() => import('pages/Callback'))
const Page404 = lazy(() => import('pages/404'))

const Chat = lazy(() => import('pages/Chat'))

interface WhiteLabelRoutesProps {
  whiteLabel: TWhiteLabel
}

export function WhiteLabelRoutes({ whiteLabel }: WhiteLabelRoutesProps) {
  const isAdmin = useStore(state => state.isAdmin)

  const { blockedRoutes } = whiteLabel

  const routes: TRoutes = useMemo(
    () => ({
      home: (
        <Route
          index
          element={
            <ProtectRoute>
              <Home />
            </ProtectRoute>
          }
        />
      ),

      golf: (
        <Route
          path="/golf"
          element={
            <ProtectRoute>
              <GolfHome />
            </ProtectRoute>
          }
        />
      ),

      pickleball: (
        <Route
          path="/pickleball"
          element={
            <ProtectRoute>
              <PickleballHome />
            </ProtectRoute>
          }
        />
      ),

      waiting_list: (
        <Route
          path="/waiting-list"
          element={
            <ProtectRoute>
              <WaitingList />
            </ProtectRoute>
          }
        />
      ),

      login: (
        <Route
          path="/login"
          element={
            <ProtectRoute>
              <SignIn />
            </ProtectRoute>
          }
        />
      ),

      sign_up: (
        <Route
          path="/sign-up"
          element={
            <ProtectRoute>
              <SignUp />
            </ProtectRoute>
          }
        />
      ),

      subscription: (
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          }
        />
      ),

      personalize: (
        <Route
          path="/personalize"
          element={
            <PrivateRoute>
              <Personalize />
            </PrivateRoute>
          }
        />
      ),

      forgot: (
        <Route
          path="/forgot"
          element={
            <ProtectRoute>
              <Forgot />
            </ProtectRoute>
          }
        />
      ),

      home_page: (
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={SHOW_NEW_HOMEPAGE ? <NewHomePage /> : <HomePage />} />
        </Route>
      ),

      content: (
        <>
          <Route
            path="/content"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route path=":slug" element={<ContentDetail />} />
            <Route index element={<Content />} />
          </Route>

          <Route
            path="/content-feed"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<ContentFeed />} />
          </Route>
        </>
      ),

      programs: (
        <Route
          path="/programs"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Programs />} />
        </Route>
      ),

      content_list: (
        <Route
          path="/content-list"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<ContentListing />} />
        </Route>
      ),

      program_slug: (
        <Route
          path="/program/:slug"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<ProgramsDetail />} />
          <Route path=":contentSlug" element={<ProgramsDetail />} />
        </Route>
      ),

      moments: (
        <>
          <Route
            path="/moments"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Moments />} />
          </Route>
          <Route
            path="/moment-detail/:id"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<MomentDetail />} />
            <Route path=":id" element={<MomentDetail />} />
          </Route>
        </>
      ),

      player_connect: (
        <Route
          path="/player-connect"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route path="availability" element={<Availability />} />
          <Route path="users-available" />
          <Route index element={<PlayerConnect />} />
        </Route>
      ),

      chat: (
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Chat />} />
          <Route path=":id" element={<Chat />} />
        </Route>
      ),

      settings: (
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
        >
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="account" element={<Account />} />
          <Route path="invite-friends" element={<InviteFriends />} />
          <Route path="subscription" element={<EditSubscription />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="notification-preferences" element={<NotificationPreferences />} />

          <Route index element={<EditProfile />} />
        </Route>
      ),

      admin: isAdmin() ? (
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminHomePage />} />
          <Route path="/admin/content/:id" element={<AdminContentDetailPage />} />
          <Route path="/admin/create-content" element={<AdminCreateContentPage />} />
          <Route path="/admin/programs" element={<AdminPrograms />} />
          <Route path="/admin/create-program" element={<AdminCreateProgramPage />} />
          <Route path="/admin/edit-program/:slug" element={<AdminEditProgramPage />} />
          <Route path="/admin/notifications" element={<AdminNotifications />} />
          <Route path="/admin/create-white-label" element={<AdminCreateWhiteLabel />} />
        </Route>
      ) : null,

      callback: <Route path="/callback" element={<Callback />} />,

      privacy: <Route path="/privacy.pdf" />,

      rest: <Route path="*" element={<Page404 />} />,
    }),
    [isAdmin]
  )

  const renderRoutes = useCallback(
    () =>
      (Object.keys(routes) as Array<keyof typeof routes>).filter(
        route => !blockedRoutes.includes(route)
      ),
    [blockedRoutes, routes]
  )

  return (
    <Routes>
      {renderRoutes().map(route => (
        <Fragment key={route}>{routes[route]}</Fragment>
      ))}
    </Routes>
  )
}
