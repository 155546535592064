export default {
  content: {
    title_detail: 'More Videos',
    be_first_person: 'Be the first person to react!',
    title: 'Title',
    update: 'Update',
    create: 'Create',
    cancel: 'Cancel',
    description: 'Description',
    richText: 'Rich Text',
    link: 'Video Link',
    tags: 'Tags',
    delete: 'Delete',
    thumbnail: 'Thumbnail',
    white_label: 'White Label',
  },
}
