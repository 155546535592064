import Bugsnag, { Client } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

let BugsnagClient: Client

export const BugsnagService = {
  getClient() {
    if (!BugsnagClient) {
      this.start()
    }
    return BugsnagClient
  },
  start() {
    const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY ?? ''

    BugsnagClient = Bugsnag.start({
      apiKey,
      plugins: [new BugsnagPluginReact()],
    })
  },
}
